import Vue from 'vue/dist/vue';
import { formSending } from '@library/forms/form-sending';
import { formValidation } from '@library/forms/validation';
import { form } from '@scripts/mixins/form';
import { apiTradingPartnerRequest } from '@scripts/api-methods';
import { VALIDATION_MESSAGES, VALIDATION_RULES } from '@scripts/constants';
import { INPUT_MASKS } from '@library/scripts/constants';
import VueTheMask from 'vue-the-mask';

Vue.use(VueTheMask);

const formDataScheme = () => ({
    subject: '',
    organizationName: '',

    organizationNameDocuments: '',
    organizationNameShort: '',
    addressReal: '',
    organizationNameFull: '',
    formOrganizationalLaw: '',
    legalPostalAddress: '',
    namePostPresident: '',
    namePostManagerPointSupport: '',
    phone: '',
    email: '',
    inn: '',
    ogrn: '',
    site: '',

    specialization: '',

    wholesalePercentage: '',
    salesCompanyPercentage: '',
    retailSelfPercentage: '',
    carJapanPercentage: '',
    carRussiaPercentage: '',
    carKoreaPercentage: '',
    carGermanyPercentage: '',
    carFrancePercentage: '',
    carAmericaPercentage: '',
    carChinaPercentage: '',
    carPassengerPercentage: '',
    carOthersPercentage: '',
    trucksPercentage: '',
    motoPercentage: '',
    carSpecialPercentage: '',
    salesRegions: '',

    listCompanyOfDealer: '',
    salesOnBrandsPercentage: '',
    listCompanyOfSuspensionOfDealer: '',
    assortmentCurrent: '',
    crossDocking: '',

    historyCompany: '',
    structureCompany: '',
    structureSalesSection: '',
    logistic: '',
    timeProcessing: '',
    timeDeliveryMin: '',
    coveringRegional: '',
    deliveryCondition: '',
    warehouses: '',
    countClient: '',
    countServiceStation: '',
    countRetailShop: '',
    countWebShop: '',
    salesPoint: '',

    pr: '',
    prPlan: '',

    additional: '',
});

export default (el, name) =>
    new Vue({
        el,
        name,
        data: () => ({
            formData: formDataScheme(),
            masks: {
                phone: INPUT_MASKS.PHONE_MASK,
                inn: INPUT_MASKS.INN_MASK,
                ogrn: INPUT_MASKS.OGRN_MASK,
                percent: '###',
            },
        }),
        mixins: [
            form(),
            formSending({ useRecaptcha: true }),
            formValidation(
                {
                    subject: {
                        field: 'formData.subject',
                        rules: {
                            required: true,
                            ...VALIDATION_RULES.SMALL_TEXT,
                        },
                    },
                    organization_name: {
                        field: 'formData.organizationName',
                        rules: {
                            required: true,
                            ...VALIDATION_RULES.SMALL_TEXT,
                        },
                    },

                    // Общие сведения
                    organization_documented_name: {
                        field: 'formData.organizationNameDocuments',
                        rules: {
                            required: true,
                            ...VALIDATION_RULES.SMALL_TEXT,
                        },
                    },
                    organization_abbreviated_name_or_trade_mark: {
                        field: 'formData.organizationNameShort',
                        rules: {
                            required: true,
                            ...VALIDATION_RULES.MEDIUM_TEXT,
                        },
                    },
                    actual_location_address: {
                        field: 'formData.addressReal',
                        rules: {
                            required: true,
                            ...VALIDATION_RULES.MEDIUM_TEXT,
                        },
                    },
                    legal_entity_full_name: {
                        field: 'formData.organizationNameFull',
                        rules: {
                            required: true,
                            ...VALIDATION_RULES.MEDIUM_TEXT,
                        },
                    },
                    organizational_and_legal_form: {
                        field: 'formData.formOrganizationalLaw',
                        rules: {
                            required: true,
                            ...VALIDATION_RULES.MEDIUM_TEXT,
                        },
                    },
                    legal_postal_address: {
                        field: 'formData.legalPostalAddress',
                        rules: {
                            required: true,
                            ...VALIDATION_RULES.LARGE_TEXT,
                        },
                    },
                    company_head_full_name_and_position: {
                        field: 'formData.namePostPresident',
                        rules: {
                            required: true,
                            ...VALIDATION_RULES.SMALL200_TEXT,
                        },
                    },
                    partnership_contact_person_full_name_and_position: {
                        field: 'formData.namePostManagerPointSupport',
                        rules: {
                            required: true,
                            ...VALIDATION_RULES.SMALL200_TEXT,
                        },
                    },
                    contact_phone: {
                        field: 'formData.phone',
                        rules: {
                            required: true,
                            ...VALIDATION_RULES.PHONE,
                        },
                    },
                    email: {
                        field: 'formData.email',
                        rules: {
                            required: true,
                            ...VALIDATION_RULES.EMAIL,
                        },
                    },
                    organization_tin: {
                        field: 'formData.inn',
                        rules: {
                            required: true,
                            ...VALIDATION_RULES.INN,
                        },
                    },
                    organization_psrn: {
                        field: 'formData.ogrn',
                        rules: {
                            required: true,
                            ...VALIDATION_RULES.OGRN,
                        },
                    },
                    website: {
                        field: 'formData.site',
                        rules: {
                            required: true,
                            ...VALIDATION_RULES.SMALL200_TEXT,
                        },
                    },

                    // Коммерческая деятельность
                    company_specialization: {
                        field: 'formData.specialization',
                        rules: {
                            required: true,
                            ...VALIDATION_RULES.SMALL200_TEXT,
                        },
                    },

                    // Распределение товарооборота в разрезах
                    turnover_distribution_wholesale_percentage: {
                        field: 'formData.wholesalePercentage',
                        rules: {
                            ...VALIDATION_RULES.PERCENT,
                        },
                    },
                    turnover_distribution_corporate_sales_percentage: {
                        field: 'formData.salesCompanyPercentage',
                        rules: {
                            ...VALIDATION_RULES.PERCENT,
                        },
                    },
                    turnover_distribution_retail_percentage: {
                        field: 'formData.retailSelfPercentage',
                        rules: {
                            ...VALIDATION_RULES.PERCENT,
                        },
                    },
                    turnover_distribution_japanese_cars_percentage: {
                        field: 'formData.carJapanPercentage',
                        rules: {
                            ...VALIDATION_RULES.PERCENT,
                        },
                    },
                    turnover_distribution_russian_cars_percentage: {
                        field: 'formData.carRussiaPercentage',
                        rules: {
                            ...VALIDATION_RULES.PERCENT,
                        },
                    },
                    turnover_distribution_korean_cars_percentage: {
                        field: 'formData.carKoreaPercentage',
                        rules: {
                            ...VALIDATION_RULES.PERCENT,
                        },
                    },
                    turnover_distribution_german_cars_percentage: {
                        field: 'formData.carGermanyPercentage',
                        rules: {
                            ...VALIDATION_RULES.PERCENT,
                        },
                    },
                    turnover_distribution_french_cars_percentage: {
                        field: 'formData.carFrancePercentage',
                        rules: {
                            ...VALIDATION_RULES.PERCENT,
                        },
                    },
                    turnover_distribution_american_cars_percentage: {
                        field: 'formData.carAmericaPercentage',
                        rules: {
                            ...VALIDATION_RULES.PERCENT,
                        },
                    },
                    turnover_distribution_chinese_cars_percentage: {
                        field: 'formData.carChinaPercentage',
                        rules: {
                            ...VALIDATION_RULES.PERCENT,
                        },
                    },
                    turnover_distribution_passenger_cars_percentage: {
                        field: 'formData.carPassengerPercentage',
                        rules: {
                            ...VALIDATION_RULES.PERCENT,
                        },
                    },
                    turnover_distribution_other_cars_percentage: {
                        field: 'formData.carOthersPercentage',
                        rules: {
                            ...VALIDATION_RULES.PERCENT,
                        },
                    },
                    turnover_distribution_trucks_percentage: {
                        field: 'formData.trucksPercentage',
                        rules: {
                            ...VALIDATION_RULES.PERCENT,
                        },
                    },
                    turnover_distribution_mototechnics_percentage: {
                        field: 'formData.motoPercentage',
                        rules: {
                            ...VALIDATION_RULES.PERCENT,
                        },
                    },
                    turnover_distribution_construction_machinery_percentage: {
                        field: 'formData.carSpecialPercentage',
                        rules: {
                            ...VALIDATION_RULES.PERCENT,
                        },
                    },
                    sales_regions: {
                        field: 'formData.salesRegions',
                        rules: {
                            required: true,
                            ...VALIDATION_RULES.LARGE_TEXT,
                        },
                    },

                    // Ассортимент
                    distributed_manufacturers: {
                        field: 'formData.listCompanyOfDealer',
                        rules: {
                            ...VALIDATION_RULES.LARGE_TEXT,
                        },
                    },
                    brands_sales_to_total_sales_volume_percentage: {
                        field: 'formData.salesOnBrandsPercentage',
                        rules: {
                            ...VALIDATION_RULES.LARGE_TEXT,
                        },
                    },
                    distributed_suspension_parts_manufacturers: {
                        field: 'formData.listCompanyOfSuspensionOfDealer',
                        rules: {
                            ...VALIDATION_RULES.LARGE_TEXT,
                        },
                    },
                    current_assortment: {
                        field: 'formData.assortmentCurrent',
                        rules: {
                            ...VALIDATION_RULES.LARGE_TEXT,
                        },
                    },
                    cross_docking: {
                        field: 'formData.crossDocking',
                        rules: {
                            ...VALIDATION_RULES.LARGE_TEXT,
                        },
                    },

                    // Описание инфраструктуры
                    brief_company_history: {
                        field: 'formData.historyCompany',
                        rules: {
                            ...VALIDATION_RULES.LARGE_TEXT,
                        },
                    },
                    employees_count_and_company_structure: {
                        field: 'formData.structureCompany',
                        rules: {
                            ...VALIDATION_RULES.LARGE_TEXT,
                        },
                    },
                    salesmen_count_and_company_sales_structure: {
                        field: 'formData.structureSalesSection',
                        rules: {
                            ...VALIDATION_RULES.LARGE_TEXT,
                        },
                    },
                    logistics: {
                        field: 'formData.logistic',
                        rules: {
                            ...VALIDATION_RULES.LARGE_TEXT,
                        },
                    },
                    order_processing_time: {
                        field: 'formData.timeProcessing',
                        rules: {
                            ...VALIDATION_RULES.SMALL_TEXT,
                        },
                    },
                    minimum_delivery_time_and_per_day_deliveries_count: {
                        field: 'formData.timeDeliveryMin',
                        rules: {
                            ...VALIDATION_RULES.SMALL_TEXT,
                        },
                    },
                    logistics_services_regional_coverage: {
                        field: 'formData.coveringRegional',
                        rules: {
                            ...VALIDATION_RULES.LARGE_TEXT,
                        },
                    },
                    minimum_client_delivery_requirements: {
                        field: 'formData.deliveryCondition',
                        rules: {
                            ...VALIDATION_RULES.LARGE_TEXT,
                        },
                    },
                    warehouse_premises: {
                        field: 'formData.warehouses',
                        rules: {
                            ...VALIDATION_RULES.LARGE_TEXT,
                        },
                    },
                    regular_customer_base_size: {
                        field: 'formData.countClient',
                        rules: {
                            ...VALIDATION_RULES.SMALL_TEXT,
                        },
                    },
                    service_stations_count: {
                        field: 'formData.countServiceStation',
                        rules: {
                            ...VALIDATION_RULES.SMALL_TEXT,
                        },
                    },
                    retail_stores_count: {
                        field: 'formData.countRetailShop',
                        rules: {
                            ...VALIDATION_RULES.SMALL_TEXT,
                        },
                    },
                    online_stores_count: {
                        field: 'formData.countWebShop',
                        rules: {
                            ...VALIDATION_RULES.SMALL_TEXT,
                        },
                    },
                    company_sale_points: {
                        field: 'formData.salesPoint',
                        rules: {
                            ...VALIDATION_RULES.LARGE_TEXT,
                        },
                    },

                    // Маркетинговые мероприятия
                    advertising_exhibition_and_marketing_activities: {
                        field: 'formData.pr',
                        rules: {
                            ...VALIDATION_RULES.LARGE_TEXT,
                        },
                    },
                    current_period_advertising_plan: {
                        field: 'formData.prPlan',
                        rules: {
                            ...VALIDATION_RULES.LARGE_TEXT,
                        },
                    },

                    // Дополнительная информация
                    additional_information: {
                        field: 'formData.additional',
                        rules: {
                            ...VALIDATION_RULES.LARGE_TEXT,
                        },
                    },
                },
                VALIDATION_MESSAGES
            ),
        ],
        methods: {
            clearForm() {
                this.formData = formDataScheme();
            },
            sendRequest() {
                const f = this.formData;

                return this.sendForm(() =>
                    apiTradingPartnerRequest(
                        this.optionalPayload({
                            subject: f.subject,
                            organization_name: f.organizationName,
                            organization_documented_name: f.organizationNameDocuments,
                            organization_abbreviated_name_or_trade_mark: f.organizationNameShort,
                            actual_location_address: f.addressReal,
                            legal_entity_full_name: f.organizationNameFull,
                            organizational_and_legal_form: f.formOrganizationalLaw,
                            legal_postal_address: f.legalPostalAddress,
                            company_head_full_name_and_position: f.namePostPresident,
                            partnership_contact_person_full_name_and_position: f.namePostManagerPointSupport,
                            contact_phone: f.phone,
                            email: f.email,
                            organization_tin: f.inn,
                            organization_psrn: f.ogrn.replace(/\s/g, ''),
                            website: f.site,
                            company_specialization: f.specialization,
                            turnover_distribution_wholesale_percentage: this.isOptional(f.wholesalePercentage, ''),
                            turnover_distribution_corporate_sales_percentage: this.isOptional(f.salesCompanyPercentage, ''),
                            turnover_distribution_retail_percentage: this.isOptional(f.retailSelfPercentage, ''),
                            turnover_distribution_japanese_cars_percentage: this.isOptional(f.carJapanPercentage, ''),
                            turnover_distribution_russian_cars_percentage: this.isOptional(f.carRussiaPercentage, ''),
                            turnover_distribution_korean_cars_percentage: this.isOptional(f.carKoreaPercentage, ''),
                            turnover_distribution_german_cars_percentage: this.isOptional(f.carGermanyPercentage, ''),
                            turnover_distribution_french_cars_percentage: this.isOptional(f.carFrancePercentage, ''),
                            turnover_distribution_american_cars_percentage: this.isOptional(f.carAmericaPercentage, ''),
                            turnover_distribution_chinese_cars_percentage: this.isOptional(f.carChinaPercentage, ''),
                            turnover_distribution_other_cars_percentage: this.isOptional(f.carOthersPercentage, ''),
                            turnover_distribution_passenger_cars_percentage: this.isOptional(f.carPassengerPercentage, ''),
                            turnover_distribution_trucks_percentage: this.isOptional(f.trucksPercentage, ''),
                            turnover_distribution_mototechnics_percentage: this.isOptional(f.motoPercentage, ''),
                            turnover_distribution_construction_machinery_percentage: this.isOptional(f.carSpecialPercentage, ''),
                            sales_regions: f.salesRegions,
                            distributed_manufacturers: this.isOptional(f.listCompanyOfDealer, ''),
                            brands_sales_to_total_sales_volume_percentage: this.isOptional(f.salesOnBrandsPercentage, ''),
                            distributed_suspension_parts_manufacturers: this.isOptional(f.listCompanyOfSuspensionOfDealer, ''),
                            current_assortment: this.isOptional(f.assortmentCurrent, ''),
                            cross_docking: this.isOptional(f.crossDocking, ''),
                            brief_company_history: this.isOptional(f.historyCompany, ''),
                            employees_count_and_company_structure: this.isOptional(f.structureCompany, ''),
                            salesmen_count_and_company_sales_structure: this.isOptional(f.structureSalesSection, ''),
                            logistics: this.isOptional(f.logistic, ''),
                            order_processing_time: this.isOptional(f.timeProcessing, ''),
                            minimum_delivery_time_and_per_day_deliveries_count: this.isOptional(f.timeDeliveryMin, ''),
                            logistics_services_regional_coverage: this.isOptional(f.coveringRegional, ''),
                            minimum_client_delivery_requirements: this.isOptional(f.deliveryCondition, ''),
                            warehouse_premises: this.isOptional(f.warehouses, ''),
                            regular_customer_base_size: this.isOptional(f.countClient, ''),
                            service_stations_count: this.isOptional(f.countServiceStation, ''),
                            retail_stores_count: this.isOptional(f.countRetailShop, ''),
                            online_stores_count: this.isOptional(f.countWebShop, ''),
                            company_sale_points: this.isOptional(f.salesPoint, ''),
                            advertising_exhibition_and_marketing_activities: this.isOptional(f.pr, ''),
                            current_period_advertising_plan: this.isOptional(f.prPlan, ''),
                            additional_information: this.isOptional(f.additional, ''),
                            captcha_token: this.token,
                        })
                    )
                );
            },
        },
    });
