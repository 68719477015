import Vue from 'vue/dist/vue';
import { mapActions } from 'vuex';
import store from '@scripts/store';
import { MODALS, VUEX_ACTIONS } from '@scripts/constants';
import UIkit from '../../../vendor/uikit/uikit';

export default (el, name) =>
    new Vue({
        el,
        name,
        store,
        data: () => ({
            clearCartLoader: {
                debounceLoading: false,
                realLoading: false,
            },
        }),
        methods: {
            ...mapActions([VUEX_ACTIONS.CLEAR_CART]),

            handleClearCartConfirmed() {
                this.clearCartLoader.debounceLoading = true;
                this.clearCartLoader.realLoading = true;

                this.clearCart()
                    .then(() => {
                        UIkit.modal(MODALS.CLEAR_CART_CONFIRM).hide();
                    })
                    .finally(() => {
                        this.clearCartLoader.debounceLoading = false;
                        this.clearCartLoader.realLoading = false;
                    });
            },
        },
    });
