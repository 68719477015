<template>
    <div
        class="RegisteredDetail"
        :class="{ 'RegisteredDetail--withComment': showComment }"
    >
        <div
            class="uk-accordion-title RegisteredDetail__header"
            data-accordion-toggle
        >
            <a :href="url" class="RegisteredDetail__pictureLink">
                <picture
                    class="RegisteredDetail__picture"
                    :class="{
                        'RegisteredDetail__picture--withPlaceholder': !image,
                    }"
                >
                    <img
                        v-if="image"
                        class="RegisteredDetail__image"
                        :srcset="`${image}`"
                        :src="image"
                        :alt="detailName"
                    />
                    <inline-svg
                        v-else
                        src="/assets/images/image-placeholder.svg"
                        class="RegisteredDetail__placeholder"
                    ></inline-svg>
                </picture>
            </a>

            <div class="RegisteredDetail__detailNameBlock">
                <span
                    class="RegisteredDetail__status"
                    :class="{
                        'RegisteredDetail__status--green':
                            status.id === 'on_warranty',
                    }"
                    v-html="status.name"
                ></span>

                <a :href="url" class="RegisteredDetail__detailNameLink">
                    <span
                        v-html="vendorCode"
                        class="RegisteredDetail__vendorCode"
                    ></span>
                    &nbsp;
                    <span
                        v-html="detailName"
                        class="RegisteredDetail__detailName"
                    ></span>
                </a>
            </div>
        </div>

        <div class="RegisteredDetail__main" data-accordion-content hidden>
            <div class="RegisteredDetail__detailsBlock">
                <div
                    class="RegisteredDetail__detail RegisteredDetail__detail--purchaseDate"
                >
                    <span class="RegisteredDetail__detailCaption">
                        Дата покупки:
                    </span>

                    <time
                        class="RegisteredDetail__detailValue"
                        :datetime="purchaseDate"
                        v-html="purchaseDate"
                    ></time>
                </div>

                <div
                    class="RegisteredDetail__detail RegisteredDetail__detail--manufacturingDate"
                >
                    <span class="RegisteredDetail__detailCaption">
                        Дата выпуска:
                    </span>

                    <time
                        class="RegisteredDetail__detailValue"
                        :datetime="manufacturingDate"
                        v-html="manufacturingDate"
                    ></time>
                </div>

                <div
                    class="RegisteredDetail__detail RegisteredDetail__detail--carInfo"
                >
                    <span class="RegisteredDetail__detailCaption">
                        Автомобиль:
                    </span>

                    <span
                        class="RegisteredDetail__detailValue"
                        v-html="`${carBrand} ${carModel}`"
                    ></span>
                </div>

                <div
                    class="RegisteredDetail__detail RegisteredDetail__detail--serialNumber"
                >
                    <span class="RegisteredDetail__detailCaption">
                        Серийный номер:
                    </span>

                    <span
                        class="RegisteredDetail__detailValue"
                        v-html="serialNumber"
                    ></span>
                </div>

                <div
                    class="RegisteredDetail__detail RegisteredDetail__detail--vin"
                >
                    <span class="RegisteredDetail__detailCaption">
                        VIN / кузов:
                    </span>

                    <span
                        class="RegisteredDetail__detailValue"
                        v-html="vin"
                    ></span>
                </div>

                <div
                    class="RegisteredDetail__detail RegisteredDetail__detail--ownerName"
                >
                    <span class="RegisteredDetail__detailCaption">ФИО:</span>

                    <span
                        class="RegisteredDetail__detailValue"
                        v-html="ownerName"
                    ></span>
                </div>
            </div>

            <div v-if="showComment" class="RegisteredDetail__commentBlock">
                <span class="RegisteredDetail__commentHeading">
                    Комментарий
                </span>

                <p class="RegisteredDetail__commentText" v-html="comment"></p>
            </div>
        </div>
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
    props: {
        detailName: {
            type: String,
            required: true,
        },
        vendorCode: {
            type: String,
            required: true,
        },
        url: {
            type: String,
            required: true,
        },
        status: {
            type: Object,
            required: true,
        },
        carBrand: {
            type: String,
            required: true,
        },
        carModel: {
            type: String,
            required: true,
        },
        serialNumber: {
            type: String,
            required: true,
        },
        vin: {
            type: String,
            required: true,
        },
        ownerName: {
            type: String,
            required: true,
        },
        purchaseDate: {
            type: String,
            required: true,
        },
        image: {
            type: String,
        },
        manufacturingDate: {
            type: String,
        },
        comment: {
            type: String,
        },
    },
    components: {
        InlineSvg,
    },
    computed: {
        showComment() {
            return this.status.id === 'off_warranty' && this.comment;
        },
    },
};
</script>
