import Vue from 'vue/dist/vue';
import { apiGetWheelOfFortuneSpin } from '@scripts/api-methods';
import { showErrorMessage, showSuccessMessage, showWarningMessage } from '@library/uikit/notifications';

//--------------
const wheelBody = document.getElementsByClassName('wheel-body');
const wheelBodyBorder = document.getElementsByClassName('wheel-body-border');
//--------------
export default (el, name) =>
    new Vue({
        el,
        name,
        data: () => ({
            angle:0,
        }),
        mounted() {
            if (winangle !== undefined && winangle > 0){
                setTimeout(()=>{
                    wheelBody[0].style.transform = 'rotate(-'+winangle+'deg)';
                    wheelBodyBorder[0].style.transform = 'rotate('+winangle+'deg)';
                },300);
            }
        },
        methods: {
            spinWheel() {
                apiGetWheelOfFortuneSpin()
                    .then((ret) => {
                        if (ret !== undefined && ret.angle !== undefined ){
                            if (ret.angle > 0){
                                this.angle = ret.angle;

                                wheelBody[0].style.transform = 'rotate(-'+this.angle+'deg)';
                                wheelBodyBorder[0].style.transform = 'rotate('+this.angle+'deg)';

                                setTimeout(()=>{
                                    showSuccessMessage({ text: ret.msg });
                                    window.location.reload();
                                },7500);
                            }else{
                                showWarningMessage({ text: ret.msg });
                            }
                        }
                        // console.log(ret);
                        // window.location.reload();
                    })
                    .catch(() => {
                        showErrorMessage({
                            text: 'Произошла ошибка',
                        });
                    })
                ;
            },
        },
    })
;