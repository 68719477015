import Vue from 'vue/dist/vue';

import { VueReCaptcha } from 'vue-recaptcha-v3';

import { getFromBinding } from './helpers';
import { GLOBAL } from './constants';

export function initRecaptcha() {
    const siteKey = getFromBinding(GLOBAL.RECAPTCHA_SITE_KEY);
    if (siteKey && siteKey !== 'Undefined') {
        Vue.use(VueReCaptcha, { siteKey });
    } else {
        /* eslint-disable no-console */
        console.warn('Recaptcha key is not exist in binding');
    }
}
