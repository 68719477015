import smoothscroll from 'smoothscroll-polyfill'
/**
 * Smooth Scroll polyfill
 * @param {string} attributeName - attribute name you want to add on <a> elements
 * @example
 * <a href='#foo' data-smooth-scroll>
 */
export default function smoothScroll(attributeName = 'data-smooth-scroll') {
	if (CSS.supports('scroll-behavior', 'smooth')) return
	smoothscroll.polyfill()
	const links = document.querySelectorAll(`a[href^="#"][${attributeName}]`)
	links.forEach(link => {
		const href = link.attributes['href'].value
		const id = href.slice(1)
		const target = document.getElementById(id)
		link.addEventListener('click', event => {
			event.preventDefault()
			// Hack to prevent native hash anchor handling
			link.attributes['href'].value = '' // woop
			target.scrollIntoView({ behavior: 'smooth' })
			history.replaceState({}, 'hash', href)
			link.attributes['href'].value = href // naebal
		})
	})
}
