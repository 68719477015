<template>
    <tr
        class="CartTableLegal__row CartItemLegal"
        :class="{ 'CartItemLegal--removing': isRemoving }"
    >
        <td class="CartItemLegal__cell">
            <a :href="url" class="CartItemLegal__link" v-if="!isPresentInRossko">
                <picture
                    class="CartItemLegal__picture"
                    :class="{
                        'CartItemLegal__picture--withPlaceholder': !image,
                    }"
                >
                    <img
                        v-if="image"
                        class="CartItemLegal__image"
                        :srcset="`${image1x}, ${image2x} 2x`"
                        :src="image1x"
                        :alt="name"
                    />
                    <inline-svg
                        v-else
                        src="/assets/images/image-placeholder.svg"
                        class="CartItemLegal__image CartItemLegal__image--placeholder"
                    ></inline-svg>
                </picture>

                <span class="CartItemLegal__nameWrapper">
                    <span v-if="vendorCode">
                        <span
                            v-html="vendorCode"
                            class="CartItemLegal__vendorCode"
                        ></span>
                        &nbsp;
                    </span>
                    <span v-html="name" class="CartItemLegal__name"></span>
                </span>
            </a>
            <span class="CartItemLegal__title" v-else>
                <picture
                    class="CartItemLegal__picture"
                    :class="{
                        'CartItemLegal__picture--withPlaceholder': !image,
                    }"
                >
                    <img
                        v-if="image"
                        class="CartItemLegal__image"
                        :srcset="`${image1x}, ${image2x} 2x`"
                        :src="image1x"
                        :alt="name"
                    />
                    <inline-svg
                        v-else
                        src="/assets/images/image-placeholder.svg"
                        class="CartItemLegal__image CartItemLegal__image--placeholder"
                    ></inline-svg>
                </picture>

                <span class="CartItemLegal__nameWrapperNeighbours">
                    <span v-if="vendorCode">
                        <span
                            v-html="vendorCode"
                            class="CartItemLegal__vendorCode"
                        ></span>
                        &nbsp;
                    </span>
                    <span v-html="name" class="CartItemLegal__name"></span>
                </span>
            </span>
        </td>

        <td
            class="CartItemLegal__cell"
            v-html="formatValue(pricePerItem, formatRoubles)"
        ></td>

        <td class="CartItemLegal__cell">
            <div class="CartItemLegal__counter">
                <counter
                    :mode="'legalCart'"
                    :value="fakeQuantity"
                    :quantity-in-package="packageItemsCount"
                    :is-user-wholesaler="isUserWholesaler"
                    :max-item-quantity="999"
                    :mask="'###'"
                    :show-buttons="false"
                    @change="debounceChangeQuantity($event)"
                ></counter>
            </div>
        </td>

        <td class="CartItemLegal__cell" v-html="packageItemsCount"></td>

        <td class="CartItemLegal__cell" v-html="packagesCount"></td>

        <td
            class="CartItemLegal__cell CartItemLegal__cell--greenText"
            v-html="availableQuantity"
        ></td>

        <td
            class="CartItemLegal__cell"
            v-if="displayUnavailableProductsInfo"
            v-html="formatValue(quantityForOrder)"
        ></td>

        <td
            class="CartItemLegal__cell"
            v-if="displayUnavailableProductsInfo"
            v-html="formatValue(quantityForManufacturing)"
        ></td>

        <td
            class="CartItemLegal__cell"
            v-html="formatValue(totalPrice, formatRoubles)"
        ></td>

        <td
            class="CartItemLegal__cell CartItemLegal__cell--lightGrayText"
            v-if="displayRetailPrices"
            v-html="formatValue(pricePerItemRetail, formatRoubles)"
        ></td>

        <td
            class="CartItemLegal__cell CartItemLegal__cell--lightGrayText"
            v-if="displayRetailPrices"
            v-html="formatValue(totalPriceRetail, formatRoubles)"
        ></td>

        <td
            class="CartItemLegal__cell"
            v-if="displayWeight"
            v-html="formatValue(weightPerItem, formatFloatNumberThousands)"
        ></td>

        <td
            class="CartItemLegal__cell CartItemLegal__cell--alignRight"
            v-if="displayWeight"
        >
            {{ formatValue(weightItemTotal, formatFloatNumberThousands) }}

            <button
                v-if="!renderRemoveButtonCell"
                type="button"
                class="Button Button--remove CartItemLegal__removeButton"
                @click="removeItem"
            >
                <span class="Button__icon">
                    <inline-svg src="/assets/icons/close.svg"></inline-svg>
                </span>
            </button>
        </td>

        <td
            class="CartItemLegal__cell CartItemLegal__cell--remove"
            v-if="renderRemoveButtonCell"
        >
            <button
                type="button"
                class="Button Button--remove CartItemLegal__removeButton"
                @click="removeItem"
            >
                <span class="Button__icon">
                    <inline-svg src="/assets/icons/close.svg"></inline-svg>
                </span>
            </button>
        </td>
    </tr>
</template>

<script>
import CartItem from '../cart-item/cart-item.vue';
import Counter from '../counter/counter.vue';
import { PRODUCT_STATUSES } from '@scripts/constants';

export default {
    extends: CartItem,
    props: {
        vendorCode: {
            type: String,
            required: false,
        },
        pricePerItemRetail: {
            type: Number,
        },
        packagesCount: {
            type: Number,
            required: true,
        },
        availableQuantity: {
            type: Number,
            required: true,
        },
        quantityForOrder: {
            type: Number,
        },
        quantityForManufacturing: {
            type: Number,
        },
        totalPriceRetail: {
            type: Number,
        },
        weightPerItem: {
            type: Number,
        },
        weightItemTotal: {
            type: Number,
        },
        displayRetailPrices: {
            type: Boolean,
            default: false,
        },
        displayWeight: {
            type: Boolean,
            default: false,
        },
        displayUnavailableProductsInfo: {
            type: Boolean,
            default: false,
        },
        isPresentInRossko: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        Counter,
    },
    data() {
        return {
            PRODUCT_STATUSES,
            debounceTimeout: null,
            fakeQuantity: this.quantity,
        };
    },
    computed: {
        renderRemoveButtonCell() {
            return !(this.displayRetailPrices && this.displayWeight);
        },
    },
    methods: {
        formatValue(value, formatter = null) {
            if (value != null) {
                return formatter !== null ? formatter(value) : value;
            }
            return '-';
        },

        debounceChangeQuantity(event) {
            const newQuantity = event.quantity;
            this.fakeQuantity = newQuantity;

            clearTimeout(this.debounceTimeout);
            this.debounceTimeout = null;

            this.debounceTimeout = setTimeout(() => {
                this.changeQuantity({ quantity: newQuantity });
                this.debounceTimeout = null;
            }, 400);
        },
    },
};
</script>
