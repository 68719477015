import Vue from 'vue/dist/vue';
import { mapGetters, mapActions } from 'vuex';
import store from '@scripts/store';
import { VUEX_GETTERS, VUEX_ACTIONS } from '@scripts/constants';
import { toggleFavorites } from '@scripts/mixins/toggle-favorites';
import AddToCart from '../add-to-cart/add-to-cart.vue';
import ProductStatus from '../product-status/product-status.vue';

export default (el, name, data) =>
    new Vue({
        el,
        name,
        store,
        components: {
            AddToCart,
            ProductStatus,
        },
        mixins: [toggleFavorites()],
        beforeMount() {
            this.favoritesMap = data.favoritesMap;
        },
        computed: {
            ...mapGetters([VUEX_GETTERS.CART_PRODUCT_QUANTITY, VUEX_GETTERS.CART_PRODUCT_LOADING_STATUS, VUEX_GETTERS.IS_USER_WHOLESALER]),
        },
        methods: {
            ...mapActions([VUEX_ACTIONS.CHANGE_CART_PRODUCT_QUANTITY]),
        },
    });
