'use strict'

const excludedDefaultMethods = []

export function binder(instance, options) {
	options = Object.assign({}, options)
	options.exclude = (options.exclude || []).concat(excludedDefaultMethods)
	return (instance, options) => {
		options = Object.assign({}, options)
		const filter = key => {
			const match = pattern => typeof pattern === 'string' ? key === pattern : pattern.test(key)
			if (options.include) return options.include.some(match)
			if (options.exclude) return !options.exclude.some(match)
			return true
		}

		for (const key of Object.getOwnPropertyNames(instance.constructor.prototype)) {
			const value = instance[key]
			if (key !== 'constructor' && typeof value === 'function' && filter(key)) {
				instance[key] = value.bind(instance)
			}
		}

		return instance
	}
}

export class AutoBinded {
	constructor(options = {}) {
		binder(this, options)
	}
}

export class Sealed {
	constructor(properties = {}, compute = null) {
		if (compute instanceof Function) properties = compute()
		for (const prop in properties) {
			Object.defineProperty(this, prop, {
				configurable: false,
				writable: false,
				value: properties[prop]
			})
		}
		Object.seal(this)
	}
}
