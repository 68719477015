<template>
    <article class="AddressItem">
        <span class="AddressItem__houseAddress" v-html="houseAddress"></span>

        <span class="AddressItem__cityAddress" v-html="cityAddress"></span>

        <div class="AddressItem__options DropMenu" ref="dropdown-menu">
            <button
                type="button"
                class="Button Button--empty DropMenu__contextMenuButton"
                @click="openDropMenu"
            >
                <span class="Button__icon">
                    <inline-svg src="/assets/icons/options.svg"></inline-svg>
                </span>
            </button>

            <transition name="slide-fade">
                <ul v-show="isDropMenuOpen" class="DropMenu__list">
                    <li class="DropMenu__item">
                        <button
                            type="button"
                            class="Button Button--link DropMenu__itemButton"
                            @click="edit"
                        >
                            Изменить
                        </button>
                    </li>

                    <li class="DropMenu__item">
                        <button
                            type="button"
                            class="Button Button--link DropMenu__itemButton"
                            @click="remove"
                        >
                            Удалить
                        </button>
                    </li>
                </ul>
            </transition>
        </div>
    </article>
</template>

<script>
import { onClickOutside } from '@scripts/helpers';
import InlineSvg from 'vue-inline-svg';

export default {
    props: {
        id: {
            type: Number,
            required: true,
        },
        country: {
            type: String,
            required: true,
        },
        city: {
            type: Object,
            required: true,
        },
        postalCode: {
            type: String,
            required: true,
        },
        street: {
            type: Object,
            required: true,
        },
        building: {
            type: String,
            required: true,
        },
        flat: {
            type: String,
        },
    },
    components: {
        InlineSvg,
    },
    data() {
        return {
            isDropMenuOpen: false,
        };
    },
    computed: {
        houseAddress() {
            return `ул.&nbsp;${this.street.label}, ${this.building}${
                this.flat ? `, кв.&nbsp;${this.flat}` : ''
            }`;
        },

        cityAddress() {
            return [this.country, this.city.label, this.postalCode].join(', ');
        },
    },
    methods: {
        edit() {
            this.isDropMenuOpen = false;
            this.$emit('edit', { id: this.id });
        },

        openDropMenu() {
            this.isDropMenuOpen = true;
            onClickOutside(this.$refs['dropdown-menu'], () => {
                this.isDropMenuOpen = false;
            });
        },

        remove() {
            this.isDropMenuOpen = false;
            this.$emit('remove', { id: this.id });
        },
    },
};
</script>
