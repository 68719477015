var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "RegisteredDetail",
      class: { "RegisteredDetail--withComment": _vm.showComment },
    },
    [
      _c(
        "div",
        {
          staticClass: "uk-accordion-title RegisteredDetail__header",
          attrs: { "data-accordion-toggle": "" },
        },
        [
          _c(
            "a",
            {
              staticClass: "RegisteredDetail__pictureLink",
              attrs: { href: _vm.url },
            },
            [
              _c(
                "picture",
                {
                  staticClass: "RegisteredDetail__picture",
                  class: {
                    "RegisteredDetail__picture--withPlaceholder": !_vm.image,
                  },
                },
                [
                  _vm.image
                    ? _c("img", {
                        staticClass: "RegisteredDetail__image",
                        attrs: {
                          srcset: `${_vm.image}`,
                          src: _vm.image,
                          alt: _vm.detailName,
                        },
                      })
                    : _c("inline-svg", {
                        staticClass: "RegisteredDetail__placeholder",
                        attrs: { src: "/assets/images/image-placeholder.svg" },
                      }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "RegisteredDetail__detailNameBlock" }, [
            _c("span", {
              staticClass: "RegisteredDetail__status",
              class: {
                "RegisteredDetail__status--green":
                  _vm.status.id === "on_warranty",
              },
              domProps: { innerHTML: _vm._s(_vm.status.name) },
            }),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "RegisteredDetail__detailNameLink",
                attrs: { href: _vm.url },
              },
              [
                _c("span", {
                  staticClass: "RegisteredDetail__vendorCode",
                  domProps: { innerHTML: _vm._s(_vm.vendorCode) },
                }),
                _vm._v("\n                 \n                "),
                _c("span", {
                  staticClass: "RegisteredDetail__detailName",
                  domProps: { innerHTML: _vm._s(_vm.detailName) },
                }),
              ]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "RegisteredDetail__main",
          attrs: { "data-accordion-content": "", hidden: "" },
        },
        [
          _c("div", { staticClass: "RegisteredDetail__detailsBlock" }, [
            _c(
              "div",
              {
                staticClass:
                  "RegisteredDetail__detail RegisteredDetail__detail--purchaseDate",
              },
              [
                _c("span", { staticClass: "RegisteredDetail__detailCaption" }, [
                  _vm._v(
                    "\n                    Дата покупки:\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("time", {
                  staticClass: "RegisteredDetail__detailValue",
                  attrs: { datetime: _vm.purchaseDate },
                  domProps: { innerHTML: _vm._s(_vm.purchaseDate) },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "RegisteredDetail__detail RegisteredDetail__detail--manufacturingDate",
              },
              [
                _c("span", { staticClass: "RegisteredDetail__detailCaption" }, [
                  _vm._v(
                    "\n                    Дата выпуска:\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("time", {
                  staticClass: "RegisteredDetail__detailValue",
                  attrs: { datetime: _vm.manufacturingDate },
                  domProps: { innerHTML: _vm._s(_vm.manufacturingDate) },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "RegisteredDetail__detail RegisteredDetail__detail--carInfo",
              },
              [
                _c("span", { staticClass: "RegisteredDetail__detailCaption" }, [
                  _vm._v("\n                    Автомобиль:\n                "),
                ]),
                _vm._v(" "),
                _c("span", {
                  staticClass: "RegisteredDetail__detailValue",
                  domProps: {
                    innerHTML: _vm._s(`${_vm.carBrand} ${_vm.carModel}`),
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "RegisteredDetail__detail RegisteredDetail__detail--serialNumber",
              },
              [
                _c("span", { staticClass: "RegisteredDetail__detailCaption" }, [
                  _vm._v(
                    "\n                    Серийный номер:\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("span", {
                  staticClass: "RegisteredDetail__detailValue",
                  domProps: { innerHTML: _vm._s(_vm.serialNumber) },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "RegisteredDetail__detail RegisteredDetail__detail--vin",
              },
              [
                _c("span", { staticClass: "RegisteredDetail__detailCaption" }, [
                  _vm._v(
                    "\n                    VIN / кузов:\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("span", {
                  staticClass: "RegisteredDetail__detailValue",
                  domProps: { innerHTML: _vm._s(_vm.vin) },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "RegisteredDetail__detail RegisteredDetail__detail--ownerName",
              },
              [
                _c("span", { staticClass: "RegisteredDetail__detailCaption" }, [
                  _vm._v("ФИО:"),
                ]),
                _vm._v(" "),
                _c("span", {
                  staticClass: "RegisteredDetail__detailValue",
                  domProps: { innerHTML: _vm._s(_vm.ownerName) },
                }),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm.showComment
            ? _c("div", { staticClass: "RegisteredDetail__commentBlock" }, [
                _c(
                  "span",
                  { staticClass: "RegisteredDetail__commentHeading" },
                  [_vm._v("\n                Комментарий\n            ")]
                ),
                _vm._v(" "),
                _c("p", {
                  staticClass: "RegisteredDetail__commentText",
                  domProps: { innerHTML: _vm._s(_vm.comment) },
                }),
              ])
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }