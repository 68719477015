'use strict'

/**
 * @param {*} v
 * @return {boolean}
 */
export function isNull(v) {
	return v === null
}

/**
 * @param {*} v
 * @return {boolean}
 */
export function isUndefined(v) {
	return v === void 0
}

/**
 * @param {*} v
 * @return {boolean}
 */
export function isBool(v) {
	return typeof v === 'boolean'
}

/**
 * @param {*} v
 * @param radix
 * @return {boolean}
 */
export function isInt(v, radix = 10) {
	return parseInt(v, radix) === v
}

/**
 * @param {*} v
 * @return {boolean}
 */
export function isFloat(v) {
	return !isInt(v) && parseFloat(v) === v
}

/**
 * @param {*} v
 * @return {boolean}
 */
export function isNatural(v) {
	return !isFloat(v) && isInt(v) && v >= 0
}

/**
 * @param {*} v
 * @return {boolean}
 */
export function isNumeric(v) {
	return Number(v) === v
}

/**
 * @param {*} v
 * @return {boolean}
 */
export function isArray(v) {
	return v instanceof Array
}

/**
 * @param {*} v
 * @return {boolean}
 */
export function isString(v) {
	return typeof v === 'string'
}

/**
 * @param {*} v
 * @return {boolean}
 */
export function isObject(v) {
	return v instanceof Object
}

/**
 * @param {*} v
 * @return {boolean}
 */
export function isEmpty(v) {
	if (isString(v) || isArray(v)) return v.length === 0
	return false
}

/**
 * @param {*} v
 * @return {boolean}
 */
export function isFunction(v) {
	return v instanceof Function
}

/**
 * @param {*} v
 * @param {Object} proto
 * @return {boolean}
 */
export function isInstanceOf (v, proto) {
	return v instanceof proto
}

export const Is = {
	'null': isNull,
	'int': isInt,
	'bool': isBool,
	'array': isArray,
	'empty': isEmpty,
	'float': isFloat,
	'string': isString,
	'object': isObject,
	'natural': isNatural,
	'numeric': isNumeric,
	'function': isFunction,
	'undefined': isUndefined,
	'instanceOf': isInstanceOf,
}
