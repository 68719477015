import Vue from 'vue/dist/vue';
import { mapGetters, mapActions } from 'vuex';
import store from '@scripts/store';
import { VUEX_GETTERS, VUEX_ACTIONS, VUE_ELEMENTS } from '@scripts/constants';
import { toggleFavorites } from '@scripts/mixins/toggle-favorites';
import AddToCart from '../add-to-cart/add-to-cart.vue';
import ProductStatus from '../product-status/product-status.vue';
import UIkit from '../../vendor/uikit/uikit';

const HASH_PART_GROUP = 'part-group-';

export default (el, name, data) =>
    new Vue({
        el,
        name,
        store,
        components: {
            AddToCart,
            ProductStatus,
        },
        mixins: [toggleFavorites()],
        data() {
            return {
                cars: data.cars,
                activeCar: 0,
                currentTab: 1,
                isAccordionAnimation: false,
            }
        },
        beforeMount() {
            this.favoritesMap = data.productsData.favoritesMap;
        },
        mounted() {
            if (this.hasLocationHash && this.getCarTabIdByHash) {
                this.setTabIdByHash(this.getCarTabIdByHash);
            } else {
                this.setFirstTabByActiveCar();
            }
            UIkit.accordion(this.$refs.cars).toggle(this.activeCar);
        },
        computed: {
            ...mapGetters([VUEX_GETTERS.CART_PRODUCT_QUANTITY, VUEX_GETTERS.CART_PRODUCT_LOADING_STATUS, VUEX_GETTERS.IS_USER_WHOLESALER]),

            getHeaderHeight() {
                return Math.min(80, document.querySelector(VUE_ELEMENTS.HEADER).getBoundingClientRect().height);
            },

            getCarTitle() {
                return document.querySelector(`[data-car-title-${this.activeCar}]`);
            },

            getLocationHash() {
                return window.location.hash;
            },

            hasLocationHash() {
                return !!this.getLocationHash;
            },

            getCarTabIdByHash() {
                return Number(this.getLocationHash.replace(/\D+/g, ''));
            },
        },
        methods: {
            ...mapActions([VUEX_ACTIONS.CHANGE_CART_PRODUCT_QUANTITY]),

            setFirstTabByActiveCar() {
                // this.activeCar = 0;
                this.currentTab = this.cars[this.activeCar].tabs[0].value;
            },

            setTabIdByHash(tabId) {
                let foundTab = false;

                this.cars.forEach((car, index) => {
                    car.tabs.forEach(tab => {
                        if (tab.value === tabId) {
                            foundTab = true;
                            this.activeCar = index;
                            this.currentTab = tab.value;
                        }
                    });
                });

                if (!foundTab) {
                    this.setFirstTabByActiveCar()
                }
            },

            scrollToCarTitle(cartTitle, delay = 1000) {
                setTimeout(() => {
                    const carTitleElementTop = cartTitle.getBoundingClientRect().top;
                    const headerHeight = this.getHeaderHeight;
                    const indentTop = 20;
                    const topToCarTitle = carTitleElementTop - headerHeight - indentTop;

                    window.scrollBy({
                        top: topToCarTitle,
                        left: 0,
                        behavior: 'smooth',
                    });
                }, delay)
            },

            hideGroups(selectors) {
                selectors.forEach((selector) => {
                    const groups = document.querySelectorAll(`[${selector}]`);
                    groups.forEach((group) => {
                        group.hidden = true;
                    });
                });
            },

            showActiveGroup(selectors, value) {
                selectors.forEach((selector) => {
                    const activeGroup = document.querySelector(`[${selector}="${value}"]`);
                    if (activeGroup === null) return;
                    activeGroup.hidden = false;
                });
            },

            setActiveCar(index) {
                if (this.activeCar !== index) {
                    this.activeCar = index;
                    this.setFirstTabByActiveCar();
                    this.isAccordionAnimation = true;
                }
            }
        },
        watch: {
            currentTab(value) {
                const selectors = [`data-product-tab-${this.activeCar}`, `data-schema-tab-${this.activeCar}`];
                this.hideGroups(selectors);
                this.showActiveGroup(selectors, value);

                // update bottom position for sticky elements
                const stickyElements = [`[data-sticky-schema-${this.activeCar}]`, `[data-sticky-tabs-${this.activeCar}]`];
                stickyElements.forEach((sticky) => {
                    const stickyElement = document.querySelector(sticky);
                    if (!stickyElement) return;
                    UIkit.update(stickyElement, 'update');
                });

                // set active tab
                window.location.hash = HASH_PART_GROUP + this.currentTab;

                // scrolling to accordion title
                const carTitleElement = this.getCarTitle;
                if (!carTitleElement) return;
                this.scrollToCarTitle(carTitleElement, this.isAccordionAnimation ? 800 : 100);
                this.isAccordionAnimation = false;
            },
        }
    })
