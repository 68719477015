import Vue from 'vue/dist/vue';

import store from '@scripts/store';
import { mapMutations } from 'vuex';

import { apiGetMarkets, apiGetModels, apiGetBodies, apiGetYears } from '@scripts/api-methods';
import { mapGetModelsResponse } from '@scripts/mappings';
import { form } from '@scripts/mixins/form';
import { vinMixin } from '@scripts/mixins/vin';
import { formSending } from '@library/forms/form-sending';
import { formValidation } from '@library/forms/validation';
import { dropdownOptions } from '@scripts/mixins/dropdown-options';
import { SEARCH_TABS, VUEX_MUTATIONS } from '@scripts/constants';

import Dropdown from '../dropdown/dropdown.vue';

const filterKeys = {
    brand: 'mark',
    market: 'market',
    model: 'model',
    carBody: 'body',
    year: 'production_year',
};

export default (el, name, data) =>
    new Vue({
        el,
        name,
        store,
        components: {
            Dropdown,
        },
        mixins: [dropdownOptions(), formValidation({ vin_or_frame: { field: 'vin.number' } }), form(), vinMixin(), formSending({ useRecaptcha: true })],
        data: () => ({
            binding: data,
            currentTab: SEARCH_TABS.BRAND_MODEL,
            submitButtonLoading: false,
            brand: null,
            market: null,
            model: null,
            carBody: null,
            year: null,
            oem: {
                number: '',
            },
            vin: {
                number: '',
            },
            dropdownOptions: {
                markets: [],
                brands: [],
                models: [],
                carBodies: [],
                years: [],
            },
            TABS: SEARCH_TABS,
        }),
        beforeMount() {
            this.dropdownOptions = {
                ...this.dropdownOptions,
                brands: this.binding.productMarks.options,
            };

            const wrongFilters = this.binding.selectedFilters.some((filter) => filter.key === 'year' || !Object.values(filterKeys).includes(filter.key));

            if (!wrongFilters) {
                this.binding.selectedFilters.forEach((filter) => {
                    Object.keys(filterKeys).forEach((key) => {
                        if (filterKeys[key] === filter.key) {
                            this[key] = {
                                label: filter.label,
                                value: filter.value,
                            };
                        }
                    });
                });
            }
            if (this.brand && this.model) {
                this.setSelectedCar();
            }
        },
        computed: {
            isSubmitButtonDisabled() {
                return !(this.brand && this.model && this.carBody && this.market);
            },

            isOEM() {
                return this.currentTab === SEARCH_TABS.OEM;
            },

            isBrandModel() {
                return this.currentTab === SEARCH_TABS.BRAND_MODEL;
            },

            isVIN() {
                return this.currentTab === SEARCH_TABS.VIN;
            },

            canGetMarkets() {
                return this.brand.value;
            },

            canGetModels() {
                return this.brand && this.brand.value && this.market && this.market.value;
            },

            canGetBodies() {
                return this.canGetModels && this.model && this.model.value;
            },

            canGetYears() {
                return this.canGetBodies && this.model.value;
            },
        },
        methods: {
            ...mapMutations([VUEX_MUTATIONS.SET_CATALOG_FILTERS_BRAND, VUEX_MUTATIONS.SET_CATALOG_FILTERS_MODEL]),

            onSubmit(event) {
                if (this.isVIN) {
                    this.vinSearch(this.vin.number);
                } else if (this.isOEM) {
                    event.currentTarget.submit();
                } else {
                    let query = '/catalog?';

                    Object.keys(filterKeys).forEach((key) => {
                        if (filterKeys[key] && this[key]) {
                            query += `${encodeURIComponent(filterKeys[key])}=${encodeURIComponent(this[key].value)}&`;
                        }
                    });

                    document.location.href = query;
                }
            },
            getMarkets({ setLoading }) {
                if (!this.canGetMarkets) return;

                setLoading(true);

                apiGetMarkets({
                    product_mark_id: this.brand.value,
                })
                    .then((result) => {
                        this.dropdownOptions.markets = result.items.map((option) => ({
                            label: option.key,
                            value: option.value,
                        }));
                    })
                    .finally(() => setLoading(false));
            },
            getModels({ page, success, setLoading }) {
                if (!this.canGetModels) return;

                setLoading(true);

                this.getPaginatedOptions({
                    apiMethod: apiGetModels,
                    mapMethod: mapGetModelsResponse,
                    params: {
                        product_mark_id: this.brand.value,
                        product_market_id: this.market.value,
                        page,
                    },
                    destPropertyName: 'models',
                    success,
                }).finally(() => setLoading(false));
            },

            getBodies({ setLoading }) {
                if (!this.canGetBodies) return;

                setLoading(true);

                apiGetBodies({
                    product_model_id: this.model.value,
                    production_year: this.year !== null ? this.year.value : null,
                })
                    .then((result) => {
                        this.dropdownOptions.carBodies = result.items.map((option) => ({
                            label: option.key,
                            value: option.value,
                        }));

                        this.dropdownOptions.carBodies = [
                            {
                                label: 'Не выбрано',
                                value: null,
                            },
                            ...this.dropdownOptions.carBodies,
                        ];
                    })
                    .finally(() => setLoading(false));
            },

            getYears({ setLoading }) {
                if (!this.canGetYears) return;

                setLoading(true);

                apiGetYears({
                    product_model_id: this.model.value,
                    product_body_id: this.carBody !== null ? this.carBody.value : null,
                })
                    .then((result) => {
                        this.dropdownOptions.years = result.items.map((option) => ({
                            label: option.key,
                            value: option.value,
                        }));

                        this.dropdownOptions.years = [
                            {
                                label: 'Не выбрано',
                                value: null,
                            },
                            ...this.dropdownOptions.years,
                        ];
                    })
                    .finally(() => setLoading(false));
            },

            filter(items, query) {
                const lowerQuery = query.toLowerCase();
                return items
                    .filter((item) => item.label.toLowerCase().includes(lowerQuery))
                    .sort((a, b) => {
                        const itemStartsWithQuery = (item) => item.label.toLowerCase().startsWith(lowerQuery);
                        return Number(itemStartsWithQuery(b)) - Number(itemStartsWithQuery(a));
                    });
            },

            setSelectedCar() {
                this.setCatalogFiltersBrand(this.brand.label);
                this.setCatalogFiltersModel(this.model.label);
            },

            resetMarket() {
                this.market = null;
                this.dropdownOptions.markets = [];
                this.resetModel();
            },

            resetModel() {
                this.model = null;
                this.dropdownOptions.models = [];
                this.resetCarBodyAndYear();
            },

            resetCarBodyAndYear() {
                this.carBody = null;
                this.dropdownOptions.carBodies = [];
                this.resetYear();
            },

            resetYear() {
                this.year = null;
                this.dropdownOptions.years = [];
            },
        },
        watch: {
            brand(val, oldVal) {
                if (oldVal) {
                    this.resetMarket();
                }
            },
            market(val, oldVal) {
                if (oldVal) {
                    this.resetModel();
                }
            },
            model(val, oldVal) {
                if (oldVal) {
                    this.resetCarBodyAndYear();
                }
            },
            carBody(val) {
                this.dropdownOptions.years = [];

                if (val && val.value === null) {
                    this.carBody = null;
                }
            },

            year(val) {
                this.dropdownOptions.carBodies = [];

                if (val && val.value === null) {
                    this.year = null;
                }
            },
        },
    });
