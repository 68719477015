import Vue from 'vue/dist/vue';
import { formSending } from '@library/forms/form-sending';
import { form } from '@scripts/mixins/form';
import { apiUpdateProfileSettings } from '@scripts/api-methods';
import { showSuccessMessage } from '@library/uikit/notifications';
import { MESSAGES } from '@scripts/constants';

const formDataScheme = () => ({
    priceViewType: null,
    showRetailPriceInCartAndCheckout: false,
    showWeightInCart: false,
});

export default (el, name, data) =>
    new Vue({
        el,
        name,
        data: () => ({
            isInitialDataSet: false,
            formData: formDataScheme(),
        }),
        mixins: [form(), formSending()],
        beforeMount() {
            this.formData.priceViewType = data.priceDisplayType;
            this.formData.showRetailPriceInCartAndCheckout = data.isRetailPriceInCartAndOrdersShown;
            this.formData.showWeightInCart = data.isProductWeightInCartShown;
            this.$nextTick(() => {
                this.isInitialDataSet = true;
            });
        },
        methods: {
            sendRequest() {
                return this.sendForm(
                    () =>
                        apiUpdateProfileSettings({
                            price_display_type: this.formData.priceViewType,
                            show_product_weight_in_cart: this.formData.showWeightInCart,
                            show_retail_price_in_cart_and_orders: this.formData.showRetailPriceInCartAndCheckout,
                        }),
                    () => {
                        showSuccessMessage({ text: MESSAGES.SUCCESS_SETTINGS_SAVED });
                    }
                );
            },
        },
        watch: {
            formData: {
                deep: true,
                handler() {
                    if (!this.isInitialDataSet) return;

                    this.sendRequest();
                },
            },
        },
    });
