<template>
    <div class="FilePicker">
        <label
            class="FilePicker__attachFilesButton Button Button--underlinedLink"
        >
            <input
                type="file"
                class="FilePicker__input"
                accept=".doc, .docx, .pdf, .png, .jpg, .jpeg"
                multiple
                @change="uploadFile($event)"
            />
            <span class="Button__icon">
                <inline-svg :src="'/assets/icons/plus.svg'"></inline-svg>
            </span>
            <span class="Button__text">Прикрепить файлы (pdf, doc/docx, png, jpg, jpeg)</span>
        </label>

        <ul class="FilePicker__itemsList" v-if="files.length > 0">
            <li class="FilePicker__item" v-for="item in files" :key="item.id">
                <button
                    class="FilePicker__removeButton"
                    type="button"
                    @click="removeFile(item.id)"
                >
                    <span class="FilePicker__removeButtonText">+</span>
                </button>
                <span class="FilePicker__filename" v-text="item.name"></span>
            </li>
        </ul>
    </div>
</template>

<script>
import { showErrorMessage } from '@library/uikit/notifications';
import InlineSvg from 'vue-inline-svg';
import { getBase64FromFile } from '@scripts/helpers';
import { MESSAGES } from '@scripts/constants';

export default {
    components: {
        InlineSvg,
    },
    data() {
        return {
            files: [],
        };
    },
    computed: {},
    methods: {
        clear() {
            this.files = [];
        },

        async uploadFile(event) {
            const input = event.target;
            const files = input.files;

            if (!files || !files[0]) {
                return;
            }

            try {
                for await (const file of files) {
                    if (file.size / 1024 / 1024 > 7) {
                        showErrorMessage({
                            text: MESSAGES.FILE_UPLOAD_SIZE_ERROR,
                        });
                        this.clear();
                        input.value = '';
                        return;
                    }
                    const base64FromFile = await getBase64FromFile(file);
                    const bytesFile = {
                        id: this.files.length,
                        name: file.name,
                        bytes: base64FromFile,
                    };
                    this.files.push(bytesFile);
                }
                await this.$emit('change', { files: this.files });
                input.value = '';
            } catch (error) {
                showErrorMessage({ text: MESSAGES.FILES_UPLOAD_ERROR });
                this.clear();
            }
        },

        removeFile(fileId) {
            this.files.splice(
                this.files.findIndex((file) => {
                    return file.id === fileId;
                }),
                1
            );
            this.$emit('change', { files: this.files });
        },
    },
};
</script>
