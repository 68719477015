import Vue from 'vue/dist/vue';
import { mapActions } from 'vuex';

import { MESSAGES, VUEX_ACTIONS } from '@scripts/constants';
import { multiSearch } from '@scripts/api-methods';
import { form } from '@scripts/mixins/form';
import store from '@scripts/store';

import { showErrorMessage, showSuccessMessage } from '@library/uikit/notifications';
import { FeipApiServerError } from '@library/scripts/api/error';

export default (el, name) =>
    new Vue({
        el,
        name,
        store,
        mixins: [form()],
        data: () => ({
            text: '',
            foundItems: [],
            isLoading: false,
            isCartLoading: false,
            isContentHighlighted: false,
            hasContent: false,
            hasExistingItems: false,
            showLegend: false,
        }),
        computed: {
            searchQueries() {
                return this.text.length > 0
                    ? this.removeDoubleBreakLines(this.text)
                        .split('\n')
                        .map((line) => {
                            const [query, quantity] = this.extractInfoFromItem(line);
                            return [query || '', quantity && !Number.isNaN(Number(quantity)) ? Math.ceil(Number(quantity)) : null];
                        })
                    : [];
            },
            formattedText() {
                let index = 0;
                return this.searchQueries
                    .map((line) => {
                        const [query, quantity] = line;

                        /** @var {MultiSearchItem} object */
                        const result = this.foundItems.find((foundItem) => foundItem.search_index === index);

                        let modifier = 'error';

                        const isDuplicate = this.isDuplicateItem(query);
                        if (result || isDuplicate) {
                            if (isDuplicate || result.oem !== null) {
                                modifier = 'oem';
                            } else if (result && result.type === 'part') {
                                modifier = 'part';
                            } else if (result && result.type === 'aftermarket') {
                                modifier = 'aftermarket';
                            }
                        }

                        const idElement = `<span class='
                                MultiSelect__resultId
                                MultiSelect__resultId--${modifier}
                            '>${query}</span>`;
                        const countElement = quantity ? `<span class='MultiSelect__resultCount'>${quantity}</span>` : null;

                        if (!this.isDuplicateItem(query)) index += 1;
                        return countElement ? `${idElement} ${countElement}` : idElement;
                    })
                    .join('<br>');
            },
            results() {
                let index = 0;
                const result = [];
                this.searchQueries.forEach((searchQuery) => {
                    if (!this.isDuplicateItem(searchQuery[0])) {
                        if (this.foundItems.some((foundItem) => foundItem.search_index === index)) {
                            const [query, quantity] = searchQuery;
                            const foundItems = this.foundItems.filter((foundItem) => foundItem.search_index === index);

                            const formattedQuantity = quantity || 1;

                            foundItems.forEach((k) => {
                                let mode = 'default';

                                if (k.oem && this.foundItems.some((foundItem) => foundItem.search_index === index
                                    && foundItem.oem === k.oem
                                    && foundItem.product.vendor_code !== k.product.vendor_code
                                )) {
                                    mode = 'compositeOem';
                                } else if (this.foundItems.some((foundItem) => foundItem.search_index !== index && foundItem.product.id === k.product.id)) {
                                    mode = 'duplicate';
                                }

                                result.push({
                                    ...k,
                                    customData: {
                                        query,
                                        mode,
                                        chosenQuantity: formattedQuantity,
                                        totalQuantity: k.product.quantity_in_package * formattedQuantity,
                                    },
                                });
                            });
                        } else {
                            result.push(null);
                        }

                        index += 1;
                    }
                });

                return result;
            },
            cantAddToCard() {
                return this.results.every((item) => item === null);
            },
        },
        methods: {
            ...mapActions([VUEX_ACTIONS.ADD_TO_CART_MULTIPLY]),

            trim(text) {
                return text.replace(/^\n|\n$/g, '').trim();
            },

            extractInfoFromItem(value) {
                return value
                    .trim()
                    .split(/(\s+)/)
                    .filter((item) => item.trim().length > 0);
            },

            isDuplicateItem(value) {
                return !!value.trim().match(/^([-–—]+)+([0-9\s]*)$/gu);
            },

            format(queries) {
                const result = [];
                const items = this.removeDoubleBreakLines(this.text)
                    .split('\n')
                    .filter((item) => !this.isDuplicateItem(item));

                items.forEach((item, index) => {
                    const [query, quantity] = this.extractInfoFromItem(item);
                    const preFoundItems = queries.filter((q) => q.search_index === index);
                    const foundItems =
                        preFoundItems.length > 1
                            ? preFoundItems
                                .map(() => {
                                    const placeholder = '–'.repeat(query.length);
                                    if (quantity) return `${placeholder} ${quantity}`;
                                    return placeholder;
                                })
                                .slice(1)
                            : [];

                    result.push(item, ...foundItems);
                });
                return result.join('\n');
            },

            addDuplicates(queries) {
                const result = [];

                queries.forEach((query, index) => {
                    const items = this.foundItems
                        .filter((item, i) => i !== index && item.search_index === index)
                        .map((item) => ['–'.repeat((item.oem || item.product.vendor_code).length), null]);
                    result.push(query, items);
                });

                return result;
            },

            // remove pairs of break lines \n\n\n -> \n
            removeDoubleBreakLines(text) {
                return text.replace(/[\r\n]+/g, '\n');
            },

            fetchResults() {
                if (this.searchQueries.length === 0) return;
                this.isLoading = true;

                const queries = this.searchQueries.map((item) => item[0]).filter((item) => item !== undefined && !this.isDuplicateItem(item));
                /** @var {MultiSearchRequest} object */
                const payload = { search_queries: queries };
                multiSearch(payload)
                    .then(({ items }) => {
                        this.foundItems = items;
                        this.text = this.format(items);
                    })
                    .catch((error) => {
                        if (error instanceof FeipApiServerError) {
                            const foundError = error.invalidFields.find((item) => {
                                return item.key.includes('search_queries');
                            });
                            if (foundError) {
                                const firstError = Object.entries(error.getInvalidFieldsMessages()).shift();
                                if (firstError) showErrorMessage({ text: `${firstError[0]}: ${firstError[1]}` });
                            } else {
                                this.failedFormSending(error);
                            }
                        }
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },

            insertExampleData(text) {
                if (this.isLoading) return;

                if (this.$refs.content.innerText.length > 0) {
                    this.$refs.content.innerText += '\n';
                }

                this.$refs.content.innerText += text;
                this.onContentChange();
                this.onContentBlur();
            },

            onContentFocus() {
                this.isContentHighlighted = false;
            },

            onContentBlur() {
                this.text = this.trim(this.$refs.content.innerText);
                this.isContentHighlighted = true;

                if (!this.hasContent) this.clearContent();
                else this.fetchResults();
            },

            onContentChange() {
                const content = this.trim(this.$refs.content.innerText);
                this.hasContent = content.length > 0 && content !== '\n';

                if (!this.hasContent) this.clearContent();
            },

            onContentPaste(event) {
                event.preventDefault();
                const paste = (event.clipboardData || window.clipboardData).getData('text');
                document.execCommand('insertText', false, paste);
            },

            clearContent() {
                this.text = '';
                this.foundItems = [];
            },

            handleAddToCart() {
                const dirtyPayload = {};

                // eslint-disable-next-line no-restricted-syntax
                for (const item of this.results) {
                    if (item) {
                        const key = item.product.id;
                        const qty = item.customData.chosenQuantity;
                        if (dirtyPayload[key] !== undefined) {
                            dirtyPayload[key] += qty;
                        } else {
                            dirtyPayload[key] = qty;
                        }
                    }
                }

                const payload = Object.entries(dirtyPayload).map(([id, qty]) => ({
                    product_id: Number(id),
                    quantity_units: qty,
                }));

                this.isCartLoading = true;
                this.addToCartMultiply(payload)
                    .then(() => {
                        showSuccessMessage({ text: MESSAGES.PRODUCTS_ADDED_SUCCESS });
                        this.clearContent();
                    })
                    .finally(() => {
                        this.isCartLoading = false;
                    });
            },
        },
    });
