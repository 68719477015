'use strict'

export default {
	ru: {
		INVALID_RESPONSE_ERROR: 'Ошибка обращения к серверу',
		NETWORK_CONNECTION_ERROR: 'Не удалось отправить запрос. Проверьте ваше соединение с сетью.',
		NOTIFICATION_TITLES: {
			DANGER: 'Ошибка',
			WARNING: 'Внимание',
			SUCCESS: 'Готово',
			PRIMARY: 'Внимание',
		},
		ERROR_HANDLER: {
			DEFAULT_ERROR: 'Произошла ошибка при отправке запроса. Повторите позднее.',
			VALIDATION_ERROR: 'Проверьте введённые данные',
		},
	},
	en: {
		INVALID_RESPONSE_ERROR: 'Invalid response from server',
		NETWORK_CONNECTION_ERROR: 'Failed to send request. Check your network connection.',
		NOTIFICATION_TITLES: {
			DANGER: 'Error',
			WARNING: 'Attention',
			SUCCESS: 'Success',
			PRIMARY: 'Attention',
		},
		ERROR_HANDLER: {
			DEFAULT_ERROR: 'An error occurred while sending the request. Repeat later.',
			VALIDATION_ERROR: 'Check the entered data',
		},
	},
}
