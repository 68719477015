'use strict'

import { Is } from '../is'

/**
 * Create Vue instances from SFC or callback
 * @param {{ query: string, element: string, name: null|string, init: null|Function, component: null|Component, data: Object }[]} vueInstances
 * @param Vue
 * @example
 *  import initFoo from './components/foo'
 *  import BarComponent from './components/bar'
 *
 *  initVueInstances([
 *        { query: '[vue-foo]', init: initFoo }, // Initalize all '[vue-foo]' by callback
 *        { element: '#bar', component: BarComponent, name: 'SuperBarComponent' }, // Create BarComponent with custom name & mount him on element
 *    ])
 *
 *  components/foo.js:
 *
 *    export default el => new Vue({
 *		el,
 *		name: 'foo',
 *		data: () => ({
 *			field: '',
 *		}),
 *	})
 *
 *  components/bar.vue:
 *
 *  <script>
 *    export default {
 *  		name: 'bar',
 *			data: () => ({
 *				field: '',
 *			}),
 *  	}
 *  </script>
 */
export default function initVueInstances(vueInstances, Vue = null) {
	const loadedKeys = []
	vueInstances.forEach(({ query, element, name = null, init = null, asyncInit = null, component = null, data = {} }) => {
		if (loadedKeys.includes(query) || loadedKeys.includes(element)) {
			throw new SyntaxError(`Vue initialize key duplicate:\n Query: ${query ? query : element}`)
		} else loadedKeys.push(query ? query : element)
		/**
		 * Initialize strategy
		 * @param {string|Element}	el
		 * @param {string|null} 	newName
		 */
		const initializer = async (el, newName) => {
			if (!Is.null(component) && Is.null(init)) {
				const SFC = Object.assign({}, component)
				if (!Is.null(newName)) SFC.name = newName
				let SFCData = SFC.data
				if (Is.function(SFCData)) SFCData = SFCData()
				SFC.data = function () { return Object.assign(SFCData, data) }
				if (Vue) {
					const Component = Vue.extend(SFC)
					const instance = new Component()
					instance.$mount(el)
				}
			} else if (Is.null(component) && !(Is.null(init) && Is.null(asyncInit))) {
				if (!Is.null(asyncInit)) {
					const { default: module } = await asyncInit()
					module(el, newName, data)
				} else init(el, newName, data)
			} else {
				throw new SyntaxError('Invalid arguments: property "init" or "component" must be defined, not both.')
			}
		}

		if (query) document.querySelectorAll(query).forEach(el => initializer(el, name))
		else if (document.querySelector(element)) initializer(element, name)
	})
}
