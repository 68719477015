/**
 * Добавление данных о продажах в яндекс
 * @param method    detail, add, remove, purchase
 * @param products  [{"id","name","price","brand","category","variant","quantity"}]
 * @param orderId   string
 */
export const yaEcommerce = (method, products,  orderId = null) => {
    if (window.dataLayer){
        try{
            let yaObj = {
                "ecommerce": {
                    "currencyCode": "RUB",
                }
            };
            yaObj.ecommerce[method] ={
                "products": products
            };
            if(orderId && method === 'purchase'){
                yaObj.ecommerce[method].actionField = {
                    "id" : orderId
                };
            }
            window.dataLayer.push(yaObj);
            return true;
        }catch (e) {
            console.log('Error: '+e.message);
        }
    }
    return false;
};

/**
 * Отслеживание событий CarrotQuest
 * @param eventName
 * @param params
 */
export const carrotquestTrack = (eventName, params = null) => {
    if (typeof carrotquest !== 'undefined' && carrotquest !== null && carrotquest){
        try{
            if (params){
                carrotquest.track(eventName, params);
            }else{
                carrotquest.track(eventName);
            }
        }catch (e) {
            console.log('Error: '+e.message);
        }

    }
};