var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "BranchItem",
      class: {
        "BranchItem--withCoordinates": _vm.hasCoordinates,
        "BranchItem--active": _vm.isActive,
      },
      on: {
        click: function ($event) {
          _vm.hasCoordinates &&
            _vm.showOnMap($event, _vm.latitude, _vm.longitude, true)
        },
      },
    },
    [
      _vm.title
        ? _c("h2", {
            staticClass: "BranchItem__title",
            domProps: { innerHTML: _vm._s(_vm.title) },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.address
        ? _c("span", {
            staticClass: "BranchItem__address",
            domProps: { innerHTML: _vm._s(_vm.address) },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.phones, function (phone, index) {
        return _c(
          "div",
          { key: index, staticClass: "BranchItem__phoneContainer" },
          [
            _c("a", {
              staticClass: "BranchItem__phone",
              attrs: {
                href: `tel:${phone.value}`,
                "data-disable-show-map": "true",
              },
              domProps: {
                innerHTML: _vm._s(_vm.formatPhone(`${phone.value}`)),
              },
            }),
            _vm._v(" "),
            phone.comment
              ? _c("span", {
                  staticClass: "text",
                  domProps: { innerHTML: _vm._s(phone.comment) },
                })
              : _vm._e(),
          ]
        )
      }),
      _vm._v(" "),
      _vm.schedule
        ? _c("span", {
            staticClass: "BranchItem__schedule",
            domProps: { innerHTML: _vm._s(_vm.schedule) },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.hasCoordinates
        ? _c(
            "a",
            {
              staticClass:
                "Button Button--underlinedLink BranchItem__showOnMap",
              attrs: { href: "#contacts-map" },
              on: {
                click: function ($event) {
                  return _vm.showOnMap(
                    $event,
                    _vm.latitude,
                    _vm.longitude,
                    false
                  )
                },
              },
            },
            [
              _c("span", { staticClass: "Button__text" }, [
                _vm._v("\n            Показать на карте\n        "),
              ]),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }