import Vue from 'vue/dist/vue';
import { mapState } from "vuex";
import { get } from 'lodash-es';
import { GLOBAL, DOCUMENT_EVENTS } from '@scripts/constants';
import store from '@scripts/store';
import { mapCityForDropdown } from "@scripts/mappings";
import BranchItem from '../../components/branch-item/branch-item.vue';

const YMAP = {
    id: 'contacts-map',
    config: {
        setBounds: true,
        markerCommonOptions: {
            preset: 'islands#dotIcon',
            iconColor: '#FFCB4F',
        },
        clusterer: {
            clusterIconColor: '#FFCB4F',
            zoomMargin: 50,
        },
    },
    coordinates: {
        RussianCoordinates: [60, 100],
    },
    zoom: {
        map: 2,
        mapMobile: 1.5,
        point: 17,
        bound: 10,
    },
    setOptions: {
        checkZoomRange: true,
        duration: 200,
    },
};

const TabType = {
    Warehouses: 'warehouses',
    Distributors: 'distributors',
    RetailStores: 'retailStores',
    ServiceCenters: 'serviceCenters',
};

const defaultAllCities = {
    label: "Все города",
    value: null,
};

export default (el, name, data) =>
    new Vue({
        el,
        name,
        store,
        components: {
            BranchItem,
        },
        data: () => ({
            map: undefined,
            activeItemId: null,
            activeTab: TabType.RetailStores,
            lastTab: null,
            activeCity: null,
            currentlyDisplayedStores: [],
            binding: data.bindings,
            mapDefaults: data.mapDefaults,
            dropdownOptions: {
                cities: [],
            },
        }),
        computed: {
            ...mapState(['selectedCity', 'citiesWithWarehouses']),
        },
        beforeMount() {
            const currentCity = this.selectedCity.city ?? null;
            // const citiesToChoose = this.citiesWithWarehouses.map(mapCityForDropdown);
            // const citiesToChoose = this.binding.retailStores.map(mapCityForDropdown);
            const stores = this.getStores(this.binding, this.activeTab);
            this.setDisplayedStores(stores, currentCity);
            // this.dropdownOptions.cities = [defaultAllCities].concat(citiesToChoose);
            this.dropdownOptions.cities = this.getCitiesForDropdown(this.binding.retailStores);
            this.activeCity = currentCity;
            this.initMap();

            
        },
        methods: {
            initMap() {
                const globals = GLOBAL.APP_ENTRY_POINT;

                if (!globals.ymaps) {
                    globals.ymaps = {};
                }

                if (!globals.ymaps.instances) {
                    globals.ymaps.instances = {};
                }

                const maps = globals.ymaps;

                maps.instances[YMAP.id] = {
                    id: YMAP.id,
                    points: this.preparePointsForMap(),
                    config: {
                        ...this.mapDefaults,
                        ...YMAP.config,
                    },
                };

                document.addEventListener(DOCUMENT_EVENTS.MAP_INITIALIZED, () => {
                    this.map = globals.ymaps.instances[YMAP.id].instance;
                    this.updateMap();
                });

                document.dispatchEvent(new Event(DOCUMENT_EVENTS.MAP_CONFIG_SET));
            },

            preparePointsForMap() {
                return this.currentlyDisplayedStores
                    .filter((item) => item.hasCoordinates)
                    .map((item) => ({
                        lat: item.lat,
                        lng: item.lng,
                        properties: {
                            balloonContentHeader: item.title,
                            balloonContentBody: item.address,
                        },
                    }));
            },

            showOnMap(event) {
                this.activeItemId = event.id;

                if (this.map === undefined) return;

                this.setCenter([event.lat, event.lng], YMAP.zoom.point);
            },

            updateMap() {
                if (this.map === undefined) return;

                const { ymaps } = window;

                const geoObjectCollection = this.map.geoObjects.get(0);
                const defaultGeoObjectCollection = new ymaps.GeoObjectCollection({});
                const currentGeoObjectCollection = geoObjectCollection || defaultGeoObjectCollection;

                const geoObjects = [];
                const geoObjectsCoordinates = [];

                this.currentlyDisplayedStores.forEach((item, index) => {
                    if(!item.hasCoordinates) return;

                    const coordinates = [item.lat, item.lng];

                    const placemark = new ymaps.Placemark(
                        coordinates,
                        {
                            balloonContentHeader: item.title,
                            balloonContentBody: item.address,
                        },
                        YMAP.config.markerCommonOptions,
                    );

                    placemark.events.add('click', () => {
                        this.activeItemId = index;
                    });

                    geoObjects.push(placemark);
                    geoObjectsCoordinates.push(coordinates);
                });

                currentGeoObjectCollection.removeAll();

                if (geoObjects.length > 0) {
                    geoObjects.forEach((item) => currentGeoObjectCollection.add(item));

                    if (geoObjectCollection === undefined) this.map.geoObjects.add(currentGeoObjectCollection);

                    const hasAllStoresOnOnePoint = geoObjectsCoordinates.length > 1
                        && this.hasAllStoresOnOnePoint(geoObjectsCoordinates);

                    if (geoObjectsCoordinates.length === 1 || hasAllStoresOnOnePoint) {
                        this.setCenter(geoObjectsCoordinates[0], YMAP.zoom.bound);
                    } else {
                        this.setBounds(this.map.geoObjects.getBounds());
                    }

                } else {
                    const zoom = this.isMobile() ? YMAP.zoom.mapMobile : YMAP.zoom.map;
                    this.setCenter(YMAP.coordinates.RussianCoordinates, zoom);
                }
            },

            setCenter(coordinates, zoom = 10, options = YMAP.setOptions) {
                this.map.setCenter(coordinates, zoom, options);
            },

            setBounds(coordinates, options = YMAP.setOptions) {
                this.map.setBounds(coordinates, options);
            },

            isMobile() {
                return window.matchMedia('(max-width: 999px)').matches;
            },

            hasAllStoresOnOnePoint(coordinates) {
                const isOnePoint = (current) =>
                    (checkpoint) => current[0] === checkpoint[0] && current[1] === checkpoint[1];

                const checkpoint = coordinates[0];
                return coordinates.every(isOnePoint(checkpoint));
            },

            getStores(tabs, type) {
                return get(tabs, type);
            },

            setDisplayedStores(stores, city) {
                // this.currentlyDisplayedStores = city ? stores.filter(s => s.city === city) : stores;
                if ( !(this.lastTab && (this.lastTab === this.activeTab)) ){
                    city = (city) ?? (this.selectedCity.city ?? null);
                }
                if(city && (stores.filter(s => s.city === city)).length > 0 ){
                    this.currentlyDisplayedStores = stores.filter(s => s.city === city);
                    this.activeCity = city;
                }else{
                    this.currentlyDisplayedStores = stores;
                    this.activeCity = null;
                }
                this.lastTab = this.activeTab;
            },

            //подготовить список городов для выпадающего меню
            getCitiesForDropdown(branches){
                //не лучшее решение
                let citesForDropdown = branches.map(mapCityForDropdown);
                citesForDropdown.sort((a,b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));
                return [defaultAllCities].concat(...new Map(citesForDropdown.map((item) => [item["value"], item])).values());
            },

        },
        watch: {
            activeTab(val) {
                this.activeItemId = null;

                this.dropdownOptions.cities = this.getCitiesForDropdown(this.binding[val]);

                const stores = this.getStores(this.binding, val);
                this.setDisplayedStores(stores, this.activeCity);

                // this.dropdownOptions.cities = [defaultAllCities].concat(this.binding[val].cities.map(mapCityForDropdown));
                this.updateMap();
            },
            activeCity() {
                this.activeItemId = null;
                const stores = this.getStores(this.binding, this.activeTab);
                this.setDisplayedStores(stores, this.activeCity);
                this.updateMap();
            },
        },
    });
