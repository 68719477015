import UIkit from '../../vendor/uikit/uikit';

export default () => {
    const button = document.querySelector('[data-comment-form-control]');

    if (button === null) return;

    const sticky = document.querySelector('[data-gallery-sticky]');

    if (sticky === null) return;

    button.addEventListener('click', () => {
        UIkit.update(sticky, 'update');
    });
};
