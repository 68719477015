import Vue from 'vue/dist/vue';

export default (el, name, data) =>
    new Vue({
        el,
        name,
        data() {
            return {
                vinInfo: data.vinInfo,
            }
        },
    });
