'use strict'

/**
 * Implements Ruby hash & PERL autovivifying hash
 * http://raganwald.com/2018/09/12/auto-vivifying-hash.html
 */

const DEFAULT_KEY = Symbol('default-key')
const MAP = Symbol('map')

export class HashMap {
	constructor (defaultValue = void 0) {
		this[MAP] = new Map()
		this[DEFAULT_KEY] = defaultValue
	}

	has(key) {
		return this[MAP].has(key)
	}

	get(key) {
		if (this[MAP].has(key)) {
			return this[MAP].get(key)
		} else {
			const defaultValue = this[DEFAULT_KEY]

			if (defaultValue instanceof Function) {
				return defaultValue(this, key)
			} else {
				return defaultValue
			}
		}
	}

	set(key, value) {
		return this[MAP].set(key, value)
	}
}

export class AutovivifyingHashMap extends HashMap {
	constructor () {
		super((target, key) => target.set(key, new AutovivifyingHashMap()))
	}
}

/**
 * Covert params to single HEX hash
 * That is the way of implementation in Java (bitwise operator)
 * @param any
 * @returns {string}
 */
export function anyToHexHash(...any) {
	let str = any.map(s => s instanceof Object ? JSON.stringify(s) : s.toString()).join(':')
	let hash = 0
	for (let i = 0; i < str.length; i++) {
		hash = ((hash << 5) - hash) + str.charCodeAt(i)
		hash = hash & hash // Convert to 32bit int
	}
	return (hash >>> 0).toString(16).toUpperCase()
}
