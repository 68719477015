import get from 'lodash-es/get';

const setLocale = (src, globalOptions) => {
    const fallbackLanguage = 'ru';
    const forcedLanguage = get(globalOptions, 'forcedLanguage', null);
    const languageMap = {
        en: 'en_US',
        ru: 'ru_RU',
        ...get(globalOptions, 'languageMap', {}),
    };

    const lang = document.documentElement.getAttribute('lang') || fallbackLanguage;
    const locale = forcedLanguage || languageMap[lang] || fallbackLanguage;
    src.searchParams.set('language', locale);

    return locale;
};

const setYmapKey = (src, scope, globalOptions) => {
    const ymapKey = get(globalOptions, 'mapApiKey') || get(scope, 'yandexMapApiKey');
    if (!ymapKey) {
        throw new Error('Yandex map API key is not set');
    }

    src.searchParams.set('apiKey', ymapKey);
};

/**
 * @return {[Number, Number]} Coordinates - latitude, longitude
 */
const formatPoint = (lat, lng) => [
    parseFloat(lat),
    parseFloat(lng),
];

/**
 * Placemark options
 * @see https://yandex.ru/dev/maps/jsapi/doc/2.1/ref/reference/Placemark.html/#Placemark__param-options
* */
const buildPlacemarkOptions = (point, markerCommonOptions) => {
    const options = get(point, 'options', {});

    const defaults = {
        iconLayout: 'islands#icon',
        iconColor: 'blue',
        iconImageHref: undefined,
        iconImageSize: undefined,
        iconImageOffset: undefined,
        disableTouchDevicesDrag: true,
        balloonShadow: false,
        balloonOffset: [3, -50],
        balloonPanelMaxMapArea: 0,
        hideIconOnBalloonOpen: false,
        ...markerCommonOptions,
    };

    return { ...defaults, ...options };
};

const buildPlacemark = (point, markerCommonProperties = {}, markerCommonOptions = {}) => {
    const { ymaps } = window;
    if (ymaps === undefined) return;

    const { lat, lng } = point;
    if (lat === undefined || lng === undefined) return;

    const properties = get(point, 'properties', {});
    const options = buildPlacemarkOptions(point, markerCommonOptions);

    return new ymaps.Placemark(
        formatPoint(lat, lng),
        {...markerCommonProperties, ...properties},
        options,
    );
};

const getConfig = (ymap) => {
    const config = get(ymap, 'config', {});
    const zoom = Number(get(ymap, 'zoom', 12));
    const points = get(ymap, 'points', []);
    const center = get(ymap, 'center', undefined);

    const {
        markerCommonProperties = {},
        markerCommonOptions = {},
        controls = ['fullscreenControl', 'geolocationControl', 'zoomControl'],
        setBounds = false,
        disableTouchDevicesDrag = false,
        clusterer = {},
        ...customConfig
    } = config;

    return {
        customConfig: customConfig || {}, markerCommonProperties, markerCommonOptions, controls, setBounds,
        disableTouchDevicesDrag: Boolean(disableTouchDevicesDrag), clustererConfig: clusterer, zoom, points, center,
    };
};

/**
 * Init Yandex Maps instance
 * @see https://tech.yandex.com/maps/jsapi/doc/2.1
 */
export default function initYandexMaps(scope, globalMapOptions = {}) {
    const script = document.createElement('script');
    const src = new URL(get(globalMapOptions, 'mapSource', 'https://api-maps.yandex.ru/2.1/'));

    const lang = setLocale(src, globalMapOptions);
    setYmapKey(src, scope, globalMapOptions);

    if (!scope.ymaps) scope.ymaps = {};
    if (!scope.ymaps.instances) scope.ymaps.instances = {};
    if (Object.keys(scope.ymaps.instances).length > 0) {
        src.searchParams.set('onload', 'window.initAllYMaps');
        src.searchParams.set('load', 'package.standard');
        src.searchParams.set('lang', lang);
        script.src = src.toString();
        script.async = true;
        document.body.appendChild(script);
    }

    window.initAllYMaps = () => {
        const { ymaps } = window;
        if (ymaps === undefined) return;

        Object.keys(scope.ymaps.instances).forEach(mapKey => {
            const ymap = scope.ymaps.instances[mapKey];
            const config = getConfig(ymap);
            const {
                customConfig, markerCommonProperties, markerCommonOptions, controls,
                setBounds, disableTouchDevicesDrag, clustererConfig, zoom, points
            } = config;
            let { center } = config;

            const mapElement = document.getElementById(mapKey);
            if (mapElement) {
                const setCenter = () => {
                    const [firstPoint] = points;
                    if (firstPoint !== undefined) {
                        const { lat, lng } = firstPoint;
                        if (lat !== undefined && lng !== undefined) {
                            center = formatPoint(lat, lng);
                        }
                    }

                    center = formatPoint(0, 0);
                };

                if (center === undefined) setCenter(center);

                ymap.instance = new ymaps.Map(mapKey, { zoom, center, controls, ...customConfig });
                ymap.markers = [];

                if (points.length > 0) {
                    points.forEach((point) => {
                        const marker = buildPlacemark(point, markerCommonProperties, markerCommonOptions);
                        if (marker !== undefined) ymap.markers.push(marker);
                    });

                    const isCluster = clustererConfig !== false;
                    if (isCluster) {
                        const clusterer = new ymaps.Clusterer(clustererConfig);
                        clusterer.add(ymap.markers);
                        ymap.instance.geoObjects.add(clusterer);
                    } else {
                        ymap.markers.forEach((marker) => ymap.instance.geoObjects.add(marker));
                    }

                    if (setBounds !== false) {
                        const options = {
                            zoomMargin: get(setBounds, 'zoomMargin', 50),
                            checkZoomRange: get(setBounds, 'checkZoomRange', false),
                        };

                        ymap.instance.setBounds(
                            ymap.instance.geoObjects.getBounds(),
                            options
                        );
                    }

                    // disable drag map on touch devices while scroll
                    if (('ontouchstart' in window || navigator.msMaxTouchPoints) && disableTouchDevicesDrag) {
                        ymap.instance.behaviors.disable('drag')
                    }
                }

                document.dispatchEvent(new CustomEvent('ymap-initialized', { detail: { id: mapKey } }));
                document.dispatchEvent(new CustomEvent(`ymap-initialized_${mapKey}`, { detail: { id: mapKey } }));
            } else {
                throw new Error(`Map with id (${mapKey}) wasn't found on a page`);
            }
        });
    };
}
