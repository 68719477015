import Vue from 'vue/dist/vue';
import { formSending } from '@library/forms/form-sending';
import { formValidation } from '@library/forms/validation';
import { form } from '@scripts/mixins/form';
import { apiSendIndustrySupplyRequest } from '@scripts/api-methods';
import { VALIDATION_MESSAGES, VALIDATION_RULES } from '@scripts/constants';
import { INPUT_MASKS } from '@library/scripts/constants';
import VueTheMask from 'vue-the-mask';

Vue.use(VueTheMask);

const formDataScheme = () => ({
    name: '',
    email: '',
    phone: '',
    city: '',
    comment: '',
});

export default (el, name) =>
    new Vue({
        el,
        name,
        data: () => ({
            formData: formDataScheme(),
            masks: {
                phone: INPUT_MASKS.PHONE_MASK,
            },
        }),
        mixins: [
            form(),
            formSending({ useRecaptcha: true }),
            formValidation(
                {
                    name: {
                        field: 'formData.name',
                        rules: {
                            required: true,
                            ...VALIDATION_RULES.SMALL200_TEXT,
                        },
                    },
                    email: {
                        field: 'formData.email',
                        rules: {
                            required: true,
                            ...VALIDATION_RULES.EMAIL,
                        },
                    },
                    phone: {
                        field: 'formData.phone',
                        rules: {
                            required: true,
                            ...VALIDATION_RULES.PHONE,
                        },
                    },
                    city: {
                        field: 'formData.city',
                        rules: {
                            required: true,
                            ...VALIDATION_RULES.LARGE_TEXT,
                        },
                    },
                    comment: {
                        field: 'formData.comment',
                        rules: {
                            required: false,
                            ...VALIDATION_RULES.LARGE_TEXT,
                        },
                    },
                },
                VALIDATION_MESSAGES
            ),
        ],
        methods: {
            clearForm() {
                this.formData = formDataScheme();
            },
            sendRequest() {
                const f = this.formData;
                return this.sendForm(() =>
                    apiSendIndustrySupplyRequest(
                        this.optionalPayload({
                            name: f.name,
                            email: f.email,
                            phone: f.phone,
                            city: f.city,
                            comment: this.isOptional(f.comment, ''),
                            captcha_token: this.token,
                        })
                    )
                );
            },
        },
    });