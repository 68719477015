var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", [
    _c(
      "div",
      { staticClass: "ProductSchema__accordionTitle uk-accordion-title" },
      [_c("span", [_vm._v(_vm._s(_vm.category) + " > " + _vm._s(_vm.name))])]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "ProductSchema__accordionContent uk-accordion-content",
        on: { mouseenter: _vm.handleMouseEnterContent },
      },
      [
        _c("section", { staticClass: "ProductSchema__schemaWrapper" }, [
          _c(
            "div",
            {
              staticClass: "ProductSchema__schemaContainer",
              on: { wheel: _vm.onWheelSchemaContainer },
            },
            [
              _c(
                "div",
                { ref: "schemaDrag", staticClass: "ProductSchema__schemaDrag" },
                [
                  _c(
                    "div",
                    { staticClass: "ProductSchema__schemaControl" },
                    [
                      _c("img", {
                        ref: "schema",
                        staticClass: "ProductSchema__image",
                        attrs: { src: _vm.image, alt: "", draggable: "false" },
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.filterDetailsForPins, function (item, index) {
                        return _vm.imageNaturalSize && _vm.recalculated
                          ? _c("div", {
                              key: index,
                              staticClass: "ProductSchema__imagePin",
                              class: {
                                "ProductSchema__imagePin--visited":
                                  _vm.visitedDetails.includes(item.code),
                                "ProductSchema__imagePin--hover":
                                  _vm.hoveredDetail === item.code,
                                "ProductSchema__imagePin--selected":
                                  _vm.selectedDetail === item.code,
                                "ProductSchema__imagePin--vendorCode":
                                  _vm.productVendorCode ===
                                  item.product.vendorCode,
                              },
                              style: _vm.calculatePinPosition(
                                item.selectionCoordinates
                              ),
                              on: {
                                mouseenter: function ($event) {
                                  return _vm.handleMouseEnter(item.code)
                                },
                                mouseleave: _vm.handleMouseLeave,
                                click: function ($event) {
                                  return _vm.handleClickPin(item.code, item.oem)
                                },
                              },
                            })
                          : _vm._e()
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ProductSchema__resetButtonWrapper" }, [
            _c(
              "button",
              {
                staticClass: "Button Button--small Button--blackOutline",
                attrs: { type: "button" },
                on: { click: _vm.resetSchema },
              },
              [
                _vm._v(
                  "\n                    Исходный размер\n                "
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("section", { staticClass: "ProductSchema__itemsContainer" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "ul",
            {
              ref: "detailsContainer",
              staticClass: "ProductSchema__itemsWrapper",
            },
            _vm._l(_vm.details, function (item, index) {
              return _c(
                "li",
                {
                  key: index,
                  ref: `${item.code}_${item.oem}`,
                  refInFor: true,
                  staticClass: "ProductSchema__item",
                  class: {
                    "ProductSchema__item--hover":
                      _vm.hoveredDetail === item.code,
                    "ProductSchema__item--selected":
                      _vm.selectedDetail === item.code,
                    "ProductSchema__item--vendorCode":
                      _vm.productVendorCode === item.product.vendorCode,
                  },
                  on: {
                    mouseenter: function ($event) {
                      return _vm.handleMouseEnter(item.code)
                    },
                    mouseleave: _vm.handleMouseLeave,
                    click: function ($event) {
                      return _vm.handleClick(item.code)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "ProductSchema__itemsHeadInfo ProductSchema__itemsGrid",
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "ProductSchema__itemHeadInfo" },
                        [_vm._v(_vm._s(item.code))]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "ProductSchema__itemHeadInfo" },
                        [_vm._v(_vm._s(item.oem))]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "ProductSchema__itemHeadInfo" },
                        [_vm._v(_vm._s(item.name))]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.isUserWholesaler
                    ? _c(
                        "div",
                        { staticClass: "ProductSchema__itemBody" },
                        [
                          _c("product-schema-detail", {
                            attrs: {
                              id: item.code,
                              quantity: _vm.cartProductQuantity(
                                item.product.id
                              ),
                              "quantity-in-package":
                                item.product.quantityInPackage,
                              "price-retail-per-package":
                                item.product.priceRetailPerPackage,
                              "price-retail-per-unit":
                                item.product.wholesale.priceRetailPerUnit,
                              "price-wholesale-per-package":
                                item.product.wholesale.priceWholesalePerPackage,
                              "price-wholesale-per-unit":
                                item.product.wholesale.priceWholesalePerUnit,
                              "possible-to-order": item.product.possibleToOrder,
                              "product-id": item.product.id,
                              url: item.product.url,
                              name: item.product.name,
                              "vendor-code": item.product.vendorCode,
                              image: item.product.image,
                              stocks: item.product.stocks,
                              "image-size": 120,
                              "is-user-wholesaler": _vm.isUserWholesaler,
                              "price-display-type": _vm.priceDisplayType,
                              "is-loading": _vm.cartProductLoadingStatus(
                                item.product.id
                              ),
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "ProductSchema__itemBody" },
                        [
                          _c("product-schema-detail", {
                            attrs: {
                              id: item.code,
                              quantity: _vm.cartProductQuantity(
                                item.product.id
                              ),
                              "quantity-in-package":
                                item.product.quantityInPackage,
                              "price-retail-per-package":
                                item.product.priceRetailPerPackage,
                              "possible-to-order": item.product.possibleToOrder,
                              "product-id": item.product.id,
                              url: item.product.url,
                              name: item.product.name,
                              "vendor-code": item.product.vendorCode,
                              image: item.product.image,
                              stocks: item.product.stocks,
                              "image-size": 120,
                              "is-user-wholesaler": _vm.isUserWholesaler,
                              "price-display-type": _vm.priceDisplayType,
                              "is-loading": _vm.cartProductLoadingStatus(
                                item.product.id
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                ]
              )
            }),
            0
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "ProductSchema__itemsHeader ProductSchema__itemsGrid" },
      [
        _c("span", { staticClass: "ProductSchema__itemHeader" }, [_vm._v("№")]),
        _vm._v(" "),
        _c("span", { staticClass: "ProductSchema__itemHeader" }, [
          _vm._v("OEM"),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "ProductSchema__itemHeader" }, [
          _vm._v("Название"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }