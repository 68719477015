import Vue from 'vue/dist/vue';
import { mapGetters, mapActions } from 'vuex';
import store from '@scripts/store';
import { VUEX_GETTERS, VUEX_ACTIONS, MESSAGES } from '@scripts/constants';
import { toggleFavorites } from '@scripts/mixins/toggle-favorites';
import { apiGetApplicableDetails } from '@scripts/api-methods';
import { mapSchemaResponse } from '@scripts/mappings';
import { formSending } from '@library/forms/form-sending';
import { showErrorMessage } from '@library/uikit/notifications';
import AddToCart from '../add-to-cart/add-to-cart.vue';
import ProductStatus from '../product-status/product-status.vue';
import ProductSchema from '../product-schema/product-schema.vue';
import Uikit from '../../vendor/uikit/uikit';

export default (el, name, data) =>
    new Vue({
        el,
        name,
        store,
        components: {
            AddToCart,
            ProductStatus,
            ProductSchema,
        },
        data() {
            return ({
                schemasData: data.schemasData,
                isLoading: false,
                applicableDetails: null,
            })
        },
        mixins: [toggleFavorites(), formSending({ useRecaptcha: true })],
        beforeMount() {
            this.favoritesMap = data.favorites.favoritesMap;
        },
        computed: {
            ...mapGetters([VUEX_GETTERS.CART_PRODUCT_QUANTITY, VUEX_GETTERS.CART_PRODUCT_LOADING_STATUS, VUEX_GETTERS.IS_USER_WHOLESALER]),

            hasDataForFetchSchemas() {
                const { searchQuery, catalogCode, vehicleId, autoId } = this.schemasData;
                return Boolean(searchQuery && catalogCode && vehicleId);
            },
        },
        methods: {
            ...mapActions([VUEX_ACTIONS.CHANGE_CART_PRODUCT_QUANTITY]),

            // fetchApplicableDetails(detailId) {
            fetchApplicableDetails(replacementOem) {
                this.isLoading = true;
                const { searchQuery, catalogCode, vehicleId, autoId } = this.schemasData;
                return this.sendForm(
                    () =>
                        apiGetApplicableDetails({
                            catalog_code: catalogCode,
                            vehicle_id: vehicleId,
                            // detail_id: detailId,
                            vin_or_frame: searchQuery,
                            captcha_token: this.token,
                            auto_id: autoId,
                            replacement_oem: ''+replacementOem,
                        }),
                    (response) => {
                        this.applicableDetails = mapSchemaResponse(response.data);
                    },
                    () => {
                        showErrorMessage({
                            text: MESSAGES.ERROR_FIND_APPLICABLE_DETAILS,
                        });
                    })
                    .finally(() => { this.isLoading = false });
            },

            openAccordionSchemaByUnit(unitIndex) {
                Uikit.accordion(this.$refs.schemaWrapper).toggle(unitIndex, true);
            },
        },
    });
