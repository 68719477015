import Vue from 'vue/dist/vue';
import { mask } from 'vue-the-mask';
import { INPUT_MASKS } from '@library/scripts/constants';
import { formSending } from '@library/forms/form-sending';
import { formValidation } from '@library/forms/validation';
import { form } from '@scripts/mixins/form';
import { VALIDATION_MESSAGES, VALIDATION_RULES, MODALS } from '@scripts/constants';
import { apiFeedback } from '@scripts/api-methods';
import UIkit from '../../../vendor/uikit/uikit';

const formDataScheme = () => ({
    name: '',
    phone: '',
    email: '',
    text: '',
});

export default (el, name) =>
    new Vue({
        el,
        name,
        data: () => ({
            formData: formDataScheme(),
            masks: {
                phone: INPUT_MASKS.PHONE_MASK,
            },
        }),
        directives: { mask },
        mixins: [
            form(),
            formSending({ useRecaptcha: true }),
            formValidation(
                {
                    name: {
                        field: 'formData.name',
                        rules: {
                            required: true,
                        },
                    },
                    phone: {
                        field: 'formData.phone',
                        rules: {
                            required: true,
                            ...VALIDATION_RULES.PHONE,
                        },
                    },
                    email: {
                        field: 'formData.email',
                        rules: {
                            ...VALIDATION_RULES.EMAIL,
                        },
                    },
                    text: {
                        field: 'formData.text',
                        rules: {
                            required: true,
                            minLength: 3,
                            maxLength: 2000,
                        },
                    },
                },
                VALIDATION_MESSAGES
            ),
        ],
        methods: {
            clearForm() {
                this.formData = formDataScheme();
            },

            sendRequest() {
                return this.sendForm(
                    () =>
                        apiFeedback({
                            name: this.formData.name,
                            phone: this.formData.phone,
                            email: this.formData.email || null,
                            text: this.formData.text,
                            captcha_token: this.token,
                        }),
                    () => {
                        this.successFormSending();
                        UIkit.modal(MODALS.FEEDBACK).hide();
                    }
                );
            },
        },
    });
