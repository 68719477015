var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vue-select", {
    class: {
      "vs--invalid": _vm.isInvalid,
      "vs--has-options": _vm.hasNextPage,
    },
    attrs: {
      options: _vm.options,
      label: _vm.labelProperty,
      filterable: !_vm.useInfiniteScroll,
      filter: _vm.filter,
      clearable: false,
      reduce: _vm.reduce,
      selectable: _vm.selectable,
      multiple: _vm.multiple,
      placeholder: _vm.placeholder,
      "input-id": _vm.inputId,
      value: _vm.value,
      disabled: _vm.disabled,
    },
    on: {
      search: (query) => _vm.onSearchDebounce(query),
      "search:blur": function ($event) {
        return _vm.$emit("search-blur", $event)
      },
      open: _vm.onOpen,
      close: _vm.onClose,
      input: function ($event) {
        return _vm.$emit("input", $event)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "open-indicator",
        fn: function ({ attributes }) {
          return [_c("span", _vm._b({}, "span", attributes, false))]
        },
      },
      {
        key: "list-footer",
        fn: function () {
          return [
            _c(
              "li",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.hasNextPage || _vm.loading,
                    expression: "hasNextPage || loading",
                  },
                ],
                ref: "load",
                staticClass: "vs__loader",
              },
              [
                _c("div", { staticClass: "Loader Loader--dropdown" }, [
                  _c(
                    "svg",
                    {
                      staticClass: "Loader__spinner",
                      attrs: {
                        viewBox: "0 0 100 100",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("circle", {
                        staticClass: "Loader__circle",
                        attrs: { cx: "50", cy: "50", r: "45" },
                      }),
                    ]
                  ),
                ]),
              ]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "no-options",
        fn: function ({ search, searching, loading }) {
          return [_c("span", [_vm._v("Результатов не найдено")])]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }