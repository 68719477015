import {mapState} from "vuex";
import {reduceStocks} from "../helpers";

export const deliveryTimeMixin = () => ({
    computed: {
        ...mapState(['cart']),

        deliveryTime() {
            let maxTime = 0;

            this.cart.items.forEach((item) => {
                const mappedStocks = reduceStocks(item.product.stocks);

                const currentStock = mappedStocks.find((stock) => item.quantityPackages <= stock.amount_in_packages) ?? mappedStocks[mappedStocks.length - 1];

                if (currentStock.delivery_time_in_days > maxTime) {
                    maxTime = currentStock.delivery_time_in_days;
                }
            });

            return maxTime;
        },
    },
    methods: {
        formattedDeliveryTime(deliveryTime) {
            return `~&nbsp;${deliveryTime}&nbsp;${this.pluralize(deliveryTime, ['день', 'дня', 'дней'])}`;
        },
    },
});
