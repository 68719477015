import Vue from 'vue/dist/vue';
import Vuex from 'vuex';

import { get } from 'lodash-es';

import { apiAddProduct, apiSetQuantity, apiCartClear, apiCartDetails, apiAddMultipleProducts, apiRepeatOrder } from '@scripts/api-methods';
import { VUEX_ACTIONS, VUEX_MUTATIONS, MESSAGES, USER_TYPES } from '@scripts/constants';
import { mapCartDetails } from '@scripts/mappings';

import { FeipApiServerError } from '@library/scripts/api/error';
import { showErrorMessage } from '@library/uikit/notifications';
import { carrotquestTrack } from '@scripts/counters';

Vue.use(Vuex);

const Store = new Vuex.Store({
    state: {
        user: {
            phone: '',
            name: '',
            email: '',
            isLoggedIn: false,
            type: USER_TYPES.PHYSIC,
            settings: {
                showRetailPriceInCartAndCheckout: true,
                showWeightInCart: true,
                priceDisplayType: null,
            },
        },
        cart: {
            cartQuantityInfoMap: {},

            items: [],
            totalPriceRetail: 0,
            totalPriceWholesale: 0,
            totalWeight: 0,
            shippingPrice: 0,
            availableItemsTotalPrice: 0,
            itemsForOrderTotalPrice: 0,
            itemsForManufacturingTotalPrice: 0,
            discountAmount: 0,
        },
        selectedCity: null,
        isCitySelected: false,
        citiesWithWarehouses: [],
        catalogFilters: {
            brand: '',
            model: '',
        },
    },
    mutations: {
        setUser(
            state,
            data = {
                phone: '',
                name: '',
                email: '',
                type: USER_TYPES.PHYSIC,
                isLoggedIn: false,
                settings: {
                    showRetailPriceInCartAndCheckout: true,
                    showWeightInCart: true,
                    priceDisplayType: null,
                },
            }
        ) {
            state.user = {
                ...state.user,
                ...data,
            };
        },

        setUserPhone(state, phone = '') {
            state.user.phone = phone;
        },

        setUserName(state, name = '') {
            state.user.name = name;
        },

        setCart(
            state,
            data = {
                items: [],
                totalPriceRetail: 0,
                totalPriceWholesale: 0,
                totalWeight: 0,
                shippingPrice: 0,
                availableItemsTotalPrice: 0,
                itemsForOrderTotalPrice: 0,
                itemsForManufacturingTotalPrice: 0,
                discountAmount: 0,
            }
        ) {
            state.cart = {
                ...state.cart,
                ...data,
            };
        },

        setCartProductFakeQuantity(state, { productId, quantity = 1 }) {
            if (state.cart.cartQuantityInfoMap[productId] !== undefined) {
                Vue.set(state.cart.cartQuantityInfoMap[productId], 'isWaiting', true);
                Vue.set(state.cart.cartQuantityInfoMap[productId], 'fakeQuantity', quantity);
            } else {
                Vue.set(state.cart.cartQuantityInfoMap, productId, {
                    isLoading: false,
                    isWaiting: true,
                    fakeQuantity: quantity,
                });
            }
        },

        setCartProductLoadingStatus(state, { productId, isLoading }) {
            Vue.set(state.cart.cartQuantityInfoMap[productId], 'isLoading', isLoading);
        },

        setCartProductWaitingStatus(state, { productId, isWaiting }) {
            Vue.set(state.cart.cartQuantityInfoMap[productId], 'isWaiting', isWaiting);
        },

        setSelectedCity(state, payload = null) {
            state.selectedCity = payload;
        },

        setCitiesWithWarehouses(state, payload) {
            state.citiesWithWarehouses = payload;
        },

        setIsCitySelected(state, payload) {
            state.isCitySelected = payload;
        },

        setCatalogFiltersBrand(state, payload) {
            state.catalogFilters.brand = payload;
        },

        setCatalogFiltersModel(state, payload) {
            state.catalogFilters.model = payload;
        },
    },
    actions: {
        modifyCart({ commit }, { method, payload }) {
            return method(payload)
                .then((result) => mapCartDetails(result))
                .then((result) => commit(VUEX_MUTATIONS.SET_CART, result))
                .catch((e) => {
                    let text = MESSAGES.ERROR_CART_MODIFYING;

                    if (e instanceof FeipApiServerError && e.isCommon()) {
                        text = e.common.getAllErrorMessages();
                    }

                    showErrorMessage({ text });
                });
        },

        addToCart({ dispatch }, { productId, quantity = 1 }) {
            if (this.getters.cartTotalItemsQuantity === 0){
                carrotquestTrack('Cоздана корзина');
            }
            return dispatch(VUEX_ACTIONS.MODIFY_CART, {
                method: apiAddProduct,
                payload: {
                    product_id: productId,
                    quantity,
                },
            });
        },

        addToCartMultiply({ dispatch }, items = []) {
            return dispatch(VUEX_ACTIONS.MODIFY_CART, {
                method: apiAddMultipleProducts,
                payload: { items },
            });
        },

        setCartItemQuantity({ dispatch }, { cartItemId, quantity }) {
            return dispatch(VUEX_ACTIONS.MODIFY_CART, {
                method: apiSetQuantity,
                payload: {
                    item_id: cartItemId,
                    quantity,
                },
            });
        },

        changeCartProductQuantity({ getters, dispatch, commit }, { productId, quantity }) {
            // проставляем требуемое количество айтема
            commit(VUEX_MUTATIONS.SET_CART_PRODUCT_FAKE_QUANTITY, {
                productId,
                quantity,
            });

            // если уже выполняется какой то апи запрос - ничего не делаем
            if (getters.cartProductLoadingStatus(productId) === true) {
                return;
            }

            commit(VUEX_MUTATIONS.SET_CART_PRODUCT_LOADING_STATUS, {
                productId,
                isLoading: true,
            });

            dispatch(VUEX_ACTIONS.SYNC_CART_PRODUCT_QUANTITY, {
                productId,
            });
        },

        syncCartProductQuantity({ getters, commit, dispatch }, { productId }) {
            const cartItemId = getters.cartProductItemId(productId);
            const fakeQuantity = getters.cartProductFakeQuantity(productId);

            const modification = {
                method: '',
                payload: {
                    quantity: fakeQuantity,
                },
            };

            // обнуляем признак для остановки очереди методов
            commit(VUEX_MUTATIONS.SET_CART_PRODUCT_WAITING_STATUS, {
                productId,
                isWaiting: false,
            });

            // если продукт уже есть в корзине - делаем set-quantity
            if (cartItemId) {
                modification.method = VUEX_ACTIONS.SET_CART_ITEM_QUANTITY;
                modification.payload.cartItemId = cartItemId;
                // если его нету то делаем add-to-cart + страхуемся от quantity = 0
            } else if (fakeQuantity > 0) {
                modification.method = VUEX_ACTIONS.ADD_TO_CART;
                modification.payload.productId = productId;
            }

            // если пытаемся задать 0 несуществующему айтему (ошибка)
            if (modification.method === '') {
                commit(VUEX_MUTATIONS.SET_CART_PRODUCT_LOADING_STATUS, {
                    productId,
                    isLoading: false,
                });
                return;
            }

            // запускаем очередь методов на изменение корзины
            dispatch(modification.method, modification.payload).then(() => {
                // если за время выполнения поступил новый запрос - выполняем его
                if (getters.cartProductWaitingStatus(productId)) {
                    dispatch(VUEX_ACTIONS.SYNC_CART_PRODUCT_QUANTITY, {
                        productId,
                    });
                } else {
                    commit(VUEX_MUTATIONS.SET_CART_PRODUCT_LOADING_STATUS, {
                        productId,
                        isLoading: false,
                    });
                }
            });
        },

        removeCartItem({ dispatch }, { productId }) {
            dispatch(VUEX_ACTIONS.CHANGE_CART_PRODUCT_QUANTITY, {
                productId,
                quantity: 0,
            });
        },

        clearCart({ dispatch }) {
            return dispatch(VUEX_ACTIONS.MODIFY_CART, {
                method: apiCartClear,
                payload: {},
            });
        },

        refreshCart({ dispatch }) {
            return dispatch(VUEX_ACTIONS.MODIFY_CART, {
                method: apiCartDetails,
                payload: {},
            });
        },

        repeatOrder({ dispatch }, orderNumber) {
            return dispatch(VUEX_ACTIONS.MODIFY_CART, {
                method: apiRepeatOrder,
                payload: {
                    order_number: orderNumber,
                },
            });
        },
    },
    getters: {
        cartQuantityMap: (state) =>
            state.cart.items.reduce((map, item) => {
                map[item.product.id] = {
                    cartItemId: item.id,
                    quantityUnits: item.quantityUnits,
                    quantityPackages: item.quantityPackages,
                };
                return map;
            }, {}),

        cartProductQuantity: (state, getters) => (productId) => get(getters.cartQuantityMap[productId], getters.isUserWholesaler ? 'quantityUnits' : 'quantityPackages', 0),

        cartTotalItemsQuantity: (state, getters) => state.cart.items.reduce((result, item) => result + (getters.isUserWholesaler ? item.quantityUnits : item.quantityPackages), 0),

        cartProductItemId: (state, getters) => (
            productId // state ???
        ) => get(getters.cartQuantityMap[productId], 'cartItemId', undefined),

        cartProductFakeQuantity: (state) => (productId) => get(state.cart.cartQuantityInfoMap[productId], 'fakeQuantity', 0),

        cartProductLoadingStatus: (state) => (productId) => get(state.cart.cartQuantityInfoMap[productId], 'isLoading', false),

        cartProductWaitingStatus: (state) => (productId) => get(state.cart.cartQuantityInfoMap[productId], 'isWaiting', false),

        isUserWholesaler: (state) => state.user.type === USER_TYPES.WHOLESALER,

        isUserLoggedIn: (state) => state.user.isLoggedIn,

        isCatalogFiltersSelected: (state) => !!state.catalogFilters.brand && !!state.catalogFilters.model,

        selectedCar: (state) => `${state.catalogFilters.brand} ${state.catalogFilters.model}`,
    },
});

export default Store;
