export class DebounceLoading {
	constructor(timeout = 400) {
		this.realLoading = false;
		this.debounceLoading = false;
		this.debounceTimer = false;
		this.timeout = timeout;
	}

	enableLoading() {
		this.realLoading = true;
		this.debounceTimer = setTimeout(() => {
			this.debounceLoading = true;
		}, this.timeout);
	}

	disableLoading() {
		this.realLoading = false;
		this.debounceLoading = false;
		clearTimeout(this.debounceTimer);
	}
}
