import Vue from 'vue/dist/vue';
import { apiResetPasswordRequest } from '@scripts/api-methods';
import { form } from '@scripts/mixins/form';
import { formSending } from '@library/forms/form-sending';
import { formValidation } from '@library/forms/validation';
import { mapRequestResetPasswordResponse } from '@scripts/mappings';
import { MODALS, VALIDATION_MESSAGES, VALIDATION_RULES } from '@scripts/constants';
import UIkit from '../../../vendor/uikit/uikit';

const formDataScheme = () => ({
    email: '',
});

export default (el, name) =>
    new Vue({
        el,
        name,
        data: () => ({
            formData: formDataScheme(),
            isSuccessfulSubmit: false,
            submitResetPasswordDelay: 0,
            timeFormatResetPasswordDelay: '',
            submitResetPasswordDelayTimer: undefined,
        }),
        mixins: [
            form(),
            formSending({ useRecaptcha: true }),
            formValidation(
                {
                    email: {
                        field: 'formData.email',
                        rules: {
                            required: true,
                            ...VALIDATION_RULES.EMAIL,
                        },
                    },
                },
                VALIDATION_MESSAGES
            ),
        ],
        methods: {
            clearForm() {
                clearInterval(this.submitResetPasswordDelayTimer);
                this.submitResetPasswordDelay = 0;
                this.timeFormatResetPasswordDelay = '';
                this.isSuccessfulSubmit = false;
            },

            sendRequest() {
                const apiMethodCall = () =>
                    apiResetPasswordRequest({
                        email: this.formData.email,
                        captcha_token: this.token,
                    });

                return this.sendForm(
                    () =>
                        apiMethodCall().then(mapRequestResetPasswordResponse),
                    (result) => {
                        this.startSubmitResetPasswordDelayTimer(result.resendDelay);
                        this.isSuccessfulSubmit = true;
                    }
                );
            },

            startSubmitResetPasswordDelayTimer(delay) {
                this.submitResetPasswordDelay = delay;
                this.formatResetPasswordDelay(delay);
                this.submitResetPasswordDelayTimer = setInterval(() => {
                    this.submitResetPasswordDelay -= 1;
                    this.formatResetPasswordDelay(this.submitResetPasswordDelay);
                    if (this.submitResetPasswordDelay === 0) {
                        clearInterval(this.submitResetPasswordDelayTimer);
                    }
                }, 1000);
            },

            formatResetPasswordDelay(delay) {
                const min = Math.floor(delay / 60);
                const sec = delay - (60 * min);

                const prevZero = (value) => value < 10 ? `0${value}` : value;

                this.timeFormatResetPasswordDelay = `${prevZero(min)}:${prevZero(sec)}`;
            },

            openLogin() {
                UIkit.modal(MODALS.RESET_PASSWORD_REQUEST).hide();
                this.clearForm();
                UIkit.modal(MODALS.LOGIN).show();
            },
        },
    });
