<template>
    <div :class="['AddToCart', modeClass]">
        <transition name="fade">
            <button
                type="button"
                class="Button Button--yellow AddToCart__button"
                title="Добавить в корзину"
                @click="
                    alwaysShowAddToCart
                        ? addToCartWithoutDebounce()
                        : debounceChangeQuantity({
                              quantity: isUserWholesaler
                                  ? quantityInPackage
                                  : 1,
                          })
                "
                v-if="!alwaysShowCounter"
                v-show="!showCounter"
                :disabled="disabled"
            >
                <span class="Button__text"> В корзину </span>
            </button>
        </transition>
        <transition name="fade">
            <div
                class="AddToCart__counter"
                v-show="showCounter"
                v-if="!alwaysShowAddToCart"
            >
                <counter
                    :value="fakeQuantity"
                    :quantity-in-package="quantityInPackage"
                    :is-user-wholesaler="isUserWholesaler"
                    :mask="isUserWholesaler ? '###' : '##'"
                    :max-item-quantity="isUserWholesaler ? 999 : 99"
                    :mode="mode"
                    @change="debounceChangeQuantity($event)"
                ></counter>
            </div>
        </transition>
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import Counter from '../counter/counter.vue';

export default {
    props: {
        mode: {
            type: String,
        },
        productId: {
            type: Number,
            required: true,
        },
        quantity: {
            type: Number,
            default: 0,
        },
        quantityInPackage: {
            type: Number,
            required: true,
        },
        isUserWholesaler: {
            type: Boolean,
            required: true,
        },
        isLoading: {
            type: Boolean,
        },
        alwaysShowCounter: {
            type: Boolean,
            default: false,
        },
        alwaysShowAddToCart: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
        },
    },
    data() {
        return {
            debounceTimeout: null,
            fakeQuantity: this.quantity,
        };
    },
    mounted() {
        this.$nextTick(() => { this.fakeQuantity = this.quantity; });
    },
    components: {
        InlineSvg,
        Counter,
    },
    computed: {
        modeClass() {
            return this.mode ? `AddToCart--${this.mode}` : '';
        },

        showCounter() {
            return this.alwaysShowCounter || this.fakeQuantity > 0;
        },
    },
    methods: {
        addToCartWithoutDebounce() {
            this.$emit('add-to-cart', { productId: this.productId });
        },

        debounceChangeQuantity(event) {
            const newQuantity = event.quantity;
            this.fakeQuantity = newQuantity;

            clearTimeout(this.debounceTimeout);
            this.debounceTimeout = null;

            this.debounceTimeout = setTimeout(() => {
                this.changeQuantity(newQuantity);
                this.debounceTimeout = null;
            }, 400);
        },

        changeQuantity(quantity) {
            this.$emit('change-quantity', {
                productId: this.productId,
                quantity,
            });
        },
    },
    watch: {
        isLoading(isLoading) {
            if (isLoading === false && this.debounceTimeout === null) {
                this.fakeQuantity = this.quantity;
            }
        },
    },
};
</script>
