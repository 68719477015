'use strict'

import 'whatwg-fetch/fetch'
import getter from 'lodash-es/get'
import { SafeBase64 } from '../base64'
import {
	ApiClientInvalidResponseError,
	ApiClientNetworkError,
	FeipApiServerError,
	FeipApiFatalError,
} from './error'
import { Is } from '../is'
import { anyToHexHash } from '../hash'
import { objToFormData } from '../forms'
import { CookieAuth, Authorization } from './auth'

// Fix 'whatwg-fetch', see - https://github.com/zloirock/core-js/issues/178
const _fetch = fetch
window.fetch = (...args) => Promise.resolve(_fetch(...args))

export class FloatingDebounceClass {
	constructor(step = 60) {
		this.timeoutCache = {}
		this.step = step
	}

	fire(callee, ms, resolver, key = null) {
		const hash = Is.null(key) ? anyToHexHash(callee, ms) : anyToHexHash(key, ms)
		if (this.timeoutCache[hash]) clearTimeout(this.timeoutCache[hash].timeout)

		const count = getter(this.timeoutCache, `${hash}.count`, 1)
		const deltas = getter(this.timeoutCache, `${hash}.deltas`, [])

		const now = +new Date()
		const last = getter(this.timeoutCache, `${hash}.last`, now)
		const delta = now - last
		const delay = this.calculate(deltas, count, ms)

		const meta = {
			last: now,
			count: count + 1,
			deltas: delta === 0 ? deltas : [...deltas, delta],
			timeout: setTimeout(() => {
				if (callee instanceof Function) {
					if (resolver instanceof Function) resolver(callee())
					else callee()
				}
				delete this.timeoutCache[hash]
			}, delay)
		}

		delete this.timeoutCache[hash]
		this.timeoutCache[hash] = meta
	}

	calculate(deltas, count, origin) {
		// TODO: подумать что использовать, функцию времени, либо матрицу или иное
		return origin + deltas.reduce((a, value) => a + (value <= (origin / 2) ? this.step / 2 : this.step), 0)
	}
}

/**
 * @example (for Bearer):
 const Auth = new BearerAuth()
 Auth.setTokenGetter(() => localStorage.getItem('token'))
 Auth.setTokenHandler(token => { localStorage.setItem('token', token) })
 Auth.setInvalidTokenHandler(() => console.log('invalid'))
 const ApiClient = new ApiClientClass(Auth)
 */
export class ApiClientClass {
	constructor(auth) {
		/** Auth initializing */
		if (!(auth instanceof Authorization)) throw new SyntaxError('Invalid auth configuration')
		this.auth = auth

		/** Runtime helpers */
		this._debounceClass = new FloatingDebounceClass()
		this._defaultHeaders = {
			Accept: 'application/json',
			Platform: 'Web',
		}
	}

	get defaultHeaders() {
		return this._defaultHeaders
	}

	set defaultHeaders(value) {
		this._defaultHeaders = value
	}

	_request(method, url, body, headers = {}) {
		return fetch(url, {
			body,
			method,
			credentials: 'same-origin',
			headers: { ...this.defaultHeaders, ...headers, ...this.auth.getAuthHeaders() },
		}).then(this._processResponse.bind(this)).catch(this._processFailedResponse.bind(this))
	}

	/** @param {Response} response */
	_processResponse(response) {
		const contentType = response.headers && response.headers.get('content-type')
		if (!contentType || contentType.indexOf('application/json') < 0) {
			return Promise.reject(new ApiClientInvalidResponseError())
		}
		return response.json().then(json => {
			if (!response.ok) {
				let ServerError = json
				if (response.status === 500) {
					ServerError = new FeipApiFatalError(json)
				} else if (response.status === 422) {
					ServerError = new FeipApiServerError(json)
					if (ServerError.isCommon() && ServerError.common.hasErrorCode('invalid_token')) {
						this.auth.invalidTokenHandler()
					}
				}
				return Promise.reject(ServerError)
			}
			if (json.hasOwnProperty('token')) this.auth.receiveNewToken(json.token)
			return json
		})
	}

	/** @param {Error|*} err */
	_processFailedResponse(err) {
		// Handle network error
		if (Is.instanceOf(err, TypeError) && err.message === 'Failed to fetch') {
			return Promise.reject(new ApiClientNetworkError())
		} else {
			return Promise.reject(err)
		}
	}

	get(url, params) {
		if (params instanceof Object) url += '?_body=' + SafeBase64.encode(JSON.stringify(params))
		return this._request('GET', url, null)
	}

	post(url, payload) {
		return this._request('POST', url, JSON.stringify(payload), { 'Content-Type': 'application/json' })
	}

	debouncedGet(ms, url, params, key = null) {
		return new Promise(resolve => this._debounceClass.fire(() => this.get(url, params), ms, resolve, key))
	}

	debouncedPost(ms, url, payload, key = null) {
		return new Promise(resolve => this._debounceClass.fire(() => this.post(url, payload), ms, resolve, key))
	}

	multiPartRequest(url, data, files) {
		const body = objToFormData(files)
		body.append('_body', SafeBase64.encode(JSON.stringify(data)))
		return fetch(url, { method: 'POST', body }).then(this._processResponse)
	}
}

const DefaultApiClient = new ApiClientClass(new CookieAuth())

export default DefaultApiClient
