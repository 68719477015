'use strict'

import { ArgumentError } from './errors'
import { isArray, isInstanceOf, isString } from './is'

/**
 * @param {Function|Promise} f
 * @return {Promise<any>}
 */
export function promisify(f) {
	return !isInstanceOf(f, Promise) ? new Promise(resolve => resolve(f())) : f
}

export class PromiseStack {
	constructor(stack = []) {
		if (!isArray(stack)) throw new ArgumentError('Argument "stack" must be Array instance')
		this.stack = stack
	}

	push(f) {
		this.stack.push(f)
	}

	run() {
		const stack = this.stack.map(promisify)
		this.stack = []
		return Promise.all(stack)
	}
}

/**
 * @param {string} url
 * @param {string} element
 * @param {string} urlProp
 * @param {boolean} appendToBody
 * @return {Promise<HTMLElement>}
 * @throws ArgumentError
 */
export function resourceLoader(url, element, urlProp, appendToBody = false) {
	if (!isString(url)) throw new ArgumentError('Argument "src" must be String instance')
	return new Promise((resolve, reject) => {
		const resource = document.createElement(element)
		resource.async = true
		resource.onload = () => { resolve(resource) }
		resource[urlProp] = url
		setTimeout(reject, 20000)
		if (appendToBody) document.body.appendChild(resource)
	})
}

/**
 * @param {string} url
 * @return {Promise<HTMLImageElement>}
 */
export function imageLoader(url) { return resourceLoader(url, 'img', 'src') }

/**
 * @param {string} url
 * @return {Promise<HTMLScriptElement>}
 */
export function scriptLoader(url) { return resourceLoader(url, 'script', 'src', true) }
