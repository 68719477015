'use strict'

import { decode, encode } from 'url-safe-base64'

export class SafeBase64 {
	static encode(str) {
		return encode(btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode('0x' + p1))))
	}

	static decode(str) {
		return decodeURIComponent(atob(decode(str)).split('').map(c => ('%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))).join(''))
	}
}
