import { lock, unlock } from 'tua-body-scroll-lock'

function toggle(element, callback) {
	/*
	Add this attribute to element you want to enable to scroll inside `element`
	Don't forget to add `overflow: auto` to element styles

	<div class='overflow-here' is-scrollable></div>
	<style>.overflow-here { overflow: auto }</style>

	*/
	const attribute = 'is-scrollable'
	if (element) {
		if (element.attributes[`[${attribute}]`] !== null) callback(element)
		element.querySelectorAll(`[${attribute}]`).forEach(scrollableElement => {
			callback(scrollableElement)
		})
	} else {
		callback()
	}
}

function enableBodyScroll(element = null) {
	toggle(element, unlock)
}

function disableBodyScroll(element = null) {
	toggle(element, lock)
}

export {
	enableBodyScroll,
	disableBodyScroll,
}
