import Vue from 'vue/dist/vue';

/** @typedef {function(eventName: string, handler: Function):void} EventListener */
/** @typedef {function(eventName: string, payload):void} PayloadEventEmitter */
/** @typedef {function(eventName: string):void} EmptyEventEmitter */

/**
 * @property {EventListener} $on
 * @property {EventListener} $off
 * @property {EventListener} $once
 * @property {PayloadEventEmitter|EmptyEventEmitter} $emit
 */
const EventBus = new Vue();
export default EventBus;
