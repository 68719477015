import get from 'lodash-es/get';
import { Is } from '../scripts/is';
import {
    ApiClientInvalidResponseError,
    ApiClientNetworkError,
    FeipApiServerError,
    FeipApiFatalError,
} from '../scripts/api/error';

const isNullish = (val) => ( val === '' || val === undefined || val === null );

export const DEFAULT_VALIDATION_MESSAGES = {
    TOO_SHORT_VALUE: 'Слишком короткое значение',
    TOO_LONG_VALUE: 'Слишком длинное значение',
    TOO_SMALL_VALUE: 'Слишком маленькое значение',
    TOO_BIG_VALUE: 'Слишком большое значение',
    WRONG_VALUE_FORMAT: 'Некорректный формат',
    REQUIRED_ERROR: 'Поле обязательно к заполнению',
    DEFAULT_ERROR: 'Ошибка',
};

const errorsDictionary = {
    minLength: 'TOO_SHORT_VALUE',
    maxLength: 'TOO_LONG_VALUE',
    min: 'TOO_SMALL_VALUE',
    max: 'TOO_BIG_VALUE',
    regexp: 'WRONG_VALUE_FORMAT',
    required: 'REQUIRED_ERROR',
}

export const SERVER_ERROR_TYPES = {
    CLIENT_INVALID_RESPONSE: 1,
    CLIENT_NETWORK: 2,
    API_SERVER: 3,
    API_FATAL: 4,
    UNRESOLVED: 5,
};

export function ValidationError(message) {
    this.message = message;
}

const validationRulesToFieldNames = (validationRules) => (
    Object.values(validationRules).map(rule => rule.field)
);

export const formValidation = (validationRules, validationMessages = DEFAULT_VALIDATION_MESSAGES) => ({
    data: () => ({
        invalidFields: {},
        unwatchers: {},
        validationRules,
        validationMessages,
        alreadyFocused: false,
        fieldsToValidate: validationRulesToFieldNames(validationRules),
    }),
    methods: {
        validateFields({
            scrollToFirstInvalidField = true,
            focusFirstInvalidField = true,
        } = {}) {
            let result = true;

            this.invalidFields = {};
            this.killAllInvalidFieldWatchers();
            // you can use this variable in your custom validation
            this.alreadyFocused = false;

            Object.values(this.validationRules).forEach((rule) => {
                const fieldName = rule.field;
                const fieldRules = rule.rules;
                const fieldErrors = rule.errors;
                const needToValidateField = this.fieldsToValidate.includes(fieldName);

                if (fieldName && fieldRules && needToValidateField) {
                    const testResult = this.isFieldValid(get(this, fieldName), fieldRules, fieldErrors);
                    if (Is.instanceOf(testResult, ValidationError)) {
                        result = false;

                        this.setError({
                            fieldName,
                            errorMessage: testResult.message,
                            watcherPath: fieldName,
                            scrollToInvalidField: scrollToFirstInvalidField && !this.alreadyFocused,
                            focusInvalidField: focusFirstInvalidField && !this.alreadyFocused,
                        });
                    }
                }
            });

            return result;
        },

        isFieldValid(val, rules, errors = {}) {
            // custom test
            if (Is.function(rules.validator)) {
                const result = rules.validator.call(this, val);
                if (Is.instanceOf(result, ValidationError)) {
                    return result;
                }
            }

            // test required
            if ((Is.array(val) && val.length === 0) || isNullish(val)) {
                if (rules.required === true) {
                    // throw required error
                    return new ValidationError(this.getErrorText(errors, 'required'));
                }

                // field is empty and we will not check it's value
                return true;
            }

            // test min length
            if (Is.int(rules.minLength) && Is.string(val) && val.length < rules.minLength) {
                return new ValidationError(this.getErrorText(errors, 'minLength'));
            }

            // test max length
            if (Is.int(rules.maxLength) && Is.string(val) && val.length > rules.maxLength) {
                return new ValidationError(this.getErrorText(errors, 'maxLength'));
            }

            // test min
            if (rules.min !== undefined && Number(val) < rules.min) {
                return new ValidationError(this.getErrorText(errors, 'min'));
            }

            // test max
            if (rules.max !== undefined && Number(val) > rules.max) {
                return new ValidationError(this.getErrorText(errors, 'max'));
            }

            // test regexp
            if (Is.instanceOf(rules.regexp, RegExp) && !rules.regexp.test(val)) {
                return new ValidationError(this.getErrorText(errors, 'regexp'));
            }

            return true;
        },

        getErrorText(errors, errorType) {
            const errorFullType = errorsDictionary[errorType];
            return get(
                errors,
                errorType,
                get(
                    this.validationMessages,
                    errorFullType,
                    get(
                        DEFAULT_VALIDATION_MESSAGES,
                        errorFullType,
                        DEFAULT_VALIDATION_MESSAGES.DEFAULT_ERROR
                    )
                )
            );
        },

        hasErrorCode(errorPath) {
            return get(this.invalidFields, errorPath, undefined) !== undefined;
        },

        setErrorMessage(errorPath, value) {
            this.$set(this.invalidFields, errorPath, value);
        },

        getErrorMessage(errorPath) {
            return this.hasErrorCode(errorPath) ? get(this.invalidFields, errorPath, null) : null;
        },

        clearErrorMessage(errorPath) {
            this.setErrorMessage(errorPath, undefined);
        },

        // notice: dont use 'array[index]' - use 'array.index' instead
        setError({
            fieldName,
            errorMessage,
            errorPath = fieldName,
            watcherPath = fieldName,
            scrollToInvalidField = false,
            focusInvalidField = false,
        }) {
            this.setErrorMessage(errorPath, errorMessage);
            this.setInvalidFieldWatcher(
                watcherPath,
                errorPath
            );
            if (scrollToInvalidField || focusInvalidField) {
                this.focusInvalidField(fieldName, scrollToInvalidField, focusInvalidField);
            }
        },

        focusInvalidField(fieldName, scroll = true, focus = true) {
            if (!scroll && !focus) {
                return;
            }
            let element = this.$refs[fieldName];
            if (!element) {
                return;
            }
            if (Is.array(element)) {
                element = element[0];
            }
            if (element instanceof Element) {
                if (focus) {
                    if (Is.function(element.focus)) element.focus();
                    if (Is.function(element.select)) element.select();
                }
                if (scroll && Is.function(element.scrollIntoView)) {
                    this.$nextTick(() => {
                        element.scrollIntoView({ block: 'center', behavior: 'smooth' });
                    });
                }
            } else {
                element = element.$el;
                if (element && scroll && Is.function(element.scrollIntoView)) {
                    this.$nextTick(() => {
                        element.scrollIntoView({ block: 'center', behavior: 'smooth' });
                    });
                }
            }
            this.alreadyFocused = true;
        },

        setInvalidFieldWatcher(watcherPath, errorPath = watcherPath) {
            // kill already existing watchers
            this.killInvalidFieldWatcher(watcherPath);

            // then create new
            const unwatch = this.$watch(
                watcherPath,
                () => {
                    this.clearErrorMessage(errorPath);
                    unwatch();
                },
            );
            this.unwatchers[watcherPath] = unwatch;
        },

        killInvalidFieldWatcher(watcherPath) {
            // kill already existing watcher
            if (Is.function(this.unwatchers[watcherPath])) {
                this.unwatchers[watcherPath]();
                delete this.unwatchers[watcherPath];
            }
        },

        killAllInvalidFieldWatchers() {
            Object.keys(this.unwatchers).forEach((watcherPath) => {
                this.killInvalidFieldWatcher(watcherPath);
            });
            this.unwatchers = {};
        },

        resetFieldsToValidationToDefault() {
            this.fieldsToValidate = validationRulesToFieldNames(this.validationRules);
        },

        addFieldsToValidation(fields) {
            if (!Is.array(fields)) {
                fields = [fields];
            }
            fields = fields.filter(el => !this.fieldsToValidate.includes(el));
            this.fieldsToValidate.push(...fields);
        },

        removeFieldsFromValidation(fields) {
            if (!Is.array(fields)) {
                fields = [fields];
            }
            this.fieldsToValidate = this.fieldsToValidate.filter(el => !fields.includes(el));
        },

        handleServerErrors({
            error,
            onError = null,
            onCommonError = null,
            onInvalidField = null,
            scrollToFirstInvalidField = true,
            focusFirstInvalidField = true,
        }) {
            let errorType;

            if (Is.instanceOf(error, FeipApiServerError)) {
                if (scrollToFirstInvalidField) {
                    this.alreadyFocused = false;
                }

                error.invalidFields.forEach((invalidField) => {
                    const fieldName = get(this.validationRules, [invalidField.key, 'field'], null);
                    const message = invalidField.getErrorMessage();
                    if (fieldName != null) {
                        this.setError({
                            fieldName,
                            errorMessage: message != null ? message : this.validationMessages.DEFAULT_ERROR,
                            scrollToInvalidField: scrollToFirstInvalidField && !this.alreadyFocused,
                            focusInvalidField: focusFirstInvalidField && !this.alreadyFocused,
                        });

                        if (Is.function(onInvalidField)) {
                            onInvalidField(fieldName, message, invalidField);
                        }
                    }
                });

                if (error.isCommon() && Is.function(onCommonError)) {
                    onCommonError(error.common.getErrorMessage());
                }

                errorType = SERVER_ERROR_TYPES.API_SERVER;
            } else if (Is.instanceOf(error, FeipApiFatalError)) {
                errorType = SERVER_ERROR_TYPES.API_FATAL;
            } else if (Is.instanceOf(error, ApiClientNetworkError)) {
                errorType = SERVER_ERROR_TYPES.CLIENT_NETWORK;
            } else if (Is.instanceOf(error, ApiClientInvalidResponseError)) {
                errorType = SERVER_ERROR_TYPES.CLIENT_INVALID_RESPONSE;
            } else {
                errorType = SERVER_ERROR_TYPES.UNRESOLVED;
            }

            console.error(error);

            if (Is.function(onError)) {
                onError(error, errorType);
            }
        }
    },
});
