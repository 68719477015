/* eslint-disable import/first, import/newline-after-import */
import UIkit from 'uikit/dist/js/uikit-core';

// import Countdown from 'uikit/dist/js/components/countdown';
// UIkit.component('countdown', Countdown);

// import Filter from 'uikit/dist/js/components/filter';
// UIkit.component('filter', Filter);

import Lightbox from 'uikit/dist/js/components/lightbox';
UIkit.component('lightbox', Lightbox);

// import lightboxPanel from 'uikit/dist/js/components/lightbox-panel';
// UIkit.component('lightboxPanel', lightboxPanel);

import Notification from 'uikit/dist/js/components/notification';
UIkit.component('notification', Notification);

// import Parallax from 'uikit/dist/js/components/parallax';
// UIkit.component('parallax', Parallax);

// import Slider from 'uikit/dist/js/components/slider';
// UIkit.component('slider', Slider);

// import SliderParallax from 'uikit/dist/js/components/slider-parallax';
// UIkit.component('sliderParallax', SliderParallax);

// import Slideshow from 'uikit/dist/js/components/slideshow';
// UIkit.component('slideshow', Slideshow);

// UIkit.component('slideshowParallax', SliderParallax);

// import Sortable from 'uikit/dist/js/components/sortable';
// UIkit.component('sortable', Sortable);

import Tooltip from 'uikit/dist/js/components/tooltip';
UIkit.component('tooltip', Tooltip);

// import Upload from 'uikit/dist/js/components/upload';
// UIkit.component('upload', Upload);

export default UIkit;
