import get from 'lodash-es/get';
import { Is } from '@library/scripts/is';
import {
    showSuccessMessage,
    showErrorMessage,
} from '@library/uikit/notifications';
import { MESSAGES, VALIDATION_MESSAGES } from '@scripts/constants';
import { SERVER_ERROR_TYPES } from '@library/forms/validation';

export const form = () => ({
    methods: {
        successFormSending(result) {
            if (Is.string(get(result, 'redirect'))) {
                window.location.href = result.redirect;
                return;
            }

            showSuccessMessage({ text: MESSAGES.SUCCESS_FORM_SUBMITTING });

            if (Is.function(this.clearForm)) {
                this.clearForm();
            }
        },

        failedFormSending(
            error,
            {
                scrollToFirstInvalidField = true,
                focusFirstInvalidField = true,
            } = {}
        ) {
            if (Is.function(this.handleServerErrors)) {
                this.handleServerErrors({
                    error,
                    onError: this.onServerError,
                    onCommonError: this.onCommonError,
                    scrollToFirstInvalidField,
                    focusFirstInvalidField,
                });
            }
        },

        onCommonError(message) {
            showErrorMessage({ text: message });
        },

        onServerError(error, type) {
            if (type !== SERVER_ERROR_TYPES.API_SERVER) {
                showErrorMessage({
                    text: VALIDATION_MESSAGES.SERVER_ERROR,
                });
            }
        },

        onFailedRecaptcha() {
            showErrorMessage({
                text: VALIDATION_MESSAGES.RECAPTCHA_ERROR,
            });
        },

        onInputPhone(event) {
            if (/^[8]/.test(event.currentTarget.value)) {
                event.currentTarget.value = event.currentTarget.value.replace(
                    /^[8]/g,
                    '+7'
                );
            }
            this.formData.phone = event.currentTarget.value;
        },

        showRecaptchaBadge: () => {
            const badge = document.querySelector('.grecaptcha-badge');
            if (badge) {
                badge.style.visibility = 'visible';
            }
        },

        hideRecaptchaBadge: () => {
            const badge = document.querySelector('.grecaptcha-badge');
            if (badge) {
                badge.style.visibility = 'hidden';
            }
        },
    },
});
