<template>
    <div
        class="CartItemPhysic"
        :class="{ 'CartItemPhysic--removing': isRemoving }"
    >
        <a
            :href="url"
            class="CartItemPhysic__pictureLink"
            v-if="!isPresentInRossko"
        >
            <picture
                class="CartItemPhysic__picture"
                :class="{ 'CartItemPhysic__picture--withPlaceholder': !image }"
            >
                <img
                    v-if="image"
                    class="CartItemPhysic__image"
                    :srcset="`${image1x}, ${image2x} 2x`"
                    :src="image1x"
                    :alt="name"
                />
                <inline-svg
                    v-else
                    src="/assets/images/image-placeholder.svg"
                    class="CartItemPhysic__placeholder"
                ></inline-svg>
            </picture>
        </a>

        <span class="CartItemPhysic__pictureLink" v-else>
            <picture
                class="CartItemPhysic__picture"
                :class="{ 'CartItemPhysic__picture--withPlaceholder': !image }"
            >
                <img
                    v-if="image"
                    class="CartItemPhysic__image"
                    :srcset="`${image1x}, ${image2x} 2x`"
                    :src="image1x"
                    :alt="name"
                />
                <inline-svg
                    v-else
                    src="/assets/images/image-placeholder.svg"
                    class="CartItemPhysic__placeholder"
                ></inline-svg>
            </picture>
        </span>

        <div class="CartItemPhysic__nameWrapper">
            <a :href="url" class="CartItemPhysic__nameLink" v-if="!isPresentInRossko">
                <span
                    v-html="vendorCode"
                    class="CartItemPhysic__vendorCode"
                ></span>
                &nbsp;
                <span v-html="name" class="CartItemPhysic__name"></span>
            </a>
            <span class="CartItemPhysic__nameSpan" v-else>
                <span v-if="vendorCode">
                    <span
                        v-html="vendorCode"
                        class="CartItemPhysic__vendorCode"
                    ></span>
                    &nbsp;
                </span>
                <span v-html="name" class="CartItemPhysic__name"></span>
            </span>
        </div>

        <div class="CartItemPhysic__details">
            <span
                class="CartItemPhysic__packageUnitsQuantity"
                v-if="packageItemsCount > 0"
                v-html="`В&nbsp;упаковке: ${packageItemsCount}&nbsp;шт.`"
            ></span>

            <div class="CartItemPhysic__helpItem" v-if="designFeatures.length > 0">
                <button
                    type="button"
                    class="Button Button--underlinedLink CartItemPhysic__featuresLink"
                >
                    <span class="Button__text">
                        Особенности
                    </span>
                </button>
                <div class="CartItemPhysic__tooltip">
                    <a
                        class="Button Button--underlinedLink CartItemPhysic__tooltipLink"
                        target='_blank'
                        v-for="feature in designFeatures"
                        :href="feature.link"
                        v-html="feature.name"
                    />
                </div>
            </div>
        </div>

        <div class="CartItemPhysic__priceBlock">
            <span class="CartItemPhysic__price CartItemPhysic__price--total" v-html="formatRoubles(totalPrice)"></span>

            <div class="CartItemPhysic__statusBlock">
                <span
                    class="CartItemPhysic__price CartItemPhysic__price--perUnit"
                    v-if="pricePerPackage > 0"
                    v-html="`(${formatRoubles(pricePerPackage)} / упак.)`"
                ></span>

                <div class="CartItemPhysic__status">
                    <product-status
                        :quantity="quantity"
                        :quantity-in-package="packageItemsCount"
                        :stocks="stocks"
                        :is-user-wholesaler="isUserWholesaler"
                        :part-vendor-code="vendorCode"
                    ></product-status>
                </div>
            </div>

            <div class="CartItemPhysic__counter">
                <add-to-cart
                    :product-id="productId"
                    :quantity="quantity"
                    :mode="'cart'"
                    :quantity-in-package="packageItemsCount"
                    :is-user-wholesaler="isUserWholesaler"
                    :is-loading="isLoading"
                    :always-show-counter="true"
                    @change-quantity="changeQuantity"
                ></add-to-cart>
            </div>
        </div>

        <button
            type="button"
            class="Button Button--remove CartItemPhysic__removeButton"
            @click="removeItem"
        >
            <span class="Button__icon">
                <inline-svg src="/assets/icons/close.svg"></inline-svg>
            </span>
        </button>
    </div>
</template>

<script>
import CartItem from '../cart-item/cart-item.vue';
import { PRODUCT_STATUSES } from '@scripts/constants';

export default {
    extends: CartItem,
    props: {
        vendorCode: {
            type: String,
        },
        status: {
            type: String,
            required: true,
        },
        designFeatures: {
            type: Array,
        },
        stocks: {
            type: Array,
        },
        isPresentInRossko: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            PRODUCT_STATUSES,
        };
    },
};
</script>
