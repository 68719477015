import Vue from 'vue/dist/vue';
import { mapGetters, mapActions } from 'vuex';
import store from '@scripts/store';
import { VUEX_GETTERS, VUEX_ACTIONS, MESSAGES, MODALS } from '@scripts/constants';
import { showErrorMessage, showSuccessMessage } from '@library/uikit/notifications';
import { getFromBinding } from '@scripts/helpers';
import { apiFavoritesAdd, apiFavoritesRemove } from '@scripts/api-methods';
import AddToCart from '../../components/add-to-cart/add-to-cart.vue';
import ProductStatus from '../../components/product-status/product-status.vue';
import UIkit from '../../vendor/uikit/uikit';
import { yaEcommerce } from '@scripts/counters';

export default (el, name) =>
    new Vue({
        el,
        name,
        store,
        components: {
            AddToCart,
            ProductStatus,
        },
        data: () => ({
            productId: null,
            isProductFavorite: false,
            isFavoriteButtonLoading: false,
            isUserWholesaler: false,
        }),
        computed: {
            ...mapGetters([VUEX_GETTERS.CART_PRODUCT_QUANTITY, VUEX_GETTERS.CART_PRODUCT_LOADING_STATUS, VUEX_GETTERS.IS_USER_LOGGED_IN]),
        },
        beforeMount() {
            this.isProductFavorite = getFromBinding('product.is_favorite');
            this.productId = getFromBinding('product.id');
        },
        mounted() {
            this.addToMetrika(getFromBinding('product'));
        },
        methods: {
            ...mapActions([VUEX_ACTIONS.CHANGE_CART_PRODUCT_QUANTITY]),

            toFavorite() {
                if (this.productId === null) return;
                if (this.isUserLoggedIn) {
                    this.toggleProductFavorite();
                } else {
                    this.showLoginModal();
                }
            },

            toggleProductFavorite() {
                // TODO: API method favorite toggle
                if (this.isFavoriteButtonLoading) return;

                this.isFavoriteButtonLoading = true;

                const payload = {
                    product_id: this.productId,
                };

                if (!this.isProductFavorite) {
                    apiFavoritesAdd(payload)
                        .then(() => {
                            showSuccessMessage({ text: MESSAGES.FAVORITE_SUCCESS_ADD });
                            this.isProductFavorite = !this.isProductFavorite;
                        })
                        .catch(() => {
                            showErrorMessage({
                                text: MESSAGES.FAVORITE_TOGGLE_ERROR,
                            });
                        })
                        .finally(() => {
                            this.isFavoriteButtonLoading = false;
                        });
                } else {
                    apiFavoritesRemove(payload)
                        .then(() => {
                            showSuccessMessage({ text: MESSAGES.FAVORITE_SUCCESS_REMOVE });
                            this.isProductFavorite = !this.isProductFavorite;
                        })
                        .catch(() => {
                            showErrorMessage({
                                text: MESSAGES.FAVORITE_TOGGLE_ERROR,
                            });
                        })
                        .finally(() => {
                            this.isFavoriteButtonLoading = false;
                        });
                }
            },

            addToMetrika(product){
                yaEcommerce('detail',[{
                    id: product.vendor_code || '',
                    name: product.vendor_code+'. '+product.name.replace("&nbsp;",'') || '',
                    price: product.price_retail_per_package/100 || 0,
                    brand:'Точка Опоры'
                }]);
            },

            showLoginModal() {
                UIkit.modal(MODALS.LOGIN).show();
            },
        },
    });
