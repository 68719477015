var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "FilePicker" }, [
    _c(
      "label",
      {
        staticClass:
          "FilePicker__attachFilesButton Button Button--underlinedLink",
      },
      [
        _c("input", {
          staticClass: "FilePicker__input",
          attrs: {
            type: "file",
            accept: ".doc, .docx, .pdf, .png, .jpg, .jpeg",
            multiple: "",
          },
          on: {
            change: function ($event) {
              return _vm.uploadFile($event)
            },
          },
        }),
        _vm._v(" "),
        _c(
          "span",
          { staticClass: "Button__icon" },
          [_c("inline-svg", { attrs: { src: "/assets/icons/plus.svg" } })],
          1
        ),
        _vm._v(" "),
        _c("span", { staticClass: "Button__text" }, [
          _vm._v("Прикрепить файлы (pdf, doc/docx, png, jpg, jpeg)"),
        ]),
      ]
    ),
    _vm._v(" "),
    _vm.files.length > 0
      ? _c(
          "ul",
          { staticClass: "FilePicker__itemsList" },
          _vm._l(_vm.files, function (item) {
            return _c("li", { key: item.id, staticClass: "FilePicker__item" }, [
              _c(
                "button",
                {
                  staticClass: "FilePicker__removeButton",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.removeFile(item.id)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "FilePicker__removeButtonText" }, [
                    _vm._v("+"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("span", {
                staticClass: "FilePicker__filename",
                domProps: { textContent: _vm._s(item.name) },
              }),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }