import Vue from 'vue/dist/vue';
import { get } from 'lodash-es';
import { mapState, mapMutations } from 'vuex';
import { showSuccessMessage } from '@library/uikit/notifications';
import { INPUT_MASKS } from '@library/scripts/constants';
import { apiUpdateProfile, apiChangeEmailRequestConfirmation } from '@scripts/api-methods';
import { form } from '@scripts/mixins/form';
import { formSending } from '@library/forms/form-sending';
import { formValidation } from '@library/forms/validation';
import { VALIDATION_MESSAGES, VALIDATION_RULES, MESSAGES, VUEX_MUTATIONS } from '@scripts/constants';
import store from '@scripts/store';

const formDataScheme = () => ({
    name: '',
    email: '',
});

export default (el, name) =>
    new Vue({
        el,
        name,
        store,
        data: () => ({
            formData: formDataScheme(),
            masks: {
                phone: INPUT_MASKS.PHONE_MASK,
            },
        }),
        mixins: [
            form(),
            formSending({ useRecaptcha: true }),
            formValidation(
                {
                    email: {
                        field: 'formData.email',
                        rules: {
                            ...VALIDATION_RULES.EMAIL,
                        },
                    },
                },
                VALIDATION_MESSAGES
            ),
        ],
        computed: {
            ...mapState(['user']),

            isUserNameChanged() {
                return this.formData.name !== this.user.name;
            },

            isUserEmailChanged() {
                return this.formData.email !== this.user.email;
            },

            isUserDataChanged() {
                return this.isUserNameChanged || this.isUserEmailChanged;
            },
        },
        beforeMount() {
            this.formData.name = this.user.name;
            this.formData.email = this.user.email;
        },
        methods: {
            ...mapMutations([VUEX_MUTATIONS.SET_USER_NAME]),

            sendRequest() {
                if (!this.isUserDataChanged) return;

                if (this.isUserNameChanged) {
                    this.sendForm(
                        () =>
                            apiUpdateProfile({
                                name: this.formData.name || null,
                            }),
                        (response) => {
                            this.setUserName(get(response, 'role_data.name', '') || '');
                            showSuccessMessage({
                                text: MESSAGES.SUCCESS_USER_NAME_CHANGING,
                            });
                        }
                    );
                }

                if (this.isUserEmailChanged && this.formData.email !== '') {
                    this.sendForm(
                        () =>
                            apiChangeEmailRequestConfirmation({
                                email: this.formData.email,
                                captcha_token: this.token,
                            }),
                        () => {
                            showSuccessMessage({
                                text: MESSAGES.SUCCESS_USER_EMAIL_CHANGING,
                            });
                        }
                    );
                }
            },
        },
    });
