import Vue from 'vue/dist/vue';
import { displayCurrency, KnownCurrencyFormat } from '@library/scripts/currency';
import SmartPlurals from 'smart-plurals';

const pluralizeUtil = SmartPlurals.Plurals.getRule('ru');

Vue.mixin({
    methods: {
        formatRoubles(kopecks, units = undefined, isPriceFrom = false) {
            const formattedUnits = units !== undefined ? `&nbsp;/${units}` : '';
            const formattedPrice = displayCurrency(KnownCurrencyFormat.RUB, kopecks / 100, 2, '&thinsp;');
            const prefix = isPriceFrom === true ? 'от&nbsp;' : '';

            return `${prefix}${formattedPrice}${formattedUnits}`;
        },

        formatFloatNumberThousands: (value) => {
            return new Intl.NumberFormat('ru-RU').format(value / 1000);
        },

        formatPhone(phone) {
            return phone.replace(/(\d)(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 $2 $3-$4-$5');
        },

        pluralize: (quantity, pluralForms) => {
            return pluralizeUtil(quantity, pluralForms);
        },
    },
});
