import UIkit from 'uikit/dist/js/uikit-core';

export const FORM_NOTIFICATION_TYPES = {
    SUCCESS: 'success',
    ERROR: 'danger',
    WARNING: 'warning',
    INFO: 'primary',
};

let notificationDefaults = {
    position: 'top-right',

    successTitle: 'Готово!',
    errorTitle: 'Ошибка',
    warningTitle: 'Внимание!',
};

export function setupNotificationsDefaults(
    defaults = {
        position: '',
        successTitle: '',
        errorTitle: '',
        warningTitle: '',
    }
) {
    notificationDefaults = {
        ...notificationDefaults,
        ...defaults,
    };
}

export function showMessage({
    text,
    title = '',
    status = FORM_NOTIFICATION_TYPES.SUCCESS,
    position = notificationDefaults.position,
} = {}) {
    let content = `<span class="uk-notification-text">${text}</span>`;

    if (title !== '' && title != null) {
        content = `<span class="uk-notification-header">${title}</span>${content}`;
    }

    UIkit.notification({
        message: content,
        pos: position,
        status,
    });
}

export function showSuccessMessage({
    text,
    title = notificationDefaults.successTitle,
    position = notificationDefaults.position,
} = {}) {
    showMessage({
        text,
        title,
        status: FORM_NOTIFICATION_TYPES.SUCCESS,
        position,
    });
}

export function showErrorMessage({
    text,
    title = notificationDefaults.errorTitle,
    position = notificationDefaults.position,
} = {}) {
    showMessage({
        text,
        title,
        status: FORM_NOTIFICATION_TYPES.ERROR,
        position,
    });
}

export function showWarningMessage({
    text,
    title = notificationDefaults.warningTitle,
    position = notificationDefaults.position,
}) {
    showMessage({
        text,
        title,
        status: FORM_NOTIFICATION_TYPES.WARNING,
        position,
    });
}

export function showInfoMessage({
    text,
    title,
    position = notificationDefaults.position,
}) {
    showMessage({
        text,
        title,
        status: FORM_NOTIFICATION_TYPES.INFO,
        position,
    });
}
