import Vue from 'vue/dist/vue';
import { mask } from 'vue-the-mask';
import { INPUT_MASKS } from '@library/scripts/constants';
import { formSending } from '@library/forms/form-sending';
import { formValidation } from '@library/forms/validation';
import { form } from '@scripts/mixins/form';
import { VALIDATION_MESSAGES, VALIDATION_RULES, MODALS } from '@scripts/constants';
import { apiReclamation, apiCatalogMarks, apiCatalogModels } from '@scripts/api-methods';
import UIkit from '../../../vendor/uikit/uikit';
import Dropdown from '../../dropdown/dropdown.vue';
import FilePicker from '../../file-picker/file-picker.vue';

const formDataScheme = () => ({
    name: '',
    phone: '',
    email: '',
    brand: null,
    model: null,
    vin: '',
    engineVolume: '',
    text: '',
    files: [],
});

export default (el, name) =>
    new Vue({
        el,
        name,
        components: {
            Dropdown,
            FilePicker,
        },
        data: () => ({
            formData: formDataScheme(),
            masks: {
                phone: INPUT_MASKS.PHONE_MASK,
            },
            dropdownOptions: {
                brands: [],
                models: [],
            },
        }),
        directives: { mask },
        computed: {
            isModelDisabled() {
                return this.dropdownOptions.brands.length === 0 || this.dropdownOptions.models.length === 0;
            },
        },
        mixins: [
            form(),
            formSending({ useRecaptcha: true }),
            formValidation(
                {
                    name: {
                        field: 'formData.name',
                        rules: {
                            required: true,
                        },
                    },
                    phone: {
                        field: 'formData.phone',
                        rules: {
                            required: true,
                            ...VALIDATION_RULES.PHONE,
                        },
                    },
                    email: {
                        field: 'formData.email',
                        rules: {
                            ...VALIDATION_RULES.EMAIL,
                        },
                    },
                    text: {
                        field: 'formData.text',
                        rules: {
                            required: true,
                            minLength: 3,
                            maxLength: 2000,
                        },
                    },
                },
                VALIDATION_MESSAGES
            ),
        ],
        beforeMount() {
            apiCatalogMarks()
                .then(({ items }) => {
                    this.dropdownOptions.brands = this.formatListOptions(items);
                })
                .catch(console.warn);
        },
        methods: {
            formatListOptions(items) {
                return items.map((item) => ({
                    label: item.name,
                    value: item.id,
                }));
            },

            clearForm() {
                this.formData = formDataScheme();
                this.$refs['file-picker'].clear();
            },

            onFilesChange(data) {
                this.formData.files = data.files.map((item) => ({
                    name: item.name,
                    bytes: item.bytes.split(',')[1],
                }));
            },

            sendRequest() {
                return this.sendForm(
                    () =>
                        apiReclamation(
                            this.optionalPayload({
                                name: this.formData.name,
                                phone: this.formData.phone,
                                email: this.isOptional(this.formData.email, ''),
                                car_brand: this.isOptional(this.formData.brand, null, 'label'),
                                car_model: this.isOptional(this.formData.model, null, 'label'),
                                vin_or_car_body_number: this.isOptional(this.formData.vin, ''),
                                engine_volume: this.isOptional(this.formData.engineVolume, ''),
                                text: this.formData.text,
                                attachments: this.formData.files,
                                captcha_token: this.token,
                            })
                        ),
                    () => {
                        this.successFormSending();
                        UIkit.modal(MODALS.CLAIM).hide();
                    }
                );
            },
        },
        watch: {
            'formData.brand': function formDataBrand(currentValue) {
                this.formData.model = null;
                this.dropdownOptions.models = [];

                if (currentValue && currentValue.value) {
                    apiCatalogModels({
                        mark_id: currentValue.value,
                    })
                        .then(({ items }) => {
                            this.dropdownOptions.models = this.formatListOptions(items);
                        })
                        .catch(this.failedFormSending);
                }
            },
        },
    });
