import Vue from 'vue/dist/vue';
import { tabsMixin } from '../../scripts/mixins/tabs';

const TABS = {
    CAR: 'car',
    OEM: 'oem',
    VIN: 'vin',
};

export default (el, name) =>
    new Vue({
        el,
        name,
        mixins: [tabsMixin()],
        data: () => ({
            currentTab: TABS.CAR,
            TABS,
            tabsSelectors: ['data-compatibility-info-tab'],
        }),
    });
