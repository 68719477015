import Vue from 'vue/dist/vue';
import DatePick from 'vue-date-pick';
import { formSending } from '@library/forms/form-sending';
import { formValidation } from '@library/forms/validation';
import { showErrorMessage } from '@library/uikit/notifications';
import { form } from '@scripts/mixins/form';
import { VALIDATION_MESSAGES, MODALS, MESSAGES } from '@scripts/constants';
import { redirect } from '@library/scripts/network';
import {
    apiRegisterDetails,
    apiCatalogMarks,
    apiCatalogModels,
} from '../../../scripts/api-methods';
import UIkit from '../../../vendor/uikit/uikit';
import Dropdown from '../../dropdown/dropdown.vue';

const formDataScheme = () => ({
    name: '',
    date: null,
    brand: null,
    model: null,
    vin: '',
    engineVolume: '',
    serials: '',
});

export default (el, name) =>
    new Vue({
        el,
        name,
        components: {
            Dropdown,
            DatePick,
        },
        data: () => ({
            formData: formDataScheme(),
            listsData: {
                brands: [],
                models: [],
            },
        }),
        beforeMount () {
            apiCatalogMarks()
                .then(({ items }) => {
                    this.listsData.brands = items;
                })
                .catch(console.warn);
        },
        mixins: [
            form(),
            formSending({ useRecaptcha: true }),
            formValidation(
                {
                    name: {
                        field: 'formData.name',
                        rules: {
                            required: true,
                        },
                    },
                    bought_at: {
                        field: 'formData.date',
                        rules: {
                            required: true,
                        },
                    },
                    car_brand: {
                        field: 'formData.brand',
                        rules: {
                            required: true,
                        },
                    },
                    car_model: {
                        field: 'formData.model',
                        rules: {
                            required: true,
                        },
                    },
                    vin_or_car_body_number: {
                        field: 'formData.vin',
                        rules: {
                            required: true,
                        },
                    },
                    engine_volume: {
                        field: 'formData.engineVolume',
                        rules: {
                            required: true,
                        },
                    },
                    serial_numbers: {
                        field: 'formData.serials',
                        rules: {
                            required: true,
                        },
                    },
                },
                VALIDATION_MESSAGES,
            ),
        ],
        computed: {
            dropdownOptions() {
                return {
                    brands: this.formatListOptions(this.listsData.brands),
                    models: this.formatListOptions(this.listsData.models),
                };
            },

            isModelDisabled() { return this.listsData.brands.length === 0 || this.listsData.models.length === 0; },
        },
        methods: {
            formatListOptions (items) {
                return items.map((item) => ({
                    label: item.name,
                    value: item.id,
                }));
            },

            formatSerialNumbers (serialNumbers) {
                return serialNumbers.split(',').map(serial => serial.trim());
            },

            fetchModels (id) {
                apiCatalogModels({
                    mark_id: id,
                })
                    .then(({ items }) => {
                        this.listsData.models = items;
                    })
                    .catch(this.failedFormSending);
            },

            clearForm () {
                this.formData = formDataScheme();
            },

            isDisabledDate (date) {
                return date > new Date().setHours(0, 0, 0, 0);
            },

            sendRequest () {
                return this.sendForm(
                    () =>
                        apiRegisterDetails({
                            name: this.formData.name,
                            bought_at: this.formData.date,
                            product_mark_id: this.formData.brand.value,
                            product_model_id: this.formData.model.value,
                            vin_or_car_body_number: this.formData.vin,
                            engine_volume: this.formData.engineVolume,
                            serial_numbers: this.formatSerialNumbers(this.formData.serials),
                            captcha_token: this.token,
                        }),
                    () => {
                        this.successFormSending();
                        UIkit.modal(MODALS.DETAILS_REGISTRATION).hide();
                        redirect();
                    },
                    () => {
                        showErrorMessage({
                            text: MESSAGES.REGISTER_DETAIL_SERIAL_NUMBERS_ERROR,
                        });
                    }
                );
            },
        },
        watch: {
            'formData.brand': function formDataBrand(currentValue) {
                this.formData.model = null;
                this.listsData.models = [];

                if (currentValue && currentValue.value) {
                    this.fetchModels(currentValue.value);
                }
            },
        },
    });
UIkit.util.on(MODALS.DETAILS_REGISTRATION, 'shown', function () {
    this.setAttribute('tabindex','');
});