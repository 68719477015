import Vue from 'vue/dist/vue';
import eventBus from '@scripts/event-bus';
import { GLOBAL_EVENTS, MODALS, MESSAGES } from '@scripts/constants';
import { showErrorMessage, showSuccessMessage } from '@library/uikit/notifications';
import AddressItem from '../address-item/address-item.vue';
import UIkit from '../../vendor/uikit/uikit';
import { apiRemoveUserAddress } from '../../scripts/api-methods';

export default (el, name, data) =>
    new Vue({
        el,
        name,
        components: {
            AddressItem,
        },
        data: () => ({
            addresses: [],
            binding: data,
        }),
        beforeMount() {
            this.addresses = this.binding.addresses;
        },
        methods: {
            editAddress(event) {
                UIkit.modal(MODALS.USER_ADDRESS).show();
                eventBus.$emit(GLOBAL_EVENTS.OPEN_EDIT_ADDRESS_MODAL, {
                    address: this.addresses.find((address) => address.id === event.id),
                });
            },

            removeAddress(event) {
                apiRemoveUserAddress({user_address_id: event.id})
                    .then(() => {
                        this.addresses = this.addresses.filter((address) => address.id !== event.id);
                        showSuccessMessage({
                            text: MESSAGES.REMOVE_ADDRESS_SUCCESS,
                        });
                    })
                    .catch(() => {
                        showErrorMessage({
                            text: MESSAGES.REMOVE_ADDRESS_ERROR,
                        });
                    })
            },
        },
    });
