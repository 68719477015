import Vue from 'vue/dist/vue';
import { mapGetters, mapActions, mapState } from 'vuex';
import store from '@scripts/store';
import {VUEX_GETTERS, VUEX_ACTIONS, USER_TYPES, DOCUMENT_EVENTS} from '@scripts/constants';
import { deliveryTimeMixin } from "@scripts/mixins/delivery-time";
import { apiGetSharedCartLink } from '@scripts/api-methods';
import { showErrorMessage, showSuccessMessage } from '@library/uikit/notifications';
import CartItemLegal from '../../components/cart-item-legal/cart-item-legal.vue';
import CartItemPhysic from '../../components/cart-item-physic/cart-item-physic.vue';
import UIkit from '../../vendor/uikit/uikit';

const LEGAL_STICKY_TABLE_HEAD_ELEMENT = '.CartTableLegal__sticky';

export default (el, name) =>
    new Vue({
        el,
        name,
        store,
        components: {
            CartItemLegal,
            CartItemPhysic,
        },
        mixins: [deliveryTimeMixin()],
        computed: {
            ...mapGetters([VUEX_GETTERS.CART_PRODUCT_LOADING_STATUS, VUEX_GETTERS.CART_TOTAL_ITEMS_QUANTITY, VUEX_GETTERS.IS_USER_WHOLESALER]),

            ...mapState(['cart', 'user']),

            renderRemoveButtonCell() {
                return !(this.displayRetailPrices && this.displayWeight);
            },
            onOrderTime() {
                return this.deliveryTimeByStatus('on_order');
            },
            forProductionTime() {
                return this.deliveryTimeByStatus('for_production');
            },
            scrollLeftTable() {
                return this.$refs.table.scrollLeft;
            },
            scrollLeftSticky() {
                return this.$refs.sticky.scrollLeft;
            },
        },
        data: () => ({
            displayRetailPrices: true,
            displayWeight: true,
            allProductsAreAvailable: false, // TODO
            checkboxes: {
                itemsAvailable: false,
                itemForOrder: false,
                itemsForManufacturing: false,
            },
            deliveryTimeString: '',
            deliveryTimeShipment: '',
            USER_TYPES,
        }),
        beforeMount() {
            this.displayRetailPrices = this.user.settings.showRetailPriceInCartAndCheckout;
            this.displayWeight = this.user.settings.showWeightInCart;
        },
        mounted() {
            // Set scroll position from table body to sticky element when visible
            UIkit.util.on(LEGAL_STICKY_TABLE_HEAD_ELEMENT, 'active', () => {
                this.$refs.sticky.scrollLeft = this.$refs.table.scrollLeft;
            });

            // Sync table body with sticky element scroll
            if (this.$refs.table) {
                this.$refs.table.addEventListener(DOCUMENT_EVENTS.SCROLL, () => {
                    this.$refs.sticky.scrollLeft = this.$refs.table.scrollLeft;
                });
            }
        },
        methods: {
            ...mapActions([VUEX_ACTIONS.REMOVE_CART_ITEM, VUEX_ACTIONS.CHANGE_CART_PRODUCT_QUANTITY]),

            deliveryTimeByStatus(status) {
                let maxTime = 0;

                this.cart.items.forEach((item) => {
                    item.product.stocks.forEach((stock) => {
                        if (stock.status.id === status && stock.delivery_time_in_days > maxTime) {
                            maxTime = stock.delivery_time_in_days;
                        }
                    });
                });

                return maxTime;
            },

            shareCart(){
                apiGetSharedCartLink()
                    .then((res) => {
                        if(res.link){
                            window.location.href = '/cart/shared/'+res.link;
                        }

                    })
                    .catch(() => {
                        showErrorMessage({
                            text: 'Произошла ошибка',
                        });
                    })
                ;
            }
        },
    });
