<script>
import InlineSvg from 'vue-inline-svg';
import AddToCart from '../add-to-cart/add-to-cart.vue';
import ProductStatus from '../product-status/product-status.vue';
import { getImageSize } from '@scripts/helpers';

export default {
    props: {
        id: {
            type: Number,
            required: true,
        },
        productId: {
            type: Number,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        url: {
            type: String,
            required: true,
        },
        quantity: {
            type: Number,
            required: true,
        },
        packageItemsCount: {
            type: Number,
            required: true,
        },
        totalPrice: {
            type: Number,
            required: true,
        },
        imageSize: {
            type: Number,
            required: true,
        },
        isUserWholesaler: {
            type: Boolean,
            required: true,
        },
        image: {
            type: Object,
        },
        pricePerItem: {
            type: Number,
        },
        pricePerPackage: {
            type: Number,
        },
        isLoading: {
            type: Boolean,
        },
    },
    components: {
        InlineSvg,
        AddToCart,
        ProductStatus,
    },
    data() {
        return {
            isRemoving: false,
        };
    },
    computed: {
        image1x() {
            return getImageSize(this.image.sizes, this.imageSize);
        },

        image2x() {
            return getImageSize(this.image.sizes, this.imageSize * 2);
        },
    },
    methods: {
        changeQuantity(event) {
            if (event.quantity === 0) {
                this.isRemoving = true;
            }
            this.$emit('change-quantity', {
                productId: this.productId,
                quantity: event.quantity,
            });
        },

        removeItem() {
            this.isRemoving = true;
            this.$emit('remove', { productId: this.productId });
        },
    },
    watch: {
        isLoading(isLoading) {
            if (isLoading === false) {
                this.isRemoving = false;
            }
        },
    },
};
</script>
