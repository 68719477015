import Vue from 'vue/dist/vue';
import { mapActions, mapGetters } from "vuex";
import store from '@scripts/store';
import { initializer } from "../../vendor/swiper/swiper";
import { apiGetConcomitantProducts } from "../../scripts/api-methods";
import { toggleFavorites } from "../../scripts/mixins/toggle-favorites";
import { mapNeighboursItemsResponse } from "../../scripts/mappings";
import { VUEX_GETTERS, VUEX_ACTIONS } from "../../scripts/constants";
import AddToCart from '../add-to-cart/add-to-cart.vue';
import ProductStatus from '../product-status/product-status.vue';
import ProductCompactCard from '../product-compact-card/product-compact-card.vue';


const productsSliderOptions = {
    pagination: {
        el: '[data-swiper-pagination]',
        type: 'fraction',
    },
};

const PER_PAGE = 100;

export default (el, name) =>
    new Vue({
        el,
        name,
        store,
        components: {
            AddToCart,
            ProductStatus,
            ProductCompactCard,
        },
        mixins: [toggleFavorites()],
        data: {
            items: [],
            page: 1,
            perPage: PER_PAGE,
            total: null,
            swiper: undefined,
        },
        beforeMount() {
            this.getProducts(this.page);
        },
        mounted() {
        },
        computed: {
            ...mapGetters([VUEX_GETTERS.CART_PRODUCT_QUANTITY, VUEX_GETTERS.CART_PRODUCT_LOADING_STATUS, VUEX_GETTERS.IS_USER_WHOLESALER]),

            totalItems() {
                return this.items.length;
            },
        },
        methods: {
            ...mapActions([VUEX_ACTIONS.CHANGE_CART_PRODUCT_QUANTITY]),

            getFavoriteProducts(products) {
                return products.reduce((favorites, product) => {
                    if (product && product.isFavorite) {
                        favorites = {
                            ...favorites,
                            [`id${product.id}`]: 'liked',
                        };
                    }
                    return favorites;
                }, {});
            },

            setFavoritesProducts(favorites) {
                this.favoritesMap = Object.assign(this.favoritesMap, favorites);
            },

            async getProducts(initPage = 1) {
                if (this.totalItems !== this.total) {
                    const pageProducts = await apiGetConcomitantProducts({
                        page: initPage,
                        per_page: 100,
                    });

                    const { total, items, page } = pageProducts;

                    this.total = total;
                    this.page = page;

                    const newItems = this.items.concat(mapNeighboursItemsResponse(items));
                    this.setFavoritesProducts(this.getFavoriteProducts(newItems));
                    this.items = newItems;

                    setTimeout(() => {
                        this.swiper = initializer('[data-products-slider]', productsSliderOptions);
                    }, 0);
                }
            },
        },
    });
