<template>
    <section class="CheckoutItemsList">
        <ul class="CheckoutItemsList__list">
            <li
                class="CheckoutItemsList__row"
                v-for="item in items"
                :key="item.id"
            >
                <a
                    class="CheckoutItemsList__pictureLink"
                    :href="item.product.url"
                    v-if="!item.product.is_present_in_rossko"
                >
                    <picture class="CheckoutItemsList__picture">
                        <img
                            v-if="item.product.image_small"
                            class="CheckoutItemsList__image"
                            :srcset="`${getImageSize(
                                item.product.image_small
                            )}, ${getImageSize(item.product.image_small, 2)} 2x`"
                            :src="getImageSize(item.product.image_small)"
                            :alt="item.product.name"
                        />

                        <inline-svg
                            v-else
                            src="/assets/images/image-placeholder.svg"
                            class="CheckoutItemsList__placeholder"
                        ></inline-svg>
                    </picture>
                </a>

                <span v-else>
                    <picture class="CheckoutItemsList__picture">
                        <img
                            v-if="item.product.image_small"
                            class="CheckoutItemsList__image"
                            :srcset="`${getImageSize(
                                item.product.image_small
                            )}, ${getImageSize(item.product.image_small, 2)} 2x`"
                            :src="getImageSize(item.product.image_small)"
                            :alt="item.product.name"
                        />

                        <inline-svg
                            v-else
                            src="/assets/images/image-placeholder.svg"
                            class="CheckoutItemsList__placeholder"
                        ></inline-svg>
                    </picture>
                </span>

                <a
                    class="CheckoutItemsList__productName"
                    :href="item.product.url"
                    v-html="item.product.name"
                    v-if="!item.product.is_present_in_rossko"
                ></a>

                <span
                    class="CheckoutItemsList__productName"
                    v-html="item.product.name"
                    v-else
                ></span>

                <span
                    class="CheckoutItemsList__productPackagesCount"
                    v-html="
                        `${item.quantity_packages} ${pluralize(item.quantity_packages, [
                            'упаковка',
                            'упаковки',
                            'упаковок',
                        ])}`
                    "
                ></span>

                <span
                    class="CheckoutItemsList__productPrice"
                    v-if="item.wholesale"
                    v-html="formatRoubles(item.wholesale.price_total_wholesale)"
                ></span>
                <span
                    class="CheckoutItemsList__productPrice"
                    v-else
                    v-html="formatRoubles(item.price_total_retail)"
                ></span>
            </li>
        </ul>

        <footer class="CheckoutItemsList__row CheckoutItemsList__row--total">
            <span>Итого:</span>

            <span v-html="formatRoubles(total)"></span>
        </footer>
    </section>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import { getImageSize } from '@scripts/helpers';

const imageSize = 56;

export default {
    props: {
        items: {
            type: Array,
            required: true,
        },
        total: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    components: {
        InlineSvg,
    },
    methods: {
        getImageSize(image, multiplier = 1) {
            return getImageSize(image.sizes, imageSize * multiplier);
        },
    },
};
</script>
