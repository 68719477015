'use strict'

import { REQUEST } from './constants'
import { Is } from './is'
import { scriptLoader } from './promises'

export function refresh() {
	location.reload()
}
export function redirect(destination = window.location.href, timeout = REQUEST.RELOAD_PAGE_TIMEOUT) {
	setTimeout(() => {
		if (window.location.href === destination || destination == null) window.location.reload()
		else window.location.href = destination
	}, timeout)
}

export function parseQueryParams(search = document.location.search) {
	return [...new URLSearchParams(search).entries()]
		.reduce((q, [k, v]) => Object.assign(q, { [k]: v }), {})
}

/**
 * Helper function that flattens an object, retaining key structure as a path array
 * @param obj
 * @param urlEncode
 * @returns {*}
 */
export function toQueryString(obj, urlEncode) {
	function flattenObj(x, path) {
		const result = []
		path = path || []
		Object.keys(x).forEach(function (key) {
			if (!x.hasOwnProperty(key)) return
			const newPath = path.slice()
			newPath.push(key)
			let values = []
			if (x[key] instanceof Object) {
				values = flattenObj(x[key], newPath)
			} else {
				values.push({ path: newPath, val: x[key] })
			}
			values.forEach(function (obj) {
				return result.push(obj)
			})
		})

		return result
	}

	// start with  flattening `obj`
	let parts = flattenObj(obj) // [ { path: [ ...parts ], val: ... }, ... ]

	// convert to array notation:
	parts = parts.map(varInfo => {
		if (varInfo.path.length === 1) {
			varInfo.path = varInfo.path[0]
		} else {
			varInfo.path = `${varInfo.path[0]}[${varInfo.path.slice(1).join('][')}]`
		}
		return varInfo
	})

	// join the parts to a query-string url-component
	const queryString = parts.map(varInfo => varInfo.path + '=' + varInfo.val).join('&')
	if (urlEncode) {
		return encodeURIComponent(queryString)
	} else {
		return queryString
	}
}

/**
 * Load script by url & inject into DOM like Promise
 * @param {string} url
 * @return {Promise}
 */
export const scriptPromise = scriptLoader

/** @deprecated */
export function removeHashFromUrl () {
	const uri = window.location.toString()
	if (uri.indexOf('#') > 0) {
		window.history.replaceState({}, document.title, uri.substring(0, uri.indexOf('#')))
	}
}

/** @see https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent */
export function fixedEncodeURIComponent (str) {
	return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
		return '%' + c.charCodeAt(0).toString(16)
	})
}

export function setPageHash (hash = null) {
	const uri = window.location.toString()
	if (Is.null(hash) && uri.indexOf('#') > 0) {
		window.history.replaceState({}, document.title, uri.substring(0, uri.indexOf('#')))
	} else if (Is.string(hash)) {
		const normalizedHash = '#' + hash.replace('#', '')
		const newUrl = uri.substring(0, uri.indexOf('#')) + normalizedHash
		window.history.replaceState({}, document.title, newUrl)
	}
}
