'use strict'

export function plural(number, one, two, five) {
	let n = Math.abs(number)
	n %= 100
	if (n >= 5 && n <= 20) {
		return five
	}
	n %= 10
	if (n === 1) {
		return one
	}
	if (n >= 2 && n <= 4) {
		return two
	}
	return five
}

export function capitalize(string) {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

export function capitalizeParagraph(paragraph) {
	return paragraph.split(' ').map(capitalize).join(' ')
}

export function lettering(string) {
	return string.toString().replace(/./g, letter => `<span>${letter}</span>`)
}

export function camelize(str) {
	return str
		.replace(/(?:^\w|[A-Z]|\b\w)/g, (ltr, idx) => idx === 0
			? ltr.toLowerCase()
			: ltr.toUpperCase())
		.replace(/\s+/g, '')
}

export function convertHtmlToText(html) {
	const doc = new DOMParser().parseFromString(html, 'text/html')
	return doc.body.textContent || ''
}

export function wordsLimit(str, limit) {
	let words = str.match(/([^ ]+)/g).slice(0, limit)
	let result = words.join(' ')
	if (limit < words.length) {
		result = result.concat('...')
	}
	return result
}
