var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    {
      staticClass: "CartTableLegal__row CartItemLegal",
      class: { "CartItemLegal--removing": _vm.isRemoving },
    },
    [
      _c("td", { staticClass: "CartItemLegal__cell" }, [
        !_vm.isPresentInRossko
          ? _c(
              "a",
              { staticClass: "CartItemLegal__link", attrs: { href: _vm.url } },
              [
                _c(
                  "picture",
                  {
                    staticClass: "CartItemLegal__picture",
                    class: {
                      "CartItemLegal__picture--withPlaceholder": !_vm.image,
                    },
                  },
                  [
                    _vm.image
                      ? _c("img", {
                          staticClass: "CartItemLegal__image",
                          attrs: {
                            srcset: `${_vm.image1x}, ${_vm.image2x} 2x`,
                            src: _vm.image1x,
                            alt: _vm.name,
                          },
                        })
                      : _c("inline-svg", {
                          staticClass:
                            "CartItemLegal__image CartItemLegal__image--placeholder",
                          attrs: {
                            src: "/assets/images/image-placeholder.svg",
                          },
                        }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "CartItemLegal__nameWrapper" }, [
                  _vm.vendorCode
                    ? _c("span", [
                        _c("span", {
                          staticClass: "CartItemLegal__vendorCode",
                          domProps: { innerHTML: _vm._s(_vm.vendorCode) },
                        }),
                        _vm._v("\n                     \n                "),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "CartItemLegal__name",
                    domProps: { innerHTML: _vm._s(_vm.name) },
                  }),
                ]),
              ]
            )
          : _c("span", { staticClass: "CartItemLegal__title" }, [
              _c(
                "picture",
                {
                  staticClass: "CartItemLegal__picture",
                  class: {
                    "CartItemLegal__picture--withPlaceholder": !_vm.image,
                  },
                },
                [
                  _vm.image
                    ? _c("img", {
                        staticClass: "CartItemLegal__image",
                        attrs: {
                          srcset: `${_vm.image1x}, ${_vm.image2x} 2x`,
                          src: _vm.image1x,
                          alt: _vm.name,
                        },
                      })
                    : _c("inline-svg", {
                        staticClass:
                          "CartItemLegal__image CartItemLegal__image--placeholder",
                        attrs: { src: "/assets/images/image-placeholder.svg" },
                      }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "CartItemLegal__nameWrapperNeighbours" },
                [
                  _vm.vendorCode
                    ? _c("span", [
                        _c("span", {
                          staticClass: "CartItemLegal__vendorCode",
                          domProps: { innerHTML: _vm._s(_vm.vendorCode) },
                        }),
                        _vm._v("\n                     \n                "),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "CartItemLegal__name",
                    domProps: { innerHTML: _vm._s(_vm.name) },
                  }),
                ]
              ),
            ]),
      ]),
      _vm._v(" "),
      _c("td", {
        staticClass: "CartItemLegal__cell",
        domProps: {
          innerHTML: _vm._s(
            _vm.formatValue(_vm.pricePerItem, _vm.formatRoubles)
          ),
        },
      }),
      _vm._v(" "),
      _c("td", { staticClass: "CartItemLegal__cell" }, [
        _c(
          "div",
          { staticClass: "CartItemLegal__counter" },
          [
            _c("counter", {
              attrs: {
                mode: "legalCart",
                value: _vm.fakeQuantity,
                "quantity-in-package": _vm.packageItemsCount,
                "is-user-wholesaler": _vm.isUserWholesaler,
                "max-item-quantity": 999,
                mask: "###",
                "show-buttons": false,
              },
              on: {
                change: function ($event) {
                  return _vm.debounceChangeQuantity($event)
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("td", {
        staticClass: "CartItemLegal__cell",
        domProps: { innerHTML: _vm._s(_vm.packageItemsCount) },
      }),
      _vm._v(" "),
      _c("td", {
        staticClass: "CartItemLegal__cell",
        domProps: { innerHTML: _vm._s(_vm.packagesCount) },
      }),
      _vm._v(" "),
      _c("td", {
        staticClass: "CartItemLegal__cell CartItemLegal__cell--greenText",
        domProps: { innerHTML: _vm._s(_vm.availableQuantity) },
      }),
      _vm._v(" "),
      _vm.displayUnavailableProductsInfo
        ? _c("td", {
            staticClass: "CartItemLegal__cell",
            domProps: {
              innerHTML: _vm._s(_vm.formatValue(_vm.quantityForOrder)),
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.displayUnavailableProductsInfo
        ? _c("td", {
            staticClass: "CartItemLegal__cell",
            domProps: {
              innerHTML: _vm._s(_vm.formatValue(_vm.quantityForManufacturing)),
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("td", {
        staticClass: "CartItemLegal__cell",
        domProps: {
          innerHTML: _vm._s(_vm.formatValue(_vm.totalPrice, _vm.formatRoubles)),
        },
      }),
      _vm._v(" "),
      _vm.displayRetailPrices
        ? _c("td", {
            staticClass:
              "CartItemLegal__cell CartItemLegal__cell--lightGrayText",
            domProps: {
              innerHTML: _vm._s(
                _vm.formatValue(_vm.pricePerItemRetail, _vm.formatRoubles)
              ),
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.displayRetailPrices
        ? _c("td", {
            staticClass:
              "CartItemLegal__cell CartItemLegal__cell--lightGrayText",
            domProps: {
              innerHTML: _vm._s(
                _vm.formatValue(_vm.totalPriceRetail, _vm.formatRoubles)
              ),
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.displayWeight
        ? _c("td", {
            staticClass: "CartItemLegal__cell",
            domProps: {
              innerHTML: _vm._s(
                _vm.formatValue(
                  _vm.weightPerItem,
                  _vm.formatFloatNumberThousands
                )
              ),
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.displayWeight
        ? _c(
            "td",
            {
              staticClass:
                "CartItemLegal__cell CartItemLegal__cell--alignRight",
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.formatValue(
                      _vm.weightItemTotal,
                      _vm.formatFloatNumberThousands
                    )
                  ) +
                  "\n\n        "
              ),
              !_vm.renderRemoveButtonCell
                ? _c(
                    "button",
                    {
                      staticClass:
                        "Button Button--remove CartItemLegal__removeButton",
                      attrs: { type: "button" },
                      on: { click: _vm.removeItem },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "Button__icon" },
                        [
                          _c("inline-svg", {
                            attrs: { src: "/assets/icons/close.svg" },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.renderRemoveButtonCell
        ? _c(
            "td",
            { staticClass: "CartItemLegal__cell CartItemLegal__cell--remove" },
            [
              _c(
                "button",
                {
                  staticClass:
                    "Button Button--remove CartItemLegal__removeButton",
                  attrs: { type: "button" },
                  on: { click: _vm.removeItem },
                },
                [
                  _c(
                    "span",
                    { staticClass: "Button__icon" },
                    [
                      _c("inline-svg", {
                        attrs: { src: "/assets/icons/close.svg" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }