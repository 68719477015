import Vue from 'vue/dist/vue';
import { mapGetters, mapActions, mapState } from 'vuex';
import store from '@scripts/store';
import { VUEX_GETTERS, VUEX_ACTIONS, USER_TYPES, MODALS, MESSAGES } from '@scripts/constants';
import { showSuccessMessage } from '@library/uikit/notifications';
import { formSending } from '@library/forms/form-sending';
import { apiCitySelect } from '@scripts/api-methods';
import { getFromBinding } from '@scripts/helpers';
import Dropdown from '../dropdown/dropdown.vue';
import MiniCartItem from '../mini-cart-item/mini-cart-item.vue';
import UIkit from '../../vendor/uikit/uikit';
import { formValidation } from '../../../feip-frontend/forms/validation';

import { form } from '../../scripts/mixins/form';
import { vinMixin } from '../../scripts/mixins/vin';
import { deliveryTimeMixin } from "../../scripts/mixins/delivery-time";

export default (el, name, data) =>
    new Vue({
        el,
        name,
        store,
        components: {
            Dropdown,
            MiniCartItem,
        },
        mixins: [formValidation({ vin_or_frame: { field: 'searchString' } }), form(), vinMixin(),
            formSending({ useRecaptcha: true }), deliveryTimeMixin(), ],
        computed: {
            ...mapGetters([VUEX_GETTERS.CART_PRODUCT_LOADING_STATUS, VUEX_GETTERS.CART_TOTAL_ITEMS_QUANTITY, VUEX_GETTERS.IS_USER_WHOLESALER]),
            ...mapState(['cart', 'user', 'selectedCity', 'isCitySelected']),
            isYourCityText() {
                // return `Ваш&nbsp;город&nbsp;${this.selectedCity.city}?`;
                return `Ближайший&nbsp;склад&nbsp;в&nbsp;г.&nbsp;${this.selectedCity.city}?`;
            },
            totalPrice() {
                return this.isUserWholesaler ? this.cart.totalPriceWholesale : this.cart.totalPriceRetail;
            },
        },
        data: () => ({
            submitButtonLoading: false,
            searchString: '',
            searchType: '',
            searchTypeOptions: [
                { value: 'vin', label: 'По VIN / кузову' },
                { value: 'oem', label: 'По OEM / артикулу' },
                // { value: 'brand', label: 'По марке авто' },
            ],
            isCityPopupShown: false,
            USER_TYPES,
        }),
        beforeMount() {
            this.initSearchType();
        },
        mounted() {
            if (data.isUserConfirmed) {
                showSuccessMessage({ text: MESSAGES.SUCCESS_REGISTRATION_CONFIRMED });
            }

            if (!this.isCitySelected) {
                setTimeout(() => {
                    this.isCityPopupShown = true;
                }, 3000);
            }
        },
        methods: {
            ...mapActions([VUEX_ACTIONS.REMOVE_CART_ITEM, VUEX_ACTIONS.CHANGE_CART_PRODUCT_QUANTITY]),

            initSearchType() {
                const initialSearchType = (localStorage.getItem('searchType')) ?? getFromBinding('searchDefaultData.searchType', '');
                const foundOption = this.searchTypeOptions.find((option) => option.value === initialSearchType);
                if (foundOption) this.searchType = foundOption;

                else [this.searchType] = this.searchTypeOptions;
            },

            confirmSelectedCity() {
                this.isCityPopupShown = false;

                if (!this.isCitySelected) apiCitySelect(this.selectedCity);
            },

            selectAnotherCity() {
                this.isCityPopupShown = false;
                UIkit.modal(MODALS.CITY_SELECT).show();
            },

            leaveHandler() {
                console.log('kek');
            },

            onSubmit(event) {
                localStorage.setItem('searchType', this.searchType.value );
                if (this.searchType.value === 'vin') {
                    this.vinSearch(this.searchString);
                } else {
                    event.currentTarget.submit();
                }
            },

        },
        watch: {
            isCityPopupShown(val) {
                document.documentElement.dataset.scrollBlockMobile = `${val}`;
                document.body.classList.toggle('cityPopupShown', val);

                // FIXME: почините верстку, это пи**ц господа верстальщики, бекдропы вне потока и тупо разбросаны по верстке!
                if (val) {
                    const backdrop = document.querySelector('[data-city-popup]');
                    if (backdrop !== null) {
                        backdrop.addEventListener(
                            'click',
                            () => {
                                this.isCityPopupShown = false;
                            },
                            { once: true }
                        );
                    }
                }
            },
        },
    });
