import Vue from 'vue/dist/vue';
import { apiProfileUpdatePassword } from '@scripts/api-methods';
import { form } from '@scripts/mixins/form';
import { formSending } from '@library/forms/form-sending';
import { formValidation } from '@library/forms/validation';
import { VALIDATION_MESSAGES, VALIDATION_RULES } from '@scripts/constants';

const formDataScheme = () => ({
    oldPassword: '',
    password: '',
    confirmPassword: '',
});

export default (el, name) =>
    new Vue({
        el,
        name,
        data: () => ({
            formData: formDataScheme(),
        }),
        mixins: [
            form(),
            formSending(),
            formValidation(
                {
                    current_password: {
                        field: 'formData.oldPassword',
                    },
                    new_password: {
                        field: 'formData.password',
                        rules: {
                            required: true,
                            ...VALIDATION_RULES.PASSWORD,
                        },
                    },
                    new_password_confirmation: {
                        field: 'formData.confirmPassword',
                        rules: {
                            ...VALIDATION_RULES.CONFIRM_PASSWORD,
                        },
                    },
                },
                VALIDATION_MESSAGES
            ),
        ],
        methods: {
            clearForm() {
                this.formData = formDataScheme();
            },

            sendRequest() {
                return this.sendForm(
                    () =>
                        apiProfileUpdatePassword({
                            current_password: this.formData.oldPassword || undefined,
                            new_password: this.formData.password,
                            new_password_confirmation: this.formData.confirmPassword,
                        }),
                    () => {
                        this.successFormSending();
                        window.location.reload();
                    }
                );
            },
        },
    });
