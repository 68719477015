import { Is } from '../scripts/is';
import { DebounceLoading } from './debounce-loading';
import { get } from 'lodash-es';

export const formSending = ({ useRecaptcha = false } = {}) => {
    // Data
    const data = {
        submitLoading: new DebounceLoading(),
    };

    if (useRecaptcha) {
        data.token = null;
    }

    // Mixins
    let methods = {
        isOptional(value, check, path = '') {
            const resultValue = path !== '' ? get(value, path) : value;
            return value === check ? undefined : resultValue;
        },

        optionalPayload(payload) {
            return Object.keys(payload).reduce((result, key) => {
                if (payload[key] !== undefined) {
                    result[key] = payload[key];
                }
                return result;
            }, {});
        },

        async sendForm(
            apiCallPromise,
            onSuccess = null,
            onError = null,
            {
                useValidation = true,
                checkRecaptcha = useRecaptcha,
                debounceLoading = this.submitLoading,
                scrollToFirstInvalidField = true,
                focusFirstInvalidField = true,
                onClientValidationError = null,
            } = {}
        ) {
            if (debounceLoading.realLoading) {
                return;
            }
            debounceLoading.enableLoading();

            useValidation = useValidation && Is.function(this.validateFields);

            if (useValidation) {
                if (
                    !this.validateFields({
                        scrollToFirstInvalidField,
                        focusFirstInvalidField,
                    })
                ) {
                    if (Is.function(onClientValidationError)) {
                        onClientValidationError();
                    }
                    debounceLoading.disableLoading();
                    return;
                }
            }

            if (checkRecaptcha) {
                if (!(await this.recaptcha())) {
                    debounceLoading.disableLoading();
                    return false;
                }
            }

            return apiCallPromise()
                .then((result) => {
                    if (Is.function(onSuccess)) {
                        onSuccess(result);
                    } else if (Is.function(this.successFormSending)) {
                        this.successFormSending(result);
                    }
                })
                .catch((error) => {
                    if (Is.function(onError)) {
                        onError(error, {
                            scrollToFirstInvalidField,
                            focusFirstInvalidField,
                        });
                    } else if (Is.function(this.failedFormSending)) {
                        this.failedFormSending(error, {
                            scrollToFirstInvalidField,
                            focusFirstInvalidField,
                        });
                    }
                })
                .finally(() => {
                    debounceLoading.disableLoading();
                });
        },
    };

    if (useRecaptcha) {
        methods = {
            ...methods,
            async recaptcha() {
                try {
                    await this.$recaptchaLoaded();
                    this.token = await this.$recaptcha('login');
                } catch (error) {
                    if (Is.function(this.onFailedRecaptcha)) {
                        this.onFailedRecaptcha(error);
                    }
                    return false;
                }
                return true;
            },
        };
    }

    return {
        data,
        methods,
    };
};
