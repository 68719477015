import Vue from 'vue/dist/vue';
import Dropdown from '../dropdown/dropdown.vue';
import RegisteredDetail from '../registered-detail/registered-detail.vue';

export default (el, name, data) =>
    new Vue({
        el,
        name,
        components: {
            Dropdown,
            RegisteredDetail,
        },
        data: () => ({
            details: [],
            filteredDetails: [],
            dropdownOptions: {
                status: [],
                vendorCode: [],
                name: [],
            },
            selectedFilters: {
                status: null,
                vendorCode: null,
                name: null,
            },
            binding: data,
        }),
        beforeMount() {
            this.details = this.binding.details;
            this.filteredDetails = this.details;
            // this.dropdownOptions.status = this.getFilterOptions('status');
        },
        methods: {
            // getFilterOptions(propertyName) {
            //     const optionsObj = {};
            //     this.filteredDetails.forEach((item) => {
            //         const option = item[propertyName];
            //         const value = option.id !== undefined ? option.id : option;
            //
            //         if (optionsObj[value] === undefined) {
            //             optionsObj[value] =
            //                 option.name !== undefined ? option.name : option;
            //         }
            //     });
            //
            //     return Object.keys(optionsObj).reduce((acc, key) => {
            //         acc.push({
            //             value: key,
            //             label: optionsObj[key],
            //         });
            //
            //         return acc;
            //     }, []);
            // },
        },
    });
