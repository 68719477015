<template>
    <vue-select
        :class="{
            'vs--invalid': isInvalid,
            'vs--has-options': hasNextPage,
        }"
        :options="options"
        :label="labelProperty"
        :filterable="!useInfiniteScroll"
        :filter="filter"
        :clearable="false"
        :reduce="reduce"
        :selectable="selectable"
        :multiple="multiple"
        :placeholder="placeholder"
        :input-id="inputId"
        :value="value"
        :disabled="disabled"
        @search="(query) => onSearchDebounce(query)"
        @search:blur="$emit('search-blur', $event)"
        @open="onOpen"
        @close="onClose"
        @input="$emit('input', $event)"
    >
        <template #open-indicator="{ attributes }">
            <span v-bind="attributes"></span>
        </template>
        <template #list-footer>
            <li ref="load" class="vs__loader" v-show="hasNextPage || loading">
                <div class="Loader Loader--dropdown">
                    <svg
                        class="Loader__spinner"
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle class="Loader__circle" cx="50" cy="50" r="45" />
                    </svg>
                </div>
            </li>
        </template>
        <template #no-options="{ search, searching, loading }">
            <span>Результатов не найдено</span>
        </template>
    </vue-select>
</template>

<script>
import VueSelect from 'vue-select';
import InlineSvg from 'vue-inline-svg';

export default {
    props: {
        options: {
            type: Array,
            required: true,
        },
        isInvalid: {
            type: Boolean,
        },
        withoutSearch: {
            type: Boolean,
            default: false,
        },
        filter: {
            type: Function,
        },
        placeholder: {
            type: String,
        },
        labelProperty: {
            type: String,
        },
        multiple: {
            type: Boolean,
        },
        inputId: {
            type: String,
        },
        ajaxLoadingOptions: {
            type: Boolean,
        },
        useInfiniteScroll: {
            type: Boolean,
        },
        hideActions: {
            type: Boolean,
            required: false,
            default: false,
        },
        selectable: {
            type: Function,
        },
        reduce: {
            type: Function,
        },
        value: {},
        disabled: {
            type: Boolean,
            default: false,
        },
        stopAutoSelect: {
            type: Boolean,
            default: false,
        },
        updateTrigger: {
            type: Object,
        },
    },
    components: {
        VueSelect,
        InlineSvg,
    },
    data() {
        return {
            observer: this.useInfiniteScroll
                ? new IntersectionObserver(this.infiniteScroll)
                : null,
            search: '',
            page: 1,
            pageSize: undefined,
            total: undefined,
            debounceTimeout: null,
            loading: false,
        };
    },

    computed: {
        allOptionsAlreadyLoaded() {
            return !this.useInfiniteScroll && this.options.length !== 0;
        },

        hasNextPage() {
            if (
                !this.useInfiniteScroll ||
                !this.ajaxLoadingOptions ||
                this.allOptionsAlreadyLoaded
            ) {
                return false;
            }

            if (this.total === undefined) {
                return true;
            }

            return this.total > this.page * this.pageSize;
        },
    },

    methods: {
        async onOpen() {
            this.page = 1;
            this.search = '';

            if (!this.ajaxLoadingOptions || this.allOptionsAlreadyLoaded) {
                return;
            }

            this.$emit('load-options', {
                page: this.page,
                query: this.search,
                setLoading: (value) => {
                    this.loading = Boolean(value);
                },
                success: async (result) => {
                    this.total = result.total;
                    this.pageSize = result.pageSize;

                    if (this.useInfiniteScroll && this.hasNextPage) {
                        await this.$nextTick();
                        this.observer.observe(this.$refs['load']);
                    }
                },
            });
        },

        onClose() {
            if (this.useInfiniteScroll) {
                this.observer.disconnect();
                this.$emit('clear-options');
            }
        },

        onSearchDebounce(query) {
            clearTimeout(this.debounceTimeout);
            this.debounceTimeout = setTimeout(() => {
                this.onSearch(query);
            }, 400);
        },

        onSearch(query) {
            if (this.search === query) {
                clearTimeout(this.debounceTimeout);
                return;
            }
            if (!this.withoutSearch) {
                this.search = query;
                this.page = 1;

                this.$emit('load-options', {
                    query: this.search,
                    page: this.page,
                    success: (result) => {
                        this.total = result.total;
                        this.pageSize = result.pageSize;
                    },
                    setLoading: (value) => {
                        this.loading = Boolean(value);
                    },
                });
            }
        },

        async infiniteScroll([{ isIntersecting, target }]) {
            if (!isIntersecting) {
                return;
            }

            const ul = target.offsetParent;
            const scrollTop = target.offsetParent.scrollTop;
            this.page++;

            this.$emit('load-options', {
                page: this.page,
                query: this.search,
                success: async (result) => {
                    this.total = result.total;
                    this.pageSize = result.pageSize;

                    await this.$nextTick();
                    ul.scrollTop = scrollTop;
                },
                setLoading: (value) => {
                    this.loading = Boolean(value);
                },
            });
        },
    },
    watch: {
        updateTrigger(val) {
            this.onOpen();
        },

        options(val) {
            if (!this.stopAutoSelect) {
                if (val.length === 1) {
                    this.$emit('input', this.options[0]);
                }

                // cases with empty first element
                if (val.length === 2 && val[0].value === null) {
                    this.$emit('input', this.options[1]);
                }
            }
        },
    },
};
</script>
