var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: ["Counter", _vm.modeClass] }, [
    _vm.showButtons
      ? _c(
          "button",
          {
            staticClass: "Counter__button Button Button--empty",
            attrs: { type: "button" },
            on: { click: _vm.decreaseQuantity },
          },
          [
            _c(
              "span",
              { staticClass: "Button__icon" },
              [_c("inline-svg", { attrs: { src: "/assets/icons/minus.svg" } })],
              1
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "Counter__inputWrapper" }, [
      _c("input", {
        directives: [
          {
            name: "mask",
            rawName: "v-mask",
            value: _vm.masks.digits,
            expression: "masks.digits",
          },
        ],
        staticClass: "Counter__input",
        class: { "Counter__input--unavailable": !_vm.isAvailable },
        attrs: { inputmode: "numeric", "aria-label": "Количество" },
        domProps: { value: _vm.value },
        on: {
          input: function ($event) {
            return _vm.onInputQuantity($event)
          },
          keypress: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.setQuantity($event)
          },
          blur: function ($event) {
            return _vm.setQuantity($event)
          },
        },
      }),
    ]),
    _vm._v(" "),
    _vm.showButtons
      ? _c(
          "button",
          {
            staticClass: "Counter__button Button Button--empty",
            attrs: { type: "button" },
            on: { click: _vm.increaseQuantity },
          },
          [
            _c(
              "span",
              { staticClass: "Button__icon" },
              [_c("inline-svg", { attrs: { src: "/assets/icons/plus.svg" } })],
              1
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }