'use strict'

import SymbolMap from 'currency-symbol-map/map'
import { ArgumentError } from './errors'
import { assertNumeric, assertString } from './assert'

/**
 * Currency formatter (for Russia standard by defaults)
 * @param value
 * @param digits {int}
 * @param separator {string}
 * @param removeTrailingZeros {boolean}
 * @returns {string}
 */
export function currencyFormatter(value, digits = 2, separator = '&thinsp;', removeTrailingZeros = true) {
	const val = parseFloat(value)
	const formatted = val.toFixed(digits).replace(/(\d)(?=(\d{3})+(\.|$))/g, `$1${separator}`).replace(/\./g, ',')
	return val * 100 % 100 === 0 && removeTrailingZeros ? formatted.replace(/,\d{2}/, '') : formatted
}

/** @enum {string} */
export const KnownCurrencyFormat = {
	JPY: 'JPY',
	CNY: 'CNY',
	USD: 'USD',
	EUR: 'EUR',
	RUB: 'RUB',
}

export function placeCurrencyGlyph(code, string) {
	const glyph = SymbolMap[code]
	switch (code) {
		case KnownCurrencyFormat.JPY:
		case KnownCurrencyFormat.CNY:
		case KnownCurrencyFormat.EUR:
		case KnownCurrencyFormat.USD:
			return `${glyph}&nbsp;${string}`
		case KnownCurrencyFormat.RUB:
			return `${string}&nbsp;${glyph}`
		default:
			return string
	}
}

export function checkCurrencyCode(code) {
	assertString(code)
	if (!/[A-Z]{3}/.test(code)) throw new ArgumentError(`Code must comply with ISO 4217`)
}

export function displayCurrency(code, value, digits = 2, separator = '&thinsp;', removeTrailingZeros = true) {
	checkCurrencyCode(code)
	assertNumeric(value)
	return placeCurrencyGlyph(code, currencyFormatter(value, digits, separator, removeTrailingZeros))
}
