import Vue from 'vue/dist/vue';

export default (el, name, data) =>
    new Vue({
        el,
        name,
        data,
        methods: {
            turnOffSkeleton() {
                const skeleton = document.querySelector('[data-article-video]');
                if (skeleton === null) return;
                skeleton.classList.add('video-off');
            },
        },
        mounted() {
            this.turnOffSkeleton();
        },
    });
