import Vue from 'vue/dist/vue';
import { mapState } from 'vuex';
import store from '@scripts/store';
import { formSending } from '@library/forms/form-sending';
import { formValidation } from '@library/forms/validation';
import { showSuccessMessage } from '@library/uikit/notifications';
import { form } from '@scripts/mixins/form';
import { VALIDATION_MESSAGES, MESSAGES, MODALS } from '@scripts/constants';
import { apiSubmitInstructionComment } from '@scripts/api-methods';
import UIkit from '../../../vendor/uikit/uikit';

const formDataScheme = () => ({
    name: '',
    text: '',
});

export default (el, name, data) =>
    new Vue({
        el,
        name,
        store,
        data: () => ({
            formData: formDataScheme(),
            instructionId: null,
            binding: data,
        }),
        mixins: [
            form(),
            formSending({ useRecaptcha: true }),
            formValidation(
                {
                    body: {
                        field: 'formData.text',
                        rules: {
                            required: true,
                            minLength: 3,
                            maxLength: 2000,
                        },
                    },
                },
                VALIDATION_MESSAGES
            ),
        ],
        computed: {
            ...mapState(['user']),
        },
        beforeMount() {
            this.formData.name = this.user.name;
            this.instructionId = this.binding.instructionId;
        },
        methods: {
            clearForm() {
                this.formData = formDataScheme();
            },

            sendRequest() {
                if (!this.user.isLoggedIn) {
                    UIkit.modal(MODALS.LOGIN).show();
                    return null;
                }

                return this.sendForm(
                    () =>
                        apiSubmitInstructionComment(
                            this.optionalPayload({
                                instruction_id: this.instructionId,
                                body: this.formData.text,
                                author: this.isOptional(this.formData.name, ''),
                                captcha_token: this.token,
                            })
                        ),
                    () => {
                        showSuccessMessage({ text: MESSAGES.SUCCESS_COMMENT_SUBMIT });
                        this.clearForm();
                    }
                );
            },
        },
    });
