var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("article", { staticClass: "AddressItem" }, [
    _c("span", {
      staticClass: "AddressItem__houseAddress",
      domProps: { innerHTML: _vm._s(_vm.houseAddress) },
    }),
    _vm._v(" "),
    _c("span", {
      staticClass: "AddressItem__cityAddress",
      domProps: { innerHTML: _vm._s(_vm.cityAddress) },
    }),
    _vm._v(" "),
    _c(
      "div",
      { ref: "dropdown-menu", staticClass: "AddressItem__options DropMenu" },
      [
        _c(
          "button",
          {
            staticClass: "Button Button--empty DropMenu__contextMenuButton",
            attrs: { type: "button" },
            on: { click: _vm.openDropMenu },
          },
          [
            _c(
              "span",
              { staticClass: "Button__icon" },
              [
                _c("inline-svg", {
                  attrs: { src: "/assets/icons/options.svg" },
                }),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c("transition", { attrs: { name: "slide-fade" } }, [
          _c(
            "ul",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isDropMenuOpen,
                  expression: "isDropMenuOpen",
                },
              ],
              staticClass: "DropMenu__list",
            },
            [
              _c("li", { staticClass: "DropMenu__item" }, [
                _c(
                  "button",
                  {
                    staticClass: "Button Button--link DropMenu__itemButton",
                    attrs: { type: "button" },
                    on: { click: _vm.edit },
                  },
                  [
                    _vm._v(
                      "\n                        Изменить\n                    "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "DropMenu__item" }, [
                _c(
                  "button",
                  {
                    staticClass: "Button Button--link DropMenu__itemButton",
                    attrs: { type: "button" },
                    on: { click: _vm.remove },
                  },
                  [
                    _vm._v(
                      "\n                        Удалить\n                    "
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }