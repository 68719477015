import Vue from 'vue/dist/vue';
import { mapMutations, mapState } from 'vuex';

import { showErrorMessage } from '@library/uikit/notifications';
import { formSending } from '@library/forms/form-sending';
import { formValidation } from '@library/forms/validation';

import { VALIDATION_MESSAGES, VUEX_MUTATIONS, MESSAGES } from '@scripts/constants';
import { mapCityForDropdown, mapGetCitiesResponse } from '@scripts/mappings';
import { apiCitySelect, apiGetCities } from '@scripts/api-methods';
import { modalMixin } from '@scripts/mixins/modal';
import { form } from '@scripts/mixins/form';
import store from '@scripts/store';
import Dropdown from "../../dropdown/dropdown.vue";

const formDataScheme = () => ({
    city: null,
});

export default (el, name) =>
    new Vue({
        el,
        name,
        store,
        components: {
            Dropdown,
        },
        data: () => ({
            loading: false,
            searchedCities: [],
            formData: formDataScheme(),
            dropdownOptions: {
                cities: [],
            },
        }),
        computed: {
            ...mapState(['selectedCity', 'citiesWithWarehouses']),
        },
        mixins: [
            form(),
            formSending({ useRecaptcha: true }),
            formValidation(
                {
                    city: {
                        field: 'formData.city',
                    },
                },
            ),
            modalMixin('#city-select-modal'),
        ],
        beforeMount() {
            this.city = mapCityForDropdown(this.selectedCity);
        },
        methods: {
            ...mapMutations([VUEX_MUTATIONS.SET_SELECTED_CITY]),

            onSelectDefault(value) {
                const selectedCity = this.citiesWithWarehouses.find((item) => item.city === value);

                this.setSelectedCity(selectedCity);
                this.applyCitySelection(selectedCity);
            },

            onSelectDropdown(value) {
                const selectedCity = this.searchedCities.find((item) => item.city === value);

                this.setSelectedCity(selectedCity);
                this.applyCitySelection(selectedCity);
            },

            loadCities(settings) {
                if (!settings.query || settings.query.length === 0) return;

                settings.setLoading(true);

                this.sendForm(
                    () =>
                        apiGetCities({
                            query: settings.query,
                            results_count: 4,
                            country: "Россия",
                            captcha_token: this.token,
                        }),
                    (response) => {
                        const { data, options } = mapGetCitiesResponse(response)

                        this.searchedCities = data;
                        this.dropdownOptions.cities = options;
                    },
                    () => {
                        showErrorMessage({
                            text: MESSAGES.CITIES_LOAD_ERROR,
                        });
                    },
                    {
                        useValidation: false,
                    }
                ).finally(() => settings.setLoading(false));
            },

            applyCitySelection(city) {
                this.loading = true;
                return this.sendForm(
                    () => apiCitySelect(city),
                    () => {
                        this.closeModal();

                        window.location.reload();
                    },
                    () => {
                        this.loading = false;

                        showErrorMessage({ text: VALIDATION_MESSAGES.SERVER_ERROR });
                    },
                    {
                        useValidation: false,
                    }
                );
            },
        },
        watch: {
            // Apply city from dropdown selection
            'formData.city': function formDataCity(currentSelected) {
                if (currentSelected && currentSelected.value) {
                    this.onSelectDropdown(currentSelected.value);
                }
            }
        }
    });
