import Vue from 'vue/dist/vue';
import { formSending } from '@library/forms/form-sending';
import { formValidation } from '@library/forms/validation';
import { form } from '@scripts/mixins/form';
import { GLOBAL_EVENTS, VALIDATION_MESSAGES, MESSAGES, MODALS } from '@scripts/constants';
import { showErrorMessage } from '@library/uikit/notifications';
import eventBus from '@scripts/event-bus';
import Dropdown from '../../dropdown/dropdown.vue';
import { apiCreateUserAddress, apiGetCities, apiGetCountries, apiGetStreets, apiUpdateUserAddress } from '../../../scripts/api-methods';
import UIkit from '../../../vendor/uikit/uikit';

import VueSuggestions from 'vue-suggestions';

const formDataScheme = () => ({
    country: 'Россия',
    city: null,
    street: null,
    postalCode: '',
    building: '',
    flat: '',
});

const FORM_TYPES = {
    ADD: 'add',
    EDIT: 'edit',
};

const getFormTitles = (type) =>
    ({
        [FORM_TYPES.ADD]: {
            heading: 'Добавление адреса',
            buttonText: 'Добавить',
        },
        [FORM_TYPES.EDIT]: {
            heading: 'Изменить адрес',
            buttonText: 'Сохранить',
        },
    }[type]);
let globalSuggestion = {};

export default (el, name) =>
    new Vue({
        el,
        name,
        components: {
            Dropdown,
            VueSuggestions,
        },
        data: () => ({
            titles: getFormTitles(FORM_TYPES.ADD),
            formData: formDataScheme(),
            dropdownOptions: {
                countries: [],
                cities: [],
                streets: [],
            },
            city: '',
            coordinates: {
                latitude: '',
                longitude: ''
            },
            suggestionOptions: {
                // @see https://confluence.hflabs.ru/pages/viewpage.action?pageId=207454318
                token: '866fa4f40acd4756d401d382370292364c25757d',
                type: "ADDRESS",
                scrollOnFocus: false,
                triggerSelectOnBlur: false,
                triggerSelectOnEnter: true,
                addon: 'none',
                // @see https://confluence.hflabs.ru/pages/viewpage.action?pageId=207454320
                onSelect (suggestion) {
                    // this.setDataFromSuggestion(suggestion);
                    if (suggestion.data && suggestion.data !== undefined){
                        globalSuggestion = suggestion;
                        // globalSuggestion.suggestion = suggestion;
                    }
                }
            },
        }),
        mixins: [
            form(),
            formSending({ useRecaptcha: true }),
            formValidation(
                {
                    country: {
                        field: 'formData.country',
                        rules: {
                            required: true,
                        },
                    },
                    city: {
                        field: 'formData.city',
                        rules: {
                            required: true,
                        },
                    },
                    postalCode: {
                        field: 'formData.postalCode',
                        rules: {
                            required: true,
                        },
                    },
                    street: {
                        field: 'formData.street',
                        rules: {
                            required: true,
                        },
                    },
                    building: {
                        field: 'formData.building',
                        rules: {
                            required: true,
                        },
                    },
                },
                VALIDATION_MESSAGES
            ),
        ],
        beforeMount() {
            UIkit.util.on(MODALS.USER_ADDRESS, 'hide', () => {
                this.clearForm();
            });

            eventBus.$on(GLOBAL_EVENTS.OPEN_EDIT_ADDRESS_MODAL, (data) => {
                this.formData = {
                    ...this.formData,
                    ...data.address,
                };
                this.isEdit = true;
                this.titles = getFormTitles(FORM_TYPES.EDIT);
            });
        },
        methods: {
            clearForm() {
                this.formData = formDataScheme();
            },

            onModalHide() {
                this.clearForm();
                this.titles = getFormTitles(FORM_TYPES.ADD);
            },

            selectFirstCountry() {
                if (!this.formData.country) [this.formData.country] = this.dropdownOptions.countries;
            },

            selectFirstCity() {
                if (!this.formData.city) [this.formData.city] = this.dropdownOptions.cities;
            },

            selectFirstStreet() {
                if (!this.formData.street) [this.formData.street] = this.dropdownOptions.streets;
            },

            loadCountries(settings) {
                if (settings.query && settings.query.length > 0) {
                    settings.setLoading(true);

                    this.sendForm(
                        () =>
                            apiGetCountries({
                                query: settings.query,
                                results_count: 4,
                                captcha_token: this.token,
                            }),
                        (result) => {
                            this.dropdownOptions.countries = result.items.map((item) => item.title);
                        },
                        () => {
                            showErrorMessage({
                                text: MESSAGES.COUNTRIES_LOAD_ERROR,
                            });
                        },
                        {
                            useValidation: false,
                        }
                    ).finally(() => settings.setLoading(false));
                }
            },

            loadCities(settings) {
                if (settings.query && settings.query.length > 0 && this.formData.country) {
                    settings.setLoading(true);

                    this.sendForm(
                        () =>
                            apiGetCities({
                                query: settings.query,
                                results_count: 4,
                                country: this.formData.country,
                                captcha_token: this.token,
                            }),
                        (result) => {
                            this.dropdownOptions.cities = result.items.map((item) => ({
                                label: item.title,
                                value: item.fias_id,
                            }));
                        },
                        () => {
                            showErrorMessage({
                                text: MESSAGES.CITIES_LOAD_ERROR,
                            });
                        },
                        {
                            useValidation: false,
                        }
                    ).finally(() => settings.setLoading(false));
                }
            },

            loadStreets(settings) {
                if (settings.query && settings.query.length > 0 && this.formData.country && this.formData.city) {
                    settings.setLoading(true);

                    this.sendForm(
                        () =>
                            apiGetStreets({
                                query: settings.query,
                                results_count: 4,
                                city_fias_id: this.formData.city.value,
                                captcha_token: this.token,
                            }),
                        (result) => {
                            console.log(result);
                            this.dropdownOptions.streets = result.items.map((item) => ({
                                label: item.title,
                                value: item,
                            }));
                        },
                        () => {
                            showErrorMessage({
                                text: MESSAGES.STREETS_LOAD_ERROR,
                            });
                        },
                        {
                            useValidation: false,
                        }
                    ).finally(() => settings.setLoading(false));
                }
            },

            sendRequest() {
                const method = this.isEdit ? apiUpdateUserAddress : apiCreateUserAddress;
                let glData = (globalSuggestion.data !== undefined) ? globalSuggestion.data : null;
                if (glData){
                    glData.city = (glData.city !== undefined) ? glData.city : null;
                    if (!glData.city){
                        glData.city = (glData.settlement !== undefined) ? glData.settlement : null;
                    }
                    glData.city_fias_id = (glData.city_fias_id !== undefined) ? glData.city_fias_id : null;
                }else{
                    glData = {
                        city: null,
                        city_fias_id: null,
                    }
                }
                return this.sendForm(
                    () =>
                        method({
                            country: this.formData.country,
                            city: this.formData.city.label ?? glData.city,
                            city_fias_id: this.formData.city.value ?? glData.city_fias_id,
                            street: this.formData.street.label ?? this.formData.street,
                            building: this.formData.building,
                            flat: this.formData.flat || null,
                            postal_code: this.formData.postalCode,
                            latitude: (this.formData.street.value)?this.formData.street.value.latitude : 0,
                            longitude: (this.formData.street.value)?this.formData.street.value.longitude : 0,
                            user_address_id: this.formData.id,
                        }),
                    () => {
                        this.successFormSending();
                        UIkit.modal(MODALS.USER_ADDRESS).hide();

                        setTimeout(() => {
                            document.location.reload();
                        }, 1000);
                    }
                );
            },

            setDataFromSuggestion( suggestion ){
                if (suggestion.data && suggestion.data !== undefined){
                    // if (suggestion.data.city){
                    //     this.formData.city = {
                            // fias_id:    suggestion.data.city_fias_id ?? null,
                            // country:    suggestion.data.country ?? null,
                            // region:     suggestion.data.region ?? null,
                            // title:      suggestion.data.city ?? null,
                            // latitude:   suggestion.data.geo_lat ?? 0,
                            // longitude:	suggestion.data.geo_lon ?? 0
                        // }
                    // }

                    // this.formData.city = {
                    //     label:  suggestion.data.city ?? null,
                    //     value:  suggestion.data.city_fias_id ?? null,
                    // };
                    // this.dropdownOptions.cities = [this.formData.city];

                    //timeout - ы нужны, тк при изменении города/улицы происходит обнуление другиз полей
                    setTimeout(() => {
                        this.formData.city = suggestion.data.city ?? (suggestion.data.settlement ?? null);
                    }, 300);
                    setTimeout(() => {
                        this.formData.street = suggestion.data.street_with_type ?? (suggestion.data.settlement_with_type ?? null);
                    }, 300);
                    setTimeout(() => {
                        this.formData.postalCode = suggestion.data.postal_code ?? null;
                    }, 300);
                    setTimeout(() => {
                        this.formData.building = suggestion.data.house ?? null;
                    }, 300);
                    setTimeout(() => {
                        this.formData.flat = suggestion.data.flat ?? null;
                    }, 300);

                }
            }
        },
        watch: {
            'formData.country': function (val, oldVal) {
                if (oldVal) {
                    this.formData.city = null;
                    this.formData.street = null;
                    this.formData.postalCode = '';
                    this.formData.building = '';
                    this.formData.flat = '';
                }
            },
            'formData.city': function (val, oldVal) {
                if (oldVal) {
                    this.formData.street = null;
                    this.formData.postalCode = '';
                    this.formData.building = '';
                    this.formData.flat = '';
                }
            },
            'formData.street': function (val, oldVal) {
                if (oldVal) {
                    this.formData.postalCode = '';
                    this.formData.building = '';
                    this.formData.flat = '';
                }
            },
            'city': function (val, oldVal) {
                // if (oldVal) {
                    this.setDataFromSuggestion(globalSuggestion);
                // }
            },
        },
    });
