<template>
    <div :class="['Counter', modeClass]">
        <button
            v-if="showButtons"
            type="button"
            class="Counter__button Button Button--empty"
            @click="decreaseQuantity"
        >
            <span class="Button__icon">
                <inline-svg src="/assets/icons/minus.svg"></inline-svg>
            </span>
        </button>
        <div class="Counter__inputWrapper">
            <input
                inputmode="numeric"
                class="Counter__input"
                :class="{ 'Counter__input--unavailable': !isAvailable }"
                aria-label="Количество"
                :value="value"
                v-mask="masks.digits"
                @input="onInputQuantity($event)"
                @keypress.enter="setQuantity($event)"
                @blur="setQuantity($event)"
            />
        </div>
        <button
            v-if="showButtons"
            type="button"
            class="Counter__button Button Button--empty"
            @click="increaseQuantity"
        >
            <span class="Button__icon">
                <inline-svg src="/assets/icons/plus.svg"></inline-svg>
            </span>
        </button>
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import { mask } from 'vue-the-mask';
import { showSuccessMessage } from '@library/uikit/notifications';
import { MESSAGES } from '@scripts/constants';

export default {
    props: {
        mode: {
            type: String,
        },
        value: {
            type: Number,
            default: 0,
        },
        maxItemQuantity: {
            type: Number,
            default: 99,
        },
        mask: {
            type: String,
            default: '##',
        },
        quantityInPackage: {
            type: Number,
            required: true,
        },
        isUserWholesaler: {
            type: Boolean,
            required: true,
        },
        isAvailable: {
            type: Boolean,
            default: true,
        },
        showButtons: {
            type: Boolean,
            default: true,
        },
    },
    directives: {
        mask,
    },
    data() {
        return {
            masks: {
                digits: this.mask,
            },
        };
    },
    mounted() {
        this.$nextTick(() => { this.fakeQuantity = this.quantity; });
    },
    components: {
        InlineSvg,
    },
    computed: {
        modeClass() {
            return this.mode ? `Counter--${this.mode}` : '';
        },
        step() {
            return this.isUserWholesaler ? this.quantityInPackage : 1;
        },
    },
    methods: {
        onInputQuantity(event) {
            const val = event.currentTarget.value;
            event.currentTarget.value = val ? Number(val) : 0;
        },

        setQuantity(event) {
            const input = event.target;
            let newValue = Number(input.value);

            if (newValue === this.value) {
                return;
            }
            if (newValue > this.maxItemQuantity) {
                newValue = this.maxItemQuantity;
            }
            if (newValue < 0) {
                newValue = 0;
            }
            if (
                this.isUserWholesaler &&
                newValue % this.quantityInPackage !== 0
            ) {
                let correctValue =
                    Math.ceil(newValue / this.quantityInPackage) *
                    this.quantityInPackage;

                if (correctValue > this.maxItemQuantity) {
                    correctValue -= this.quantityInPackage;
                }

                newValue = correctValue;
                input.value = correctValue;

                showSuccessMessage({
                    text: MESSAGES.PRODUCT_QUANTITY_ROUNDED_TEXT,
                    title: MESSAGES.PRODUCT_QUANTITY_ROUNDED_TITLE,
                });
            }

            this.changeQuantity(newValue);
        },

        decreaseQuantity() {
            if (this.value === 0) {
                // dont allow to decrease to -1
                return;
            }

            this.changeQuantity(this.value - this.step);
        },

        increaseQuantity() {
            const resultValue = this.value + this.step;

            if (resultValue > this.maxItemQuantity) {
                return;
            }

            this.changeQuantity(resultValue);
        },

        changeQuantity(newValue) {
            this.$emit('change', { quantity: newValue });
        },
    },
};
</script>
