import Vue from 'vue/dist/vue';
import eventBus from '@scripts/event-bus';
import { GLOBAL_EVENTS, MODALS, MESSAGES } from '@scripts/constants';
import { showSuccessMessage } from "@library/uikit/notifications";
import UIkit from '../../../vendor/uikit/uikit';

const PATHNAME_PAGES = {
    PRODUCT: '/product',
    CATALOG: '/catalog',
};

const SINGS = {
    QUERY: '?',
    AND: '&'
};

export default (el, name) =>
    new Vue({
        el,
        name,
        data: () => ({
            vin: '',
            cars: [],
            disabledButtonApply: false,
        }),
        beforeMount() {
            eventBus.$on(GLOBAL_EVENTS.OPEN_VIN_RESULT, (data) => {
                this.vin = data.vin;
                this.cars = data.cars;
            });
        },
        computed: {
            getLocationOrigin() {
                return window.location.origin;
            },

            getLocationPathname() {
                return window.location.pathname;
            },

            getLocationSearch() {
                return window.location.search;
            },

            getLocationHash() {
                return window.location.hash;
            },

            isCatalogPage() {
                return this.getLocationPathname.includes(PATHNAME_PAGES.CATALOG);
            },
        },
        methods: {
            titleCar(index) {
                const car = this.cars[index];
                if (car.market === '') return car.model;
                return `${car.model}, ${car.market}`;
            },

            clearData(openQueryModal) {
                this.disabledButtonApply = false;
                UIkit.modal(MODALS.VIN_RESULT).hide();

                if (openQueryModal) {
                    UIkit.modal(MODALS.VIN_QUERY).show();
                }
            },

            getVinQueryParameter(sign) {
                return `${sign}vin=${this.vin}`;
            },

            getLocation() {
                const location = this.getLocationOrigin + this.getLocationPathname;
                const locationHash = this.getLocationHash;
                let locationSearch = this.getLocationSearch;
                if (this.isCatalogPage) {
                    const searchWithoutVin = locationSearch.split(`${SINGS.AND}vin`)[0];
                    locationSearch = searchWithoutVin + this.getVinQueryParameter(SINGS.AND);
                } else {
                    locationSearch = this.getVinQueryParameter(SINGS.QUERY);
                }

                return location + locationSearch + locationHash;
            },

            onButtonApplyClick() {
                this.disabledButtonApply = true;
                const newLocationHref = this.getLocation();
                if (window.location.href !== newLocationHref) {
                    window.location.href = newLocationHref;
                } else {
                    window.location.reload();
                }
                showSuccessMessage(({text: MESSAGES.SUCCESS_REDIRECT_VIN_CHECK}));
            },
        },
    });
