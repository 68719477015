var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "CheckoutItemsList" }, [
    _c(
      "ul",
      { staticClass: "CheckoutItemsList__list" },
      _vm._l(_vm.items, function (item) {
        return _c(
          "li",
          { key: item.id, staticClass: "CheckoutItemsList__row" },
          [
            !item.product.is_present_in_rossko
              ? _c(
                  "a",
                  {
                    staticClass: "CheckoutItemsList__pictureLink",
                    attrs: { href: item.product.url },
                  },
                  [
                    _c(
                      "picture",
                      { staticClass: "CheckoutItemsList__picture" },
                      [
                        item.product.image_small
                          ? _c("img", {
                              staticClass: "CheckoutItemsList__image",
                              attrs: {
                                srcset: `${_vm.getImageSize(
                                  item.product.image_small
                                )}, ${_vm.getImageSize(
                                  item.product.image_small,
                                  2
                                )} 2x`,
                                src: _vm.getImageSize(item.product.image_small),
                                alt: item.product.name,
                              },
                            })
                          : _c("inline-svg", {
                              staticClass: "CheckoutItemsList__placeholder",
                              attrs: {
                                src: "/assets/images/image-placeholder.svg",
                              },
                            }),
                      ],
                      1
                    ),
                  ]
                )
              : _c("span", [
                  _c(
                    "picture",
                    { staticClass: "CheckoutItemsList__picture" },
                    [
                      item.product.image_small
                        ? _c("img", {
                            staticClass: "CheckoutItemsList__image",
                            attrs: {
                              srcset: `${_vm.getImageSize(
                                item.product.image_small
                              )}, ${_vm.getImageSize(
                                item.product.image_small,
                                2
                              )} 2x`,
                              src: _vm.getImageSize(item.product.image_small),
                              alt: item.product.name,
                            },
                          })
                        : _c("inline-svg", {
                            staticClass: "CheckoutItemsList__placeholder",
                            attrs: {
                              src: "/assets/images/image-placeholder.svg",
                            },
                          }),
                    ],
                    1
                  ),
                ]),
            _vm._v(" "),
            !item.product.is_present_in_rossko
              ? _c("a", {
                  staticClass: "CheckoutItemsList__productName",
                  attrs: { href: item.product.url },
                  domProps: { innerHTML: _vm._s(item.product.name) },
                })
              : _c("span", {
                  staticClass: "CheckoutItemsList__productName",
                  domProps: { innerHTML: _vm._s(item.product.name) },
                }),
            _vm._v(" "),
            _c("span", {
              staticClass: "CheckoutItemsList__productPackagesCount",
              domProps: {
                innerHTML: _vm._s(
                  `${item.quantity_packages} ${_vm.pluralize(
                    item.quantity_packages,
                    ["упаковка", "упаковки", "упаковок"]
                  )}`
                ),
              },
            }),
            _vm._v(" "),
            item.wholesale
              ? _c("span", {
                  staticClass: "CheckoutItemsList__productPrice",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.formatRoubles(item.wholesale.price_total_wholesale)
                    ),
                  },
                })
              : _c("span", {
                  staticClass: "CheckoutItemsList__productPrice",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.formatRoubles(item.price_total_retail)
                    ),
                  },
                }),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "footer",
      { staticClass: "CheckoutItemsList__row CheckoutItemsList__row--total" },
      [
        _c("span", [_vm._v("Итого:")]),
        _vm._v(" "),
        _c("span", {
          domProps: { innerHTML: _vm._s(_vm.formatRoubles(_vm.total)) },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }