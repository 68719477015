import Vue from 'vue/dist/vue';
import store from '@scripts/store';
import { mapGetters } from 'vuex';
import { VUEX_GETTERS } from '../../scripts/constants';

export default (el, name) =>
    new Vue({
        el,
        name,
        store,
        computed: {
            ...mapGetters([VUEX_GETTERS.SELECTED_CAR, VUEX_GETTERS.IS_CATALOG_FILTERS_SELECTED]),
        },
    });
