'use strict'

export default class Mask {
	constructor(rawMask, formattedMask, rawRule, formattedRule) {
		this.rawRule = rawRule
		this.rawMask = rawMask
		this.formattedMask = formattedMask
		this.formattedRule = formattedRule
	}

	/**
	 * Test value with raw mask
	 * @param {string} value
	 * @returns {boolean}
	 */
	testRaw(value) {
		return this.rawRule.test(value)
	}

	/**
	 * Test value with formatted mask
	 * @param {string} value
	 * @returns {string}
	 */
	testFormatted(value) {
		return this.formattedRule.test(value)
	}

	/**
	 * Format value
	 * @param {string} value
	 * @returns {string|null}
	 */
	format(value) {
		if (!this.rawRule.test(value)) return null
		return value.replace(this.rawRule, this.formattedMask)
	}

	/**
	 * Get formatted raw value
	 * @param {string} value
	 * @returns {string|null}
	 */
	getRawValue(value) {
		if (!this.formattedRule.test(value)) return null
		return value.replace(this.formattedRule, this.rawMask)
	}
}

export const PhoneMask = new Mask(
	'7$2$3$4$5',
	'+7 $2 $3-$4-$5',
	/^(\+7|7|8)(\d{3})(\d{3})(\d{2})(\d{2})/i,
	/(\+7|8) (\d{3}) (\d{3})-(\d{2})-(\d{2})/i,
)

export const PhoneMaskWithParentheses = new Mask(
	'7$2$3$4$5',
	'+7 ($2) $3-$4-$5',
	/^(\+7|7|8)(\d{3})(\d{3})(\d{2})(\d{2})/i,
	/(\+7|8) \((\d{3})\) (\d{3})-(\d{2})-(\d{2})/i,
)

export const InnMask = new Mask(
	'$1$2$3$4',
	'$1$2$3$4',
	/^(\d{3})(\d{3})(\d{3})(\d{1}|\d{3})$/i,
	/^(\d{3})(\d{3})(\d{3})(\d{1}|\d{3})$/i,
)

export const EmailMask = new Mask(
	'$1@$2.$3',
	'$1@$2.$3',
	/^([^@]+)@([^@]+)\.([^@]+)$/i,
	/^([^@]+)@([^@]+)\.([^@]+)$/i,
)
