import UIkit from '../../vendor/uikit/uikit';

export const modalMixin = (target) => ({
    data: () => ({ modalRef: null }),
    mounted() {
        const element = document.querySelector(target);
        if (element) this.modalRef = UIkit.modal(element);
    },
    methods: {
        openModal() {
            if (this.modalRef) this.modalRef.show();
        },
        closeModal() {
            if (this.modalRef) this.modalRef.hide();
        },
    }
})
