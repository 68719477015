<template>
    <li>
        <div class='ProductSchema__accordionTitle uk-accordion-title'>
            <span>{{ category }} > {{ name }}</span>
        </div>

        <div
            class='ProductSchema__accordionContent uk-accordion-content'
            @mouseenter='handleMouseEnterContent'
        >
            <section class='ProductSchema__schemaWrapper'>
                <div
                    class='ProductSchema__schemaContainer'
                    @wheel='onWheelSchemaContainer'
                >
                    <div class='ProductSchema__schemaDrag' ref='schemaDrag'>
                        <div class='ProductSchema__schemaControl'>
                            <img class='ProductSchema__image' :src='image' alt='' ref='schema' draggable='false'/>
                            <div
                                class='ProductSchema__imagePin'
                                :class="{
                                    'ProductSchema__imagePin--visited': visitedDetails.includes(item.code),
                                    'ProductSchema__imagePin--hover': hoveredDetail === item.code,
                                    'ProductSchema__imagePin--selected': selectedDetail === item.code,
                                    'ProductSchema__imagePin--vendorCode': productVendorCode === item.product.vendorCode,
                                }"
                                v-if='imageNaturalSize && recalculated'
                                v-for='(item, index) in filterDetailsForPins'
                                :key='index'
                                :style='calculatePinPosition(item.selectionCoordinates)'
                                @mouseenter='handleMouseEnter(item.code)'
                                @mouseleave='handleMouseLeave'
                                @click='handleClickPin(item.code, item.oem)'
                            />
                        </div>
                    </div>
                </div>

                <div class='ProductSchema__resetButtonWrapper'>
                    <button
                        type='button'
                        class='Button Button--small Button--blackOutline'
                        @click='resetSchema'
                    >
                        Исходный размер
                    </button>
                </div>
            </section>
            <section class='ProductSchema__itemsContainer'>
                <div class='ProductSchema__itemsHeader ProductSchema__itemsGrid'>
                    <span class='ProductSchema__itemHeader'>№</span>
                    <span class='ProductSchema__itemHeader'>OEM</span>
                    <span class='ProductSchema__itemHeader'>Название</span>
                </div>
                <ul class='ProductSchema__itemsWrapper' ref='detailsContainer'>
                    <li
                        class='ProductSchema__item'
                        :class="{
                            'ProductSchema__item--hover': hoveredDetail === item.code,
                            'ProductSchema__item--selected': selectedDetail === item.code,
                            'ProductSchema__item--vendorCode': productVendorCode === item.product.vendorCode,
                        }"
                        v-for='(item, index) in details'
                        :key='index'
                        :ref='`${item.code}_${item.oem}`'
                        @mouseenter='handleMouseEnter(item.code)'
                        @mouseleave='handleMouseLeave'
                        @click='handleClick(item.code)'
                    >
                        <div class='ProductSchema__itemsHeadInfo ProductSchema__itemsGrid'>
                            <span class='ProductSchema__itemHeadInfo'>{{item.code}}</span>
                            <span class='ProductSchema__itemHeadInfo'>{{item.oem}}</span>
                            <span class='ProductSchema__itemHeadInfo'>{{item.name}}</span>
                        </div>
                        <div
                            class='ProductSchema__itemBody'
                            v-if='isUserWholesaler'
                        >
                            <product-schema-detail
                                :id="item.code"

                                :quantity="cartProductQuantity(item.product.id)"
                                :quantity-in-package='item.product.quantityInPackage'
                                :price-retail-per-package='item.product.priceRetailPerPackage'
                                :price-retail-per-unit='item.product.wholesale.priceRetailPerUnit'
                                :price-wholesale-per-package='item.product.wholesale.priceWholesalePerPackage'
                                :price-wholesale-per-unit='item.product.wholesale.priceWholesalePerUnit'
                                :possible-to-order="item.product.possibleToOrder"

                                :product-id="item.product.id"
                                :url="item.product.url"
                                :name="item.product.name"
                                :vendor-code='item.product.vendorCode'
                                :image="item.product.image"
                                :stocks="item.product.stocks"
                                :image-size="120"

                                :is-user-wholesaler="isUserWholesaler"
                                :price-display-type="priceDisplayType"

                                :is-loading="cartProductLoadingStatus(item.product.id)"
                            ></product-schema-detail>
                        </div>
                        <div
                            class='ProductSchema__itemBody'
                            v-else
                        >
                            <product-schema-detail
                                :id="item.code"

                                :quantity="cartProductQuantity(item.product.id)"
                                :quantity-in-package='item.product.quantityInPackage'
                                :price-retail-per-package='item.product.priceRetailPerPackage'
                                :possible-to-order="item.product.possibleToOrder"

                                :product-id="item.product.id"
                                :url="item.product.url"
                                :name="item.product.name"
                                :vendor-code='item.product.vendorCode'
                                :image="item.product.image"
                                :stocks="item.product.stocks"
                                :image-size="120"

                                :is-user-wholesaler="isUserWholesaler"
                                :price-display-type="priceDisplayType"

                                :is-loading="cartProductLoadingStatus(item.product.id)"
                            ></product-schema-detail>
                        </div>
                    </li>
                </ul>
            </section>
        </div>
    </li>
</template>

<script>
import { mapGetters } from 'vuex';
import { VUEX_GETTERS } from '@scripts/constants';
import ProductSchemaDetail from '../product-schema-detail/product-schema-detail.vue';

const SCHEMA_DEFAULT_SIZE = 100;
const ZOOM_STEP = 20;
const MAX_SIZE = ZOOM_STEP * 15;
const MIN_SIZE = ZOOM_STEP * 3;

export default {
    props: {
        category: {
            type: String,
            require: true,
        },
        name: {
            type: String,
            require: true,
        },
        image: {
            type: String,
            require: true,
        },
        details: {
            type: Array,
            require: true,
        },
        isUserWholesaler: {
            type: Boolean,
            require: true,
        },
        priceDisplayType: {
            type: String,
            require: true,
        },
        productVendorCode: {
            type: String,
            require: true,
        },
    },
    components: {
        ProductSchemaDetail,
    },
    data() {
        return {
            schemaSize: SCHEMA_DEFAULT_SIZE,
            recalculated: true,
            imageNaturalSize: null,
            hoveredDetail: null,
            selectedDetail: null,
            visitedDetails: [],
        };
    },
    beforeMount() {
        this.getImageSize(this.image, this.setImageNaturalSize);
    },
    mounted() {
        window.onresize = () => {
            this.setSchemaMaxWidth(this.schemaSize);
        };
        window.onscroll = () => {
            this.setSchemaMaxWidth(this.schemaSize);
        };

        this.setSchemaMaxWidth(this.schemaSize);

        this.dragElement(this.$refs.schemaDrag);
    },
    computed: {
        ...mapGetters([VUEX_GETTERS.CART_PRODUCT_QUANTITY, VUEX_GETTERS.CART_PRODUCT_LOADING_STATUS]),

        filterDetailsForPins() {
            const uniqueDetailsCodes = [];
            const filteredDetailsPins = [];

            this.details.forEach((detail) => {
                if (!uniqueDetailsCodes.includes(detail.code)) {
                    uniqueDetailsCodes.push(detail.code);
                    filteredDetailsPins.push(detail);
                }
            });

            return filteredDetailsPins;
        },
    },
    methods: {
        getImageSize(url, callback) {
            const img = new Image();
            img.src = url;
            img.onload = function() {
                callback({
                    width: this.width,
                    height: this.height,
                });
            };
        },

        setImageNaturalSize(size) {
            this.imageNaturalSize = size;
        },

        dragElement(element) {
            let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
            element.onmousedown = dragMouseDown;

            function dragMouseDown(e) {
                e = e || window.event;
                // get the mouse cursor position at startup:
                pos3 = e.clientX;
                pos4 = e.clientY;
                document.onmouseup = closeDragElement;
                // call a function whenever the cursor moves:
                document.onmousemove = elementDrag;
            }

            function elementDrag(e) {
                e = e || window.event;
                // calculate the new cursor position:
                pos1 = pos3 - e.clientX;
                pos2 = pos4 - e.clientY;
                pos3 = e.clientX;
                pos4 = e.clientY;
                // set the element's new position:
                element.style.top = (element.offsetTop - pos2) + "px";
                element.style.left = (element.offsetLeft - pos1) + "px";
            }

            function closeDragElement() {
                /* stop moving when mouse button is released:*/
                document.onmouseup = null;
                document.onmousemove = null;
            }
        },

        setDefaultSchemaContainerPosition() {
            this.$refs.schemaDrag.style.top = 0 + "px";
            this.$refs.schemaDrag.style.left = 0 + "px";
        },

        calculatePinPosition({ first, second }) {
            const coefficientWidthSchema = this.imageNaturalSize.width / this.$refs.schema.offsetWidth;
            const coefficientHeightSchema = this.imageNaturalSize.height / this.$refs.schema.offsetHeight;

            const left = (first.x / coefficientWidthSchema) - 1;
            const top = (first.y / coefficientHeightSchema) - 1;

            const widthPin = Math.ceil((second.x - first.x) / coefficientWidthSchema) + 3;
            const heightPin = Math.ceil((second.y - first.y) / coefficientHeightSchema) + 3;

            return ({
                top: top + 'px',
                left: left + 'px',
                width: widthPin + 'px',
                height: heightPin + 'px',
            });
        },

        setSchemaMaxWidth(maxWidth) {
            this.recalculated = false;
            this.$refs.schema.style.maxWidth = maxWidth + '%';
            this.recalculated = true;
        },

        resetSchema() {
            this.schemaSize = SCHEMA_DEFAULT_SIZE;
            this.setSchemaMaxWidth(this.schemaSize);
            this.setDefaultSchemaContainerPosition();
        },

        scrollToViewDetail(code, oem) {
            const detailsContainer = this.$refs.detailsContainer;
            const [detailToScroll] = this.$refs[`${code}_${oem}`];
            const detailTop = detailToScroll.offsetTop;
            detailsContainer.scrollTo({ top: detailTop, behavior: 'smooth'});
        },

        handleMouseEnter(code) {
            this.hoveredDetail = code;
        },

        handleMouseLeave() {
            this.hoveredDetail = null;
        },

        handleClick(code) {
            this.selectedDetail = code;
            this.visitedDetails = Array.from(new Set(this.visitedDetails.concat(code)));
        },

        handleClickPin(code, oem) {
            this.handleClick(code);
            this.scrollToViewDetail(code, oem);
        },

        handleMouseEnterContent() {
            this.setSchemaMaxWidth(this.schemaSize);
        },

        onWheelSchemaContainer() {
            event.preventDefault();

            if (event.deltaY > 0 && this.schemaSize > MIN_SIZE) {
                this.schemaSize = this.schemaSize - ZOOM_STEP;
            }
            if (event.deltaY < 0 && this.schemaSize < MAX_SIZE) {
                this.schemaSize = this.schemaSize + ZOOM_STEP;
            }

            this.setSchemaMaxWidth(this.schemaSize);
        },
    },
};
</script>
