import Vue from 'vue/dist/vue';
import { apiResetPasswordComplete } from '@scripts/api-methods';
import { form } from '@scripts/mixins/form';
import { VALIDATION_MESSAGES, VALIDATION_RULES, MODALS, MESSAGES } from '@scripts/constants';
import { showSuccessMessage } from '@library/uikit/notifications';
import { formSending } from '@library/forms/form-sending';
import { formValidation } from '@library/forms/validation';
import UIkit from "../../../vendor/uikit/uikit";

const formDataScheme = () => ({
    password: '',
    confirmPassword: '',
});

export default (el, name, data) =>
    new Vue({
        el,
        name,
        data: () => ({
            formData: formDataScheme(),
            confirmationToken: data.confirmationToken ?? '',
        }),
        mixins: [
            form(),
            formSending(),
            formValidation(
                {
                    new_password: {
                        field: 'formData.password',
                        rules: {
                            required: true,
                            ...VALIDATION_RULES.PASSWORD,
                        },
                    },
                    new_password_confirmation: {
                        field: 'formData.confirmPassword',
                        rules: {
                            ...VALIDATION_RULES.CONFIRM_PASSWORD,
                        },
                    },
                },
                VALIDATION_MESSAGES
            ),
        ],
        beforeMount() {
            UIkit.modal(MODALS.RESET_PASSWORD_COMPLETE).show();
        },
        methods: {
            clearForm() {
                this.formData = formDataScheme();
            },

            sendRequest() {
                return this.sendForm(
                    () =>
                        apiResetPasswordComplete({
                            confirmation_token: this.confirmationToken,
                            password: this.formData.password,
                            password_confirmation: this.formData.confirmPassword,
                        }),
                    () => {
                        showSuccessMessage({ text: MESSAGES.SUCCESS_RESET_PASSWORD });
                        setTimeout(() => {
                            UIkit.modal(MODALS.RESET_PASSWORD_COMPLETE_FORM).hide();
                            this.clearForm();
                            UIkit.modal(MODALS.LOGIN).show();
                        }, 300);
                    },
                );
            },
        },
    });
