'use strict'

import get from 'lodash-es/get'
import { Sealed } from '../classes'
import { LibraryLocalization } from '../l10n'

class FeipApiErrorMessage extends Sealed {}

class FeipApiError extends Sealed {
	constructor(key, error) {
		super({
			errors: error.map(error => new FeipApiErrorMessage(error)),
			key,
		})
	}

	/**
	 * Get message by index
	 * @param {number} index
	 * @returns {?string}
	 */
	getErrorMessage(index = 0) {
		return this.errors.length > 0 ? this.errors[index].message : null
	}

	/**
	 * Get all joined messages by separator
	 * @param {string} separator
	 * @returns {string}
	 */
	getAllErrorMessages(separator = ', ') {
		return this.errors.map(error => error.message).join(separator)
	}

	/**
	 * Check error code contains
	 * @param {string} code
	 * @returns {boolean}
	 */
	hasErrorCode(code) {
		return this.errors.some(error => error.code === code)
	}
}

class FeipApiInvalidField extends FeipApiError {}

class FeipApiCommonError extends FeipApiError {}

/**
 * @property {FeipApiCommonError} common
 * @property {FeipApiInvalidField[]} invalidFields
 */
export class FeipApiServerError extends Sealed {
	constructor(response) {
		super(null, () => {
			let common = null
			let invalidFields = []

			Object.keys(response.error).forEach(key => {
				const error = response.error[key]
				if (key === '') {
					common = new FeipApiCommonError(key, error)
				} else {
					invalidFields.push(new FeipApiInvalidField(key, error))
				}
			})

			return {
				common,
				invalidFields,
				details: response.details || null,
			}
		})
	}

	/**
	 * Is invalid error flag
	 * @returns {boolean}
	 */
	isInvalid() {
		return Boolean(this.invalidFields.length)
	}

	/**
	 * Is common error flag
	 * @returns {boolean}
	 */
	isCommon() {
		return this.common !== null
	}

	/**
	 * Get invalid field by key
	 * @param {string} key
	 * @returns {FeipApiInvalidField}
	 */
	getInvalidField(key) {
		return this.invalidFields.find(field => field.key === key)
	}

	/**
	 * Has invalid field by key
	 * @param {string} key
	 * @returns {boolean}
	 */
	hasInvalidField(key) {
		return Boolean(this.getInvalidField(key))
	}

	/**
	 * Get invalid fields keys
	 * @returns {string[]}
	 */
	getInvalidFieldsKeys() {
		return this.invalidFields.map(field => field.key)
	}

	/**
	 * Get invalid fields messages as object
	 * @returns {Object}
	 */
	getInvalidFieldsMessages() {
		return this.invalidFields.reduce((a, field) => ({ ...a, [field.key]: field.getAllErrorMessages() }), {})
	}
}

export class FeipApiFatalError extends Sealed {
	/** @param {Object} json */
	constructor (json) {
		super(null, () => {
			const details = {}
			const exception = get(json, 'exception', null)
			if (exception !== null) details.exception = exception
			const file = get(json, 'file', null)
			if (file !== null) details.file = file
			const line = get(json, 'line', null)
			if (line !== null) details.line = line
			return {
				code: 'INTERNAL_SERVER_ERROR',
				message: LibraryLocalization.get('ERROR_HANDLER.DEFAULT_ERROR'),
				details,
			}
		})
	}
}

export class ApiClientNetworkError extends Sealed {
	constructor () {
		super(null, () => ({
			code: 'NETWORK_CONNECTION_ERROR',
			message: LibraryLocalization.get('NETWORK_CONNECTION_ERROR'),
		}))
	}
}

export class ApiClientInvalidResponseError extends Sealed {
	constructor () {
		super(null, () => ({
			code: 'INVALID_RESPONSE_ERROR',
			message: LibraryLocalization.get('INVALID_RESPONSE_ERROR'),
		}))
	}
}
