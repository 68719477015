import Vue from 'vue/dist/vue';
import { GLOBAL_EVENTS, VALIDATION_MESSAGES, MESSAGES, MODALS } from '@scripts/constants';
import { apiProductSearchBySize } from '@scripts/api-methods';
import { showErrorMessage } from '@library/uikit/notifications';
import { form } from '@scripts/mixins/form';
import { formSending } from '@library/forms/form-sending';
// import { formValidation } from '@library/forms/validation';
// import { showErrorMessage } from '@library/uikit/notifications';
import UIkit from "../../vendor/uikit/uikit";

const formDataSchema = () => ({
    min: {},
    max: {}
});

const currentSchema = () => ({
    type: '',
    schemaUrl: '',
    title: ''
});

export default (el, name) =>
    new Vue({
        el,
        name,
        data: () => ({
            currentTab: 'all',
            typeProperties: [],
            formData: formDataSchema(),
            foundProducts: {},
            foundProductsProperties: {},
            current: currentSchema(),
            propertiesSortOrder: {}
        }),
        mounted() {

        },
        mixins: [
            form(),
            // formSending({ useRecaptcha: true }),
            formSending({}),
        ],
        computed: {

        },
        methods: {
            showSearchForm(typeId, typeScope){
                typeScope = JSON.parse(typeScope);

                this.foundProducts = {};
                this.propertiesSortOrder = {};

                this.typeProperties = typeScope.properties.sort();

                if (typeId !== this.current.type){
                    this.clearSearchForm();
                }
                this.current.schemaUrl = typeScope.schema || '';
                this.current.type = typeId;
                this.current.title = typeScope.name;

                UIkit.modal(MODALS.PRODUCT_SEARCH_BY_SIZE).show();
            },
            searchBySizes(){
                this.foundProducts = {};
                this.propertiesSortOrder = {};

                let payload =                         {
                    type: this.current.type,
                    min: structuredClone (this.formData.min),
                    max: structuredClone (this.formData.max)
                };

                for( let propertyId in this.typeProperties ){
                    if (this.typeProperties.hasOwnProperty(propertyId)) {
                        if ( this.formData.min[this.typeProperties[propertyId].scp_id] === undefined ){
                            payload.min[this.typeProperties[propertyId].scp_id] = 0;
                        }
                        if ( this.formData.max[this.typeProperties[propertyId].scp_id] === undefined ){
                            payload.max[this.typeProperties[propertyId].scp_id] = 9999;
                        }
                    }
                }
                this.sendForm(
                    () => apiProductSearchBySize(payload),
                    (response) => {
                        this.foundProducts = JSON.parse(response.products) || {};
                    },
                    () => {
                        showErrorMessage({
                            text: "Поиск не удался, повторите позже",
                        });
                    }
                );
            },
            clearSearchForm(){
                this.formData = formDataSchema();
                this.foundProducts = {};
            },
            sortProducts(columnId){
                if ( this.propertiesSortOrder[columnId]  === undefined ){
                    this.propertiesSortOrder[columnId] = 1;
                }else{
                    this.propertiesSortOrder[columnId] = -this.propertiesSortOrder[columnId];
                }
                let sortOrder = this.propertiesSortOrder[columnId];
                this.foundProducts = this.foundProducts.sort(function (a, b) {
                    return a.properties[columnId] > b.properties[columnId] ? sortOrder : -sortOrder;
                });
            },

        },
    })
;