<template>
    <div
        class="BranchItem"
        :class="{'BranchItem--withCoordinates' : hasCoordinates, 'BranchItem--active' : isActive}"
        @click="hasCoordinates && showOnMap($event, latitude, longitude, true)"
    >
        <h2
            v-if="title"
            v-html="title"
            class="BranchItem__title"
        ></h2>

        <span
            v-if="address"
            v-html="address"
            class="BranchItem__address"
        ></span>

        <div class="BranchItem__phoneContainer" v-for="(phone, index) in phones" :key="index">
            <a
                class="BranchItem__phone"
                :href="`tel:${phone.value}`"
                v-html="formatPhone(`${phone.value}`)"
                data-disable-show-map="true"
            ></a>
            <span class="text" v-if="phone.comment" v-html="phone.comment"></span>
        </div>


        <span
            v-if="schedule"
            v-html="schedule"
            class="BranchItem__schedule"
        ></span>

        <a
            v-if="hasCoordinates"
            href="#contacts-map"
            class="Button Button--underlinedLink BranchItem__showOnMap"
            @click="showOnMap($event, latitude, longitude, false)"
        >
            <span class="Button__text">
                Показать на карте
            </span>
        </a>
    </div>
</template>

<script>
    export default {
        props: {
            id: {
                type: Number,
                required: true,
            },
            isActive: {
                type: Boolean,
            },
            title: {
                type: String,
            },
            address: {
                type: String,
            },
            phones: {
                type: Array,
            },
            schedule: {
                type: String,
            },
            latitude: {
                type: Number,
            },
            longitude: {
                type: Number,
            },
            hasCoordinates: {
                type: Boolean,
            },
        },
        methods: {
            isMobile() {
                return window.matchMedia('(max-width: 999px)').matches;
            },

            showOnMap(event, lat, lng, checkScreenWidth) {
                // disable clicking on cards on mobile (show extra button instead)
                if (event.target.dataset.disableShowMap || (checkScreenWidth && this.isMobile())) return;

                this.$emit('show-on-map', { lat, lng, id: this.id });
            },
        },
        mounted() {
            console.log(this.phones);
        },
    }
</script>
