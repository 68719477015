import Vue from 'vue/dist/vue';
import { apiEmailRegister } from '@scripts/api-methods';
import { form } from '@scripts/mixins/form';
import { formSending } from '@library/forms/form-sending';
import { formValidation } from '@library/forms/validation';
import { showSuccessMessage } from '@library/uikit/notifications';
import { MESSAGES, MODALS, VALIDATION_MESSAGES, VALIDATION_RULES } from '@scripts/constants';
import UIkit from '../../../vendor/uikit/uikit';

const formDataScheme = () => ({
    email: '',
    password: '',
    confirmPassword: '',
});

export default (el, name) =>
    new Vue({
        el,
        name,
        data: () => ({
            formData: formDataScheme(),
        }),
        mixins: [
            form(),
            formSending({ useRecaptcha: true }),
            formValidation(
                {
                    email: {
                        field: 'formData.email',
                        rules: {
                            required: true,
                            ...VALIDATION_RULES.EMAIL,
                        },
                    },
                    password: {
                        field: 'formData.password',
                        rules: {
                            required: true,
                            ...VALIDATION_RULES.PASSWORD,
                        },
                    },
                    password_confirmation: {
                        field: 'formData.confirmPassword',
                        rules: {
                            ...VALIDATION_RULES.CONFIRM_PASSWORD,
                        },
                    },
                },
                VALIDATION_MESSAGES
            ),
        ],
        methods: {
            clearForm() {
                this.formData = formDataScheme();
            },

            sendRequest() {
                return this.sendForm(
                    () =>
                        apiEmailRegister({
                            email: this.formData.email,
                            password: this.formData.password,
                            password_confirmation: this.formData.confirmPassword,
                            captcha_token: this.token,
                        }),
                    () => {
                        showSuccessMessage({ text: MESSAGES.SUCCESS_REGISTRATION });
                        UIkit.modal(MODALS.REGISTRATION).hide();
                        this.clearForm();
                        UIkit.modal(MODALS.LOGIN).show();
                    }
                );
            },

            openLogin() {
                UIkit.modal(MODALS.REGISTRATION).hide();
                UIkit.modal(MODALS.LOGIN).show();
            },
        },
    });
