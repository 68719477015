import Vue from 'vue/dist/vue';
import { mask } from 'vue-the-mask';
import { INPUT_MASKS } from '@library/scripts/constants';
import { formSending } from '@library/forms/form-sending';
import { formValidation } from '@library/forms/validation';
import { form } from '@scripts/mixins/form';
import { VALIDATION_MESSAGES, VALIDATION_RULES, MODALS } from '@scripts/constants';
import { apiPartRequestFeedback } from '@scripts/api-methods';
import UIkit from '../../../vendor/uikit/uikit';
import { getFromBinding, getFromGlobalScope } from '@scripts/helpers';


const formDataScheme = () => ({
    email: '',
    allWh: true
    // part: '',
});

export default (el, name) =>
    new Vue({
        el,
        name,
        data: () => ({
            formData: formDataScheme(),
            masks: {
                phone: INPUT_MASKS.PHONE_MASK,
            },
        }),
        beforeMount(){
            // this.formData.part = document.getElementById('part-request-part').value;
            // console.log('-+-'+this.binding.currentPart);
            // console.log(getFromBinding('selectedCity'));
            if(getFromBinding('user') !== undefined && getFromBinding('user').email !== undefined){
                this.formData.email = getFromBinding('user').email ?? '';
            }
        },
        directives: { mask },
        mixins: [
            form(),
            formSending({ useRecaptcha: true }),
            formValidation(
                {
                    // name: {
                    //     field: 'formData.name',
                    //     rules: {
                    //         required: true,
                    //     },
                    // },
                    // phone: {
                    //     field: 'formData.phone',
                    //     rules: {
                    //         required: true,
                    //         ...VALIDATION_RULES.PHONE,
                    //     },
                    // },
                    email: {
                        field: 'formData.email',
                        rules: {
                            required: true,
                            ...VALIDATION_RULES.EMAIL,
                        },
                    },
                    // text: {
                    //     field: 'formData.part',
                    //     rules: {
                    //         required: true,
                    //         minLength: 3,
                    //         maxLength: 2000,
                    //     },
                    // },
                },
                VALIDATION_MESSAGES
            ),
        ],
        methods: {
            clearForm() {
                this.formData = formDataScheme();
            },

            sendRequest() {
                // console.log('-send-');
                // console.log(document.getElementById('part-request-part').value);
                let city = ( getFromBinding('selectedCity') !== undefined)?(getFromBinding('selectedCity').city):'';
                return this.sendForm(
                    () =>
                        apiPartRequestFeedback({
                            email: this.formData.email,
                            part: document.getElementById('part-request-part').value,
                            city: city,
                            all_wh: this.formData.allWh,
                            captcha_token: this.token,
                        }),
                    () => {
                        this.successFormSending();
                        UIkit.modal(MODALS.PART_REQUEST).hide();
                    }
                );
            },
        },
    });
