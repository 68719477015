'use strict';

/** @todo: refactor constants */

/** @enum {int} */
export const DefaultTimeout = {
	Notifications: 5000
};

export const NOTIFICATION_TYPES = {
	DANGER: 'danger',
	WARNING: 'warning',
	SUCCESS: 'success',
	PRIMARY: 'primary',
};

export const REQUEST = {
	PRELOADER_DEBOUNCE: 1000,
	PRIMARY_DEBOUNCE: 300,
	SECONDARY_DEBOUNCE: 1000,
	TIMEOUT: 15000,
	RELOAD_PAGE_TIMEOUT: 1700,
};

export const DATETIME = {
	TO_JS_TIMESTAMP: 1000,
	DEFAULT_DATE_FORMAT: 'DD.MM.YYYY',
};

export const INPUT_MASKS = {
	CODE_MASK: '####',
	PHONE_MASK: '+7 ### ###-##-##',
	PHONE_MASK_WITH_PARENTHESES: '+7 (###) ###-##-##',
	PHONE_MASK_LEADING_8: '8 ### ###-##-##',
	PHONE_MASK_LEADING_8_WITH_PARENTHESES: '8 (###) ###-##-##',
	INN_MASK: '############',
	KPP_MASK: '#### ## ###',
	OGRN_MASK: '### ### ### ####',
	CHECKING_ACCOUNT_MASK: '### ## ### # #### #######',
	BIK_MASK: '## ## ## ###',
	CORRESPONDENT_ACCOUNT: '### ## ### # ######## ###',
    PHONE_FREE_MASK: '+# ### ### ## ## ## ##',
};

export default {
	NOTIFICATION_TYPES,
	REQUEST,
	DATETIME,
	INPUT_MASKS,
};
