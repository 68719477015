export const tabsMixin = () => ({
    beforeMount() {
        if (this.tabsSelectors === undefined) throw new Error('Selector(s) is not set');
    },
    methods: {
        hideGroups() {
            this.tabsSelectors.forEach((selector) => {
                const groups = document.querySelectorAll(`[${selector}]`);
                groups.forEach((group) => {
                    group.hidden = true;
                });
            });
        },
        showActiveGroup(value) {
            this.tabsSelectors.forEach((selector) => {
                const activeGroup = document.querySelector(`[${selector}="${value}"]`);
                if (activeGroup === null) return;
                activeGroup.hidden = false;
            });
        },
    },
    watch: {
        currentTab(value) {
            this.hideGroups();
            this.showActiveGroup(value);
        },
    },
});
