'use strict'

/**
 * Convert JS Object to FormData
 * @param object {Object}
 * @param formData
 * @param namespace {string}
 * @returns {FormData}
 */
export function objToFormData(object, formData = new FormData(), namespace = '') {
	for (let propertyName in object) {
		let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName
		if (!object.hasOwnProperty(propertyName) || !object[propertyName]) continue
		if (object[propertyName] instanceof Date) {
			formData.append(formKey, object[propertyName].toISOString())
		} else if (object[propertyName] instanceof Array) {
			object[propertyName].forEach((element, index) => {
				const tempFormKey = `${formKey}[${index}]`
				if (typeof element === 'string' || element instanceof File) {
					formData.append(tempFormKey, element)
				} else {
					objToFormData(element, formData, tempFormKey)
				}
			})
		} else if (object[propertyName] instanceof File) {
			formData.append(formKey, object[propertyName])
		} else if (typeof object[propertyName] === 'object' && !(object[propertyName] instanceof File)) {
			objToFormData(object[propertyName], formData, formKey)
		} else {
			formData.append(formKey, object[propertyName].toString())
		}
	}
	return formData
}
