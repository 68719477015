import Vue from 'vue/dist/vue';
import { MODALS, MESSAGES } from '@scripts/constants';
import { apiLogout } from '@scripts/api-methods';
import { getFromBinding } from '@scripts/helpers';
import { showErrorMessage } from '@library/uikit/notifications';
import UIkit from '../../../vendor/uikit/uikit';

export default (el, name) =>
    new Vue({
        el,
        name,
        data: () => ({
            logoutLoader: {
                debounceLoading: false,
                realLoading: false,
            },
        }),
        methods: {
            handleLogoutConfirmed() {
                this.setLogoutLoader(true)
                apiLogout()
                    .then(() => {
                        window.location.href = getFromBinding('logoutButtonData.successLogoutRedirectRoute');
                        UIkit.modal(MODALS.LOGOUT_CONFIRM).hide();
                    })
                    .catch(() => {
                        showErrorMessage({
                            text: MESSAGES.LOGOUT_ERROR,
                        });
                    })
                    .finally(() => {
                        this.setLogoutLoader(false);
                    });
            },
            setLogoutLoader(bool) {
                this.logoutLoader.debounceLoading = bool;
                this.logoutLoader.realLoading = bool;
            },
        },
    });
