import { render, staticRenderFns } from "./cart-item-physic.vue?vue&type=template&id=33299d64&"
import script from "./cart-item-physic.vue?vue&type=script&lang=js&"
export * from "./cart-item-physic.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/sites/www/new.crt.ru/html/current_version/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('33299d64')) {
      api.createRecord('33299d64', component.options)
    } else {
      api.reload('33299d64', component.options)
    }
    module.hot.accept("./cart-item-physic.vue?vue&type=template&id=33299d64&", function () {
      api.rerender('33299d64', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/components/cart-item-physic/cart-item-physic.vue"
export default component.exports