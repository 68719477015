export const dropdownOptions = () => {
    const data = {
        dropdownOptions: {},
    };

    const methods = {
        getPaginatedOptions({ apiMethod, mapMethod, params, destPropertyName, setFilterName, success = null } = {}) {
            return apiMethod({ ...params })
                .then((result) => {
                    if (setFilterName) setFilterName(result);
                    return result;
                })
                .then((result) => mapMethod(result))
                .then((result) => {
                    if (params.page === 1) {
                        // clear when search new value
                        this.dropdownOptions[destPropertyName] = [];
                    }

                    this.dropdownOptions[destPropertyName].push(...result.items);

                    if (success) {
                        success(result);
                    }
                });
        },
    };

    return {
        data,
        methods,
    };
};
