import smoothScroll from '@library/scripts/smooth-scroll';
import yall from 'yall-js/dist/yall';
import { initModals } from '@library/scripts/register';
import { initSwiperInstances } from '../vendor/swiper/swiper';
import UIkit from '../vendor/uikit/uikit';
import newsSliderOptions from '../components/news-slider/options';
import initGallerySticky from '../pages/guide-item';

function lazyLoadImages() {
    yall({
        observeChanges: true,
        events: {
            load(event) {
                const parent = event.target.parentNode;
                if (parent.nodeName === 'PICTURE') {
                    parent.classList.add('yall-loaded');
                }
            },
        },
    });
}

export function initReadyDOMInteractions() {
    smoothScroll();
    lazyLoadImages();
    initSwiperInstances([
        {
            selector: '[data-news-slider]',
            options: newsSliderOptions,
        },
    ]);
    initGallerySticky();
}

export function initWindowLoadedInteractions() {
    initModals(
        UIkit,
        [
            {
                modal: '[data-uk-modal]',
                form: '[data-form]',
            },
        ],
        {
            bodyScrollLock: false,
        }
    );
}
