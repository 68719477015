import { GLOBAL } from '@scripts/constants';
import get from 'lodash-es/get';

export function getFromGlobalScope(path, defaultValue) {
    return get(GLOBAL.APP_ENTRY_POINT, path, defaultValue);
}

export function getFromBinding(path, mappingMethod = null) {
    const prefixedPath = `${GLOBAL.DEFAULT_BINDING_PREFIX}.${path}`;
    const result = getFromGlobalScope(prefixedPath);
    return mappingMethod && result !== undefined
        ? mappingMethod(result)
        : result;
}

export function onClickOutside(element, callback) {
    let outsideClickListener;

    const removeClickListener = () => {
        document.removeEventListener('click', outsideClickListener);
    };

    outsideClickListener = (event) => {
        const isClickInside = element.contains(event.target);
        if (!isClickInside) {
            callback();
            removeClickListener();
        }
    };

    document.addEventListener('click', outsideClickListener);

    return removeClickListener;
}

export function getImageSize(sizes, referenceValue = Number.MIN_VALUE) {
    // find image with width nearest to reference value, smallest by default
    return get(
        sizes.reduce(
            (previous, current) =>
                Math.abs(previous.width - referenceValue) <
                Math.abs(current.width - referenceValue)
                    ? previous
                    : current,
            { width: Number.MAX_VALUE }
        ),
        'url',
        null
    );
}

// https://stackoverflow.com/questions/36280818/how-to-convert-file-to-base64-in-javascript
export function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

export function reduceStocks(stocks) {
    return stocks.reduce((a, el) => {
        if (a.length > 0) {
            a.push({
                ...el,
                amount_in_units:
                    a[a.length - 1].amount_in_units + el.amount_in_units,
                amount_in_packages:
                    a[a.length - 1].amount_in_packages +
                    el.amount_in_packages,
            });
        } else {
            a.push(el);
        }

        return a;
    }, [])
}
