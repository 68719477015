'use strict'

import { capitalize } from '../strings'
import { noop } from '../index'

export const TOKEN_TYPES = {
	BASIC: 'Basic',
	BEARER: 'Bearer',
}

export class Authorization {
	constructor() {
		this.use = false
		this.type = null
		this.getter = noop
		this.handler = noop
		this.invalidTokenHandler = noop
	}

	getAuthHeaders() {
		const token = this.getter()
		const authHeaders = {}
		if (this.use && token !== null) {
			authHeaders.Authorization = `${capitalize(this.type)} ${this.getter()}`
		}
		return authHeaders
	}

	setTokenGetter(getter) {
		if (!(getter instanceof Function)) throw new SyntaxError('Getter is not a function')
		this.getter = getter
	}

	setTokenHandler(handler) {
		if (!(handler instanceof Function)) throw new SyntaxError('Handler is not a function')
		this.handler = handler
	}

	receiveNewToken(token) {
		this.handler(token)
	}

	setInvalidTokenHandler(handler) {
		if (!(handler instanceof Function)) throw new SyntaxError('Handler is not a function')
		this.invalidTokenHandler = handler
	}
}

export class CookieAuth extends Authorization {}

export class BasicAuth extends Authorization {
	constructor(defaultToken) {
		super()
		this.use = true
		this.type = TOKEN_TYPES.BASIC
		this.getter = () => defaultToken
	}
}

export class BearerAuth extends BasicAuth {
	constructor(defaultToken) {
		super(defaultToken)
		this.type = TOKEN_TYPES.BEARER
	}
}
