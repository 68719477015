import { mapState } from 'vuex';
import { FAVORITE_STATES, MESSAGES, MODALS } from '@scripts/constants';
import { apiFavoritesAdd, apiFavoritesRemove } from '@scripts/api-methods';
import store from '@scripts/store';
import { showErrorMessage } from '@library/uikit/notifications';
import UIkit from '../../vendor/uikit/uikit';

export const toggleFavorites = () => ({
    store,
    data: () => ({
        favoritesMap: {},
    }),
    computed: {
        ...mapState(['user']),
    },
    methods: {
        toggleFavorite(productId) {
            if (this.user.isLoggedIn !== true) {
                UIkit.modal(MODALS.LOGIN).show();
                return;
            }

            if (this.isFavoriteLoading(productId)) return;

            const id = `id${productId}`;

            const isFavorite = this.favoritesMap[id] || FAVORITE_STATES.NOT_LIKED;

            this.$set(this.favoritesMap, id, FAVORITE_STATES.LOADING);

            const method = isFavorite === FAVORITE_STATES.LIKED ? apiFavoritesRemove : apiFavoritesAdd;

            method({
                product_id: productId,
            })
                .then(() => {
                    this.$set(this.favoritesMap, id, isFavorite === FAVORITE_STATES.LIKED ? FAVORITE_STATES.NOT_LIKED : FAVORITE_STATES.LIKED);
                })
                .catch(() => {
                    this.$set(this.favoritesMap, id, isFavorite);
                    showErrorMessage({
                        text: MESSAGES.FAVORITE_TOGGLE_ERROR,
                    });
                });
        },

        isFavorite(productId) {
            return this.favoritesMap[`id${productId}`] === FAVORITE_STATES.LIKED;
        },

        isFavoriteLoading(productId) {
            return this.favoritesMap[`id${productId}`] === FAVORITE_STATES.LOADING;
        },
    },
});
