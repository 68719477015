import Vue from 'vue/dist/vue';
import { apiGetCheckByVinOrFrame } from "@scripts/api-methods";
import { mapCheckByVinOrFrameResponse } from '@scripts/mappings';
import { GLOBAL_EVENTS, MODALS, VALIDATION_MESSAGES, MESSAGES } from '@scripts/constants';
import eventBus from '@scripts/event-bus';
import { form } from '@scripts/mixins/form';
import { formSending } from '@library/forms/form-sending';
import { formValidation } from '@library/forms/validation';
import { showErrorMessage } from '@library/uikit/notifications';
import UIkit from '../../../vendor/uikit/uikit';

const formDataSchema = () => ({
    vin: '',
});

export default (el, name) =>
    new Vue({
        el,
        name,
        data: () => ({
            formData: formDataSchema(),
        }),
        mixins: [
            form(),
            formSending({ useRecaptcha: true }),
            formValidation(
                {
                    vin: {
                        field: 'formData.vin',
                        rules: {
                            required: true,
                            minLength: 3,
                        },
                    },
                },
                VALIDATION_MESSAGES
            ),
        ],
        methods: {
            sendRequest() {
                const { vin } = this.formData;
                const apiMethodCall = () =>
                    apiGetCheckByVinOrFrame({
                        vin_or_frame: vin,
                        captcha_token: this.token,
                    });

                return this.sendForm(
                    () =>
                        apiMethodCall(),
                    (response) => {
                        const result = mapCheckByVinOrFrameResponse({
                            vin,
                            cars: response.items,
                        });
                        eventBus.$emit(GLOBAL_EVENTS.OPEN_VIN_RESULT, result);
                        UIkit.modal(MODALS.VIN_RESULT).show();
                    },
                    () => {
                        showErrorMessage({
                            text: MESSAGES.CHECK_VIN_OR_FRAME_ERROR,
                        });
                    }
                );
            },
        },
    });
