var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "CartItemPhysic",
      class: { "CartItemPhysic--removing": _vm.isRemoving },
    },
    [
      !_vm.isPresentInRossko
        ? _c(
            "a",
            {
              staticClass: "CartItemPhysic__pictureLink",
              attrs: { href: _vm.url },
            },
            [
              _c(
                "picture",
                {
                  staticClass: "CartItemPhysic__picture",
                  class: {
                    "CartItemPhysic__picture--withPlaceholder": !_vm.image,
                  },
                },
                [
                  _vm.image
                    ? _c("img", {
                        staticClass: "CartItemPhysic__image",
                        attrs: {
                          srcset: `${_vm.image1x}, ${_vm.image2x} 2x`,
                          src: _vm.image1x,
                          alt: _vm.name,
                        },
                      })
                    : _c("inline-svg", {
                        staticClass: "CartItemPhysic__placeholder",
                        attrs: { src: "/assets/images/image-placeholder.svg" },
                      }),
                ],
                1
              ),
            ]
          )
        : _c("span", { staticClass: "CartItemPhysic__pictureLink" }, [
            _c(
              "picture",
              {
                staticClass: "CartItemPhysic__picture",
                class: {
                  "CartItemPhysic__picture--withPlaceholder": !_vm.image,
                },
              },
              [
                _vm.image
                  ? _c("img", {
                      staticClass: "CartItemPhysic__image",
                      attrs: {
                        srcset: `${_vm.image1x}, ${_vm.image2x} 2x`,
                        src: _vm.image1x,
                        alt: _vm.name,
                      },
                    })
                  : _c("inline-svg", {
                      staticClass: "CartItemPhysic__placeholder",
                      attrs: { src: "/assets/images/image-placeholder.svg" },
                    }),
              ],
              1
            ),
          ]),
      _vm._v(" "),
      _c("div", { staticClass: "CartItemPhysic__nameWrapper" }, [
        !_vm.isPresentInRossko
          ? _c(
              "a",
              {
                staticClass: "CartItemPhysic__nameLink",
                attrs: { href: _vm.url },
              },
              [
                _c("span", {
                  staticClass: "CartItemPhysic__vendorCode",
                  domProps: { innerHTML: _vm._s(_vm.vendorCode) },
                }),
                _vm._v("\n             \n            "),
                _c("span", {
                  staticClass: "CartItemPhysic__name",
                  domProps: { innerHTML: _vm._s(_vm.name) },
                }),
              ]
            )
          : _c("span", { staticClass: "CartItemPhysic__nameSpan" }, [
              _vm.vendorCode
                ? _c("span", [
                    _c("span", {
                      staticClass: "CartItemPhysic__vendorCode",
                      domProps: { innerHTML: _vm._s(_vm.vendorCode) },
                    }),
                    _vm._v("\n                 \n            "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("span", {
                staticClass: "CartItemPhysic__name",
                domProps: { innerHTML: _vm._s(_vm.name) },
              }),
            ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "CartItemPhysic__details" }, [
        _vm.packageItemsCount > 0
          ? _c("span", {
              staticClass: "CartItemPhysic__packageUnitsQuantity",
              domProps: {
                innerHTML: _vm._s(
                  `В&nbsp;упаковке: ${_vm.packageItemsCount}&nbsp;шт.`
                ),
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.designFeatures.length > 0
          ? _c("div", { staticClass: "CartItemPhysic__helpItem" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "CartItemPhysic__tooltip" },
                _vm._l(_vm.designFeatures, function (feature) {
                  return _c("a", {
                    staticClass:
                      "Button Button--underlinedLink CartItemPhysic__tooltipLink",
                    attrs: { target: "_blank", href: feature.link },
                    domProps: { innerHTML: _vm._s(feature.name) },
                  })
                }),
                0
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "CartItemPhysic__priceBlock" }, [
        _c("span", {
          staticClass: "CartItemPhysic__price CartItemPhysic__price--total",
          domProps: { innerHTML: _vm._s(_vm.formatRoubles(_vm.totalPrice)) },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "CartItemPhysic__statusBlock" }, [
          _vm.pricePerPackage > 0
            ? _c("span", {
                staticClass:
                  "CartItemPhysic__price CartItemPhysic__price--perUnit",
                domProps: {
                  innerHTML: _vm._s(
                    `(${_vm.formatRoubles(_vm.pricePerPackage)} / упак.)`
                  ),
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "CartItemPhysic__status" },
            [
              _c("product-status", {
                attrs: {
                  quantity: _vm.quantity,
                  "quantity-in-package": _vm.packageItemsCount,
                  stocks: _vm.stocks,
                  "is-user-wholesaler": _vm.isUserWholesaler,
                  "part-vendor-code": _vm.vendorCode,
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "CartItemPhysic__counter" },
          [
            _c("add-to-cart", {
              attrs: {
                "product-id": _vm.productId,
                quantity: _vm.quantity,
                mode: "cart",
                "quantity-in-package": _vm.packageItemsCount,
                "is-user-wholesaler": _vm.isUserWholesaler,
                "is-loading": _vm.isLoading,
                "always-show-counter": true,
              },
              on: { "change-quantity": _vm.changeQuantity },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "Button Button--remove CartItemPhysic__removeButton",
          attrs: { type: "button" },
          on: { click: _vm.removeItem },
        },
        [
          _c(
            "span",
            { staticClass: "Button__icon" },
            [_c("inline-svg", { attrs: { src: "/assets/icons/close.svg" } })],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass:
          "Button Button--underlinedLink CartItemPhysic__featuresLink",
        attrs: { type: "button" },
      },
      [
        _c("span", { staticClass: "Button__text" }, [
          _vm._v("\n                    Особенности\n                "),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }