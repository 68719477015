'use strict'

import { AbstractError, ArgumentError } from './errors'
import {
	isArray,
	isBool,
	isEmpty,
	isFloat,
	isFunction,
	isInstanceOf,
	isInt,
	isNatural,
	isNull,
	isNumeric,
	isObject,
	isString,
	isUndefined,
} from './is'

export class AssertionError extends AbstractError {}

/**
 * Assert condition
 * @param {boolean} condition - Anything that can be cast to boolean
 * @param {string} [message] - Optional assert message
 * @param {boolean} [soft=false] - Throw error or show
 * @throws AssertionError
 */
function assert(condition, message = 'Assertion failed! See stack trace for details', soft = false) {
	if (!condition) {
		const error = new AssertionError(message)
		if (soft) console.warn(error)
		else throw error
	}
}

/**
 * @callback CalleeOfIs
 * @param {*} value
 * @return {boolean}
 */

/**
 * @param {CalleeOfIs} callee
 * @param {*} value
 * @param {boolean} [soft=false]
 * @throws {AssertionError}
 */
function assertType(callee, value, soft = false) {
	if (!isFunction(callee)) throw new ArgumentError('Invalid type')
	assert(callee(value), `Invalid argument type.\nExpected: ${callee.name.replace('is', '')}`)
}

/**
 * @param {*} value
 * @param {boolean} [soft]
 */
export function assertNull(value, soft) { assertType(isNull, value, soft) }

/**
 * @param {*} value
 * @param {boolean} [soft]
 */
export function assertInt(value, soft) { assertType(isInt, value, soft) }

/**
 * @param {*} value
 * @param {boolean} [soft]
 */
export function assertBool(value, soft) { assertType(isBool, value, soft) }

/**
 * @param {*} value
 * @param {boolean} [soft]
 */
export function assertArray(value, soft) { assertType(isArray, value, soft) }

/**
 * @param {*} value
 * @param {boolean} [soft]
 */
export function assertEmpty(value, soft) { assertType(isEmpty, value, soft) }

/**
 * @param {*} value
 * @param {boolean} [soft]
 */
export function assertFloat(value, soft) { assertType(isFloat, value, soft) }

/**
 * @param {*} value
 * @param {boolean} [soft]
 */
export function assertString(value, soft) { assertType(isString, value, soft) }

/**
 * @param {*} value
 * @param {boolean} [soft]
 */
export function assertObject(value, soft) { assertType(isObject, value, soft) }

/**
 * @param {*} value
 * @param {boolean} [soft]
 */
export function assertNatural(value, soft) { assertType(isNatural, value, soft) }

/**
 * @param {*} value
 * @param {boolean} [soft]
 */
export function assertNumeric(value, soft) { assertType(isNumeric, value, soft) }

/**
 * @param {*} value
 * @param {boolean} [soft]
 */
export function assertFunction(value, soft) { assertType(isFunction, value, soft) }

/**
 * @param {*} value
 * @param {boolean} [soft]
 */
export function assertUndefined(value, soft) { assertType(isUndefined, value, soft) }

/**
 * @param {*} value
 * @param {*} proto
 * @param {boolean} [soft]
 */
export function assertInstanceOf(value, proto, soft) {
	assert(isInstanceOf(value, proto), `Invalid argument type.\nExpected: ${proto.name.replace('is', '')}\nReceived: ${proto.prototype.name}`)
}

export const Assert = {
	'null': assertNull,
	'int': assertInt,
	'bool': assertBool,
	'array': assertArray,
	'empty': assertEmpty,
	'float': assertFloat,
	'string': assertString,
	'object': assertObject,
	'natural': assertNatural,
	'numeric': assertNumeric,
	'function': assertFunction,
	'undefined': assertUndefined,
	'instanceOf': assertInstanceOf,
}
