import Vue from 'vue/dist/vue';

export default (el, name) =>
    new Vue({
        el,
        name,
        data: () => ({
            currentTab: '0',
        }),
    });
