import { get } from 'lodash-es';
import { PRODUCT_STATUSES, SEARCH_TABS } from '@scripts/constants'; // TODO

// ENTITIES ------------------------------------------------------------------------------------------------------------

export const mapImageSize = (imageSize) => ({
    url: imageSize.url,
    width: imageSize.width,
    height: imageSize.height,
});

export const mapImage = (image) => ({
    sizes: image.sizes.map(mapImageSize),
});

export const getStockQuantity = (stocks, status) => {
    return stocks.find((stock) => stock.status.id === status)?.amount_in_units ?? 0;
};

export const mapCartProduct = (data) => ({
    id: data.id,
    url: data.url,
    name: data.name,
    vendorCode: data.vendor_code,
    weightPerUnit: data.weight_per_unit_grams,
    weightPerPackage: data.weight_per_package_grams,
    priceRetailPerUnit: get(data.wholesale, 'price_retail_per_unit', null),
    priceRetailPerPackage: data.price_retail_per_package,
    priceWholesalePerUnit: get(data.wholesale, 'price_wholesale_per_unit', null),
    priceWholesalePerPackage: get(data.wholesale, 'price_wholesale_per_package', null),
    quantityInPackage: data.quantity_in_package,
    isFavorite: data.is_favorite,
    isPresentInRossko: data.is_present_in_rossko,
    stocks: data.stocks,
    image: mapImage(data.image_small),
    designFeatures: data.design_features || [],
    availableQuantity: getStockQuantity(data.stocks, 'available'),
    quantityForOrder: getStockQuantity(data.stocks, 'on_order'),
    quantityForManufacturing: getStockQuantity(data.stocks, 'for_production'),
    status: PRODUCT_STATUSES.FOR_ORDER, // TODO
});

export const mapCartItem = (data) => ({
    id: data.id,
    product: mapCartProduct(data.product),
    quantityUnits: data.quantity_units,
    quantityPackages: data.quantity_packages,
    priceRetailPerPackage: data.price_retail_per_package,
    priceTotalRetail: data.price_total_retail,
    priceWholesalePerUnit: get(data.wholesale, 'price_wholesale_per_unit', null),
    packagesQuantity: get(data.wholesale, 'packages_quantity', null),
    priceTotalWholesale: get(data.wholesale, 'price_total_wholesale', null),
    priceRetailPerUnit: get(data.wholesale, 'price_retail_per_unit', null),
    weightItemTotal: data.weight_total,
});

export const mapCartDetails = (data) => ({
    items: data.items.map(mapCartItem),
    totalPriceRetail: data.price_retail,
    totalPriceWholesale: data.price_wholesale,
    totalWeight: data.total_weight_grams,
    shippingPrice: 0,
    discountAmount: data.discount_amount,
});

export const mapCheckoutUserRole = (data) => ({
    organizationName: data.organization_name,
    inn: data.tin,
    kpp: data.iec,
    rs: data.checking_account,
    name: data.contact_person_full_name,
    phone: data.contact_phone,
})

export const mapCityForDropdown = (city) => ({
    value: city.city,
    label: city.city,
});

export const mapCity = (city) => ({
    country: city.country,
    region: city.region,
    city: city.title,
    latitude: city.latitude,
    longitude: city.longitude,
});

export const mapDate = (date, options = { day: 'numeric', month: "numeric", year: "numeric" }) => {
    return date.toLocaleString('ru-RU', options);
};

export const mapFormatDate = (date, options) => mapDate(new Date(date.replace(/\./g, '-')), options);

export const mapCarVinOrFrame = (car) => ({
    id: car.id,
    modelCode: car.model_code ?? '',
    catalogCode: car.catalog_code ?? '',
    mark: car.mark ?? '',
    market: car.market ?? '',
    model: car.model ?? '',
    body: car.body ?? '',
    bodyStyle: car.body_style ?? '',
    releaseDate: car.release_date ? mapFormatDate(car.release_date) : '',
    engine: car.engine ?? '',
    modification: car.modification ?? '',
    grade: car.grade ?? '',
});

export const mapCatalogVinInfo = (vehicle) => ({
    id: vehicle.id ?? '',
    modelCode: vehicle.model_code ?? '',
    catalogCode: vehicle.catalog_code ?? '',
    market: vehicle.market ?? '',
    mark: vehicle.mark ?? '',
    model: vehicle.model ?? '',
    body: vehicle.body ?? '',
    bodyStyle: vehicle.body_style ?? '',
    releaseDate: vehicle.release_date ? mapFormatDate(vehicle.release_date, { month: "numeric", year: "numeric" }) : '',
    engine: vehicle.engine ?? '',
    modification: vehicle.modification ?? '',
    grade: vehicle.grade ?? '',
    equipment: vehicle.equipment ?? '',
    prodPeriod: vehicle.prodPeriod ?? '',
    description: vehicle.description ?? '',
});

export const mapCarsVinOrFrame = (cars) => cars.map(mapCarVinOrFrame);

// RESPONSES -----------------------------------------------------------------------------------------------------------

export const mapCheckByVinOrFrameResponse = (response) => ({
    vin: response.vin,
    cars: mapCarsVinOrFrame(response.cars),
});

export const mapRequestCodeResponse = (response) => ({
    lifetime: response.lifetime,
    resendDelay: response.resend_delay,
});

export const mapRequestResetPasswordResponse = (response) => ({
    lifetime: response.lifetime,
    resendDelay: response.resend_delay,
});

export const mapGetCitiesResponse = ({ items }) => {
    const data = items.map(mapCity);
    return {
        data,
        options: data.map(mapCityForDropdown),
    };
};

export const mapGetModelsResponse = (response) => ({
    items: response.items.map((option) => ({
        label: option.key,
        value: option.value,
    })),
    total: response.total,
    page: response.page,
    pageSize: response.page_size,
});

export const mapSchemaProductWholesaleData = (data) => ({
    priceRetailPerUnit: data.price_retail_per_unit,
    priceWholesalePerPackage: data.price_wholesale_per_package,
    priceWholesalePerUnit: data.price_wholesale_per_unit,
})

export const mapSchemaProductData = (data) => ({
    id: data.id,
    description: data.description,
    isFavorite: data.is_favorite,
    name: data.name,
    possibleToOrder: data.possible_to_order,
    priceRetailPerPackage: data.price_retail_per_package,
    image: mapImage(data.primary_image),
    quantityInPackage: data.quantity_in_package,
    slug: data.slug,
    stocks: data.stocks,
    url: data.url,
    vendorCode: data.vendor_code,
    weightPerPackageGrams: data.weight_per_package_grams,
    weightPerUnitGrams: data.weight_per_unit_grams,
    wholesale: data.wholesale ? mapSchemaProductWholesaleData(data.wholesale) : data.wholesale,
});

export const mapSchemaDetailData = (data) => ({
    code: data.code,
    name: data.name,
    oem: data.oem,
    selectionCoordinates: data.selection_coordinates,
    product: mapSchemaProductData(data.product),
});

export const mapSchemaUnitsData = (data) => ({
    details: data.details.map(mapSchemaDetailData),
    image: data.image,
    name: data.name,
});

export const mapSchemaResponse = (response) => ({
    category: response.category,
    units: response.units.map(mapSchemaUnitsData),
});

export const mapNeighboursItems = (data) => ({
    id: data.id,
    name: data.name,
    description: data.description,
    isFavorite: data.is_favorite,
    possibleToOrder: data.possible_to_order,
    priceRetailPerPackage: data.price_retail_per_package,
    image: mapImage(data.primary_image),
    quantityInPackage: data.quantity_in_package,
    stocks: data.stocks,
    weightPerPackageGrams: data.weight_per_package_grams,
    weightPerUnitGrams: data.weight_per_unit_grams,
    wholesale: data.wholesale ? mapSchemaProductWholesaleData(data.wholesale) : data.wholesale,
})

export const mapNeighboursItemsResponse = (response) => (response.map(mapNeighboursItems));

// BINDING DATA --------------------------------------------------------------------------------------------------------

export const mapLoginData = (data) => ({
    successLoginRedirectRoute: data.successLoginRedirectRoute,
    isEditPhone: data.isEditPhone || false,
});

export const mapStorePhone = (data) => {
    return {value: data.value, comment: data.comment || ''};
};

export const mapBranch = (data) => {
    const city = get(data.address, 'city');
    const address = get(data.address, 'string');
    const latitude = get(data.address, 'latitude');
    const longitude = get(data.address, 'longitude');
    return {
        address,
        city,
        lat: latitude,
        lng: longitude,
        title: `г.&nbsp;${city}`,
        phones: data.phones.map(mapStorePhone),
        schedule: data.working_hours,
        hasCoordinates: latitude !== null && longitude !== null,
    };
};

export const mapBranchType = (data) => data.all.map(mapBranch);

export const mapContactsData = (data) => ({
    warehouses: mapBranchType(data.warehouses),
    distributors: mapBranchType(data.distributors),
    retailStores: mapBranchType(data.retailStores),
    serviceCenters: mapBranchType(data.serviceCenters),
});

export const mapAddress = (data) => ({
    id: data.id,
    country: data.country,
    city: {
        label: data.city,
        value: data.city_fias_id,
    },
    postalCode: data.postal_code,
    street: {
        label: data.street,
        value: {
            latitude: data.latitude,
            longitude: data.longitude,
        },
    },
    building: data.building,
    flat: data.flat,
});

export const mapProfileData = (data) => ({
    addresses: data.addresses.map(mapAddress),
});

export const mapUserSettings = (data) => ({
    showRetailPriceInCartAndCheckout: get(data, 'showRetailPriceInCartAndCheckout', true),
    showWeightInCart: get(data, 'showWeightInCart', true),
    priceDisplayType: get(data, 'priceDisplayType', null),
});

export const mapUserData = (data) => ({
    phone: data.phone,
    name: data.name,
    email: data.email,
    type: data.type,
    isLoggedIn: data.isLoggedIn,
    settings: mapUserSettings(data.settings),
});

export const mapRegisteredDetail = (data) => ({
    detailName: data.name,
    id: data.id,
    vendorCode: data.vendor_code,
    url: data.url,
    image: data.image,
    status: {
        id: get(data.status, 'id', null),
        name: get(data.status, 'name', null),
    },
    carBrand: data.mark,
    carModel: data.model,
    serialNumber: data.serial_number,
    vin: data.vin_or_body,
    ownerName: data.user_full_name,
    purchaseDate: mapDate(data.bought_at),
    manufacturingDate: mapDate(data.released_at),
    comment: data.comment,
});

export const mapRegisteredDetailsData = (data) => ({
    details: data.details.map(mapRegisteredDetail),
});

export const mapInstructionData = (data) => ({
    instructionId: data.instructionId,
});

export const mapSearchDefaultFilterData = (data) => ({
    key: data.key,
    label: data.text,
    value: data.value_data.value,
});

export const mapSearchDefaultFiltersData = (data) => data.map(mapSearchDefaultFilterData);

export const mapSearchDefaultData = (data) => ({
    searchType: data.searchType || SEARCH_TABS.VIN,
    selectedFilters: data.selectedFilters ? mapSearchDefaultFiltersData(data.selectedFilters) : [],
    productMarks: {
        options: data.productMarks.options.map((option) => ({
            label: option.key,
            value: option.value,
        })),
        filterKey: data.productMarks.catalog_filter_key,
    },
});
