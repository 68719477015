import { showErrorMessage } from '@library/uikit/notifications';
import { VALIDATION_MESSAGES } from '@scripts/constants';
import { apiSearchByVinOrFrame } from '../api-methods';

export const vinMixin = () => ({
    methods: {
        async vinSearch(number) {
            this.showRecaptchaBadge()

            try {
                let redirectUrl = '';
                let result;
                this.submitButtonLoading = true;

                await this.recaptcha();

                while (!redirectUrl) {
                    // eslint-disable-next-line no-await-in-loop
                    result = await apiSearchByVinOrFrame({ vin_or_frame: number, captcha_token: this.token });

                    if (result.redirect_url) {
                        redirectUrl = result.redirect_url;
                    }

                    // eslint-disable-next-line no-await-in-loop
                    await new Promise((r) => setTimeout(r, 2000));
                }

                document.location.href = redirectUrl;
            } catch (error) {
                this.handleServerErrors({
                    error,
                    onError: () => {
                        if (!((error.invalidFields && error.invalidFields.length) || error.common)) {
                            showErrorMessage({
                                text: VALIDATION_MESSAGES.SERVER_ERROR,
                            });
                        }
                    },
                    onCommonError: this.onCommonError,
                    onInvalidField: (fieldName, message) => {
                        this.onCommonError(message);
                    },
                });
            }

            this.submitButtonLoading = false;
            this.hideRecaptchaBadge()
        },
    },
});
