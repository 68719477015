import Vue from 'vue/dist/vue';
import { mask } from 'vue-the-mask';
import { mapMutations } from 'vuex';
import { INPUT_MASKS } from '@library/scripts/constants';
import { formSending } from '@library/forms/form-sending';
import store from '@scripts/store';
import { form } from '@scripts/mixins/form';
import { formValidation } from '@library/forms/validation';
import { mapRequestCodeResponse } from '@scripts/mappings';
import { MESSAGES, MODALS, VALIDATION_MESSAGES, VALIDATION_RULES, VUEX_MUTATIONS } from '@scripts/constants';
import { apiRequestCode, apiChangePhoneRequestCode, apiLogin, apiChangePhone, apiEmailLogin } from '@scripts/api-methods';
import { DebounceLoading } from '@library/forms/debounce-loading';
import { showSuccessMessage } from '@library/uikit/notifications';
import UIkit from '../../../vendor/uikit/uikit';

const TABS = {
    EMAIL: 'email',
    PHONE: 'phone',
};

const formDataScheme = () => ({
    email: '',
    password: '',
    phone: '',
    code: '',
});

export default (el, name, data) =>
    new Vue({
        el,
        name,
        store,
        data: () => ({
            currentTab: TABS.EMAIL,
            formData: formDataScheme(),
            showInputCodeStep: false,
            submitEmailAndPasswordLoader: new DebounceLoading(),
            submitCodeLoader: new DebounceLoading(),
            submitCodeDelay: 0,
            submitCodeDelayTimer: undefined,
            binding: data,
            masks: {
                phone: INPUT_MASKS.PHONE_MASK,
                code: INPUT_MASKS.CODE_MASK,
            },
            TABS,
        }),
        directives: { mask },
        mixins: [
            form(),
            formSending({ useRecaptcha: true }),
            formValidation(
                {
                    email: {
                        field: 'formData.email',
                        rules: {
                            required: true,
                            ...VALIDATION_RULES.EMAIL,
                        },
                    },
                    password: {
                        field: 'formData.password',
                        rules: {
                            required: true,
                        },
                    },
                    phone: {
                        field: 'formData.phone',
                        rules: {
                            required: true,
                            ...VALIDATION_RULES.PHONE,
                        },
                    },
                    code: {
                        field: 'formData.code',
                        rules: {
                            required: true,
                        },
                    },
                },
                VALIDATION_MESSAGES
            ),
        ],
        beforeMount() {
            if (this.binding.isEditPhone) {
                // hack to change 'phone' to 'new_phone'
                this.validationRules = {
                    code: this.validationRules.code,
                    new_phone: this.validationRules.phone,
                };

                this.currentTab = TABS.PHONE;
            }
        },
        methods: {
            ...mapMutations([VUEX_MUTATIONS.SET_USER_PHONE]),

            clearForm() {
                this.formData = formDataScheme();
            },

            submitEmailAndPassword() {
                this.resetFieldsToValidationToDefault();
                this.removeFieldsFromValidation(['formData.phone', 'formData.code']);

                return this.sendForm(
                    () =>
                        apiEmailLogin({
                            email: this.formData.email,
                            password: this.formData.password,
                        }),
                    () => {
                        this.successLogin();
                    },
                    null,
                    {
                        debounceLoading: this.submitEmailAndPasswordLoader,
                        checkRecaptcha: false,
                    }
                );
            },

            submitCode() {
                if (this.submitCodeDelay > 0) {
                    return null;
                }
                this.resetFieldsToValidationToDefault();
                this.removeFieldsFromValidation(['formData.code', 'formData.email', 'formData.password']);

                let apiMethodCall = () =>
                    apiRequestCode({
                        phone: this.formData.phone,
                        captcha_token: this.token,
                    });

                if (this.binding.isEditPhone) {
                    apiMethodCall = () =>
                        apiChangePhoneRequestCode({
                            new_phone: this.formData.phone,
                            captcha_token: this.token,
                        });
                }

                return this.sendForm(
                    () => apiMethodCall().then((result) => mapRequestCodeResponse(result)),
                    (result) => {
                        this.showInputCodeStep = true;
                        this.startSubmitCodeDelayTimer(result.resendDelay);
                    },
                    null,
                    {
                        debounceLoading: this.submitCodeLoader,
                        scrollToFirstInvalidField: false,
                    }
                );
            },

            startSubmitCodeDelayTimer(delay) {
                this.submitCodeDelay = delay;
                this.submitCodeDelayTimer = setInterval(() => {
                    this.submitCodeDelay -= 1;
                    if (this.submitCodeDelay === 0) {
                        clearInterval(this.submitCodeDelayTimer);
                    }
                }, 1000);
            },

            goToPhoneInput() {
                clearInterval(this.submitCodeDelayTimer);
                this.submitCodeDelay = 0;
                this.showInputCodeStep = false;
            },

            sendRequest() {
                this.resetFieldsToValidationToDefault();
                this.removeFieldsFromValidation(['formData.email', 'formData.password']);

                let apiMethodCall = () =>
                    apiLogin({
                        code: this.formData.code,
                        phone: this.formData.phone,
                    });

                if (this.binding.isEditPhone) {
                    apiMethodCall = () =>
                        apiChangePhone({
                            code: this.formData.code,
                            new_phone: this.formData.phone,
                        });
                }

                return this.sendForm(
                    () => apiMethodCall(),
                    () => {
                        if (this.binding.isEditPhone) {
                            const resultPhone = this.formData.phone;

                            showSuccessMessage({
                                text: MESSAGES.SUCCESS_PHONE_CHANGING,
                            });
                            UIkit.modal(MODALS.LOGIN).hide();
                            this.clearForm();
                            this.goToPhoneInput();
                            this.setUserPhone(resultPhone);
                        } else {
                            this.successLogin();
                        }
                    },
                    null,
                    {
                        scrollToFirstInvalidField: false,
                    }
                );
            },

            successLogin() {
                window.location.href = this.binding.successLoginRedirectRoute;
            },

            openResetPassword() {
                UIkit.modal(MODALS.LOGIN).hide();
                UIkit.modal(MODALS.RESET_PASSWORD_REQUEST).show();
            },
            openRegistration() {
                UIkit.modal(MODALS.LOGIN).hide();
                UIkit.modal(MODALS.REGISTRATION).show();
            },
        },
    });
