var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["AddToCart", _vm.modeClass] },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        !_vm.alwaysShowCounter
          ? _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showCounter,
                    expression: "!showCounter",
                  },
                ],
                staticClass: "Button Button--yellow AddToCart__button",
                attrs: {
                  type: "button",
                  title: "Добавить в корзину",
                  disabled: _vm.disabled,
                },
                on: {
                  click: function ($event) {
                    _vm.alwaysShowAddToCart
                      ? _vm.addToCartWithoutDebounce()
                      : _vm.debounceChangeQuantity({
                          quantity: _vm.isUserWholesaler
                            ? _vm.quantityInPackage
                            : 1,
                        })
                  },
                },
              },
              [
                _c("span", { staticClass: "Button__text" }, [
                  _vm._v(" В корзину "),
                ]),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        !_vm.alwaysShowAddToCart
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showCounter,
                    expression: "showCounter",
                  },
                ],
                staticClass: "AddToCart__counter",
              },
              [
                _c("counter", {
                  attrs: {
                    value: _vm.fakeQuantity,
                    "quantity-in-package": _vm.quantityInPackage,
                    "is-user-wholesaler": _vm.isUserWholesaler,
                    mask: _vm.isUserWholesaler ? "###" : "##",
                    "max-item-quantity": _vm.isUserWholesaler ? 999 : 99,
                    mode: _vm.mode,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.debounceChangeQuantity($event)
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }