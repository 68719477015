import Vue from 'vue/dist/vue';
import { mapState } from 'vuex';
import store from '@scripts/store';

export default (el, name) =>
    new Vue({
        el,
        name,
        store,
        computed: {
            ...mapState(['user']),
        },
    });
