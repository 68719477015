import Vue from 'vue/dist/vue';
import { apiGetAddSharedToCart } from '@scripts/api-methods';
import { showErrorMessage, showSuccessMessage } from '@library/uikit/notifications';

export default (el, name) =>
    new Vue({
        el,
        name,
        data: () => ({
        }),
        mounted() {

        },
        methods: {
            addSharedToCart(linkCode) {
                let payload = {
                    code: linkCode
                };
                apiGetAddSharedToCart(payload)
                    .then(() => {
                        showSuccessMessage({ text: 'Детали успешно добавлены' });
                        window.location.reload();
                    })
                    .catch(() => {
                        showErrorMessage({
                            text: 'Произошла ошибка',
                        });
                    })
                ;
            },

            copySharedCartLink(linkUrl){
                navigator.clipboard.writeText(linkUrl)
                    .then(() => {
                        showSuccessMessage({ text: 'Ссылка на корзину скопирована' });
                    })
                    .catch(err => {
                        console.log('Something went wrong', err);
                    });

            }
        },
    });