import {
    Swiper,
    Keyboard,
    Navigation,
    Pagination,
    A11y,
    Autoplay,
} from 'swiper';
import { Is } from '@library/scripts/is';
import has from 'lodash-es/has';

Swiper.use([Keyboard, Navigation, Pagination, A11y, Autoplay]);

export const CLASSES = {
    WRAPPER: '.swiper-wrapper',
    CONTAINER: '.swiper-container',
    SLIDE: '.swiper-slide',
};

export const SELECTORS = {
    ARROW_PREV: '[data-slider-arrow-prev]',
    ARROW_NEXT: '[data-slider-arrow-next]',
    PAGINATION: '[data-slider-pagination]',
};

/**
 * Gets HTML DOM Element
 * @param {string|Element} $element
 * @return Element|null
 */
function getNode($element) {
    if (Is.string($element)) {
        return document.querySelector($element);
    }

    if (Is.instanceOf($element, HTMLElement)) {
        return $element;
    }

    return null;
}

/**
 * Default Swiper options
 */
export const defaultOptions = {
    loop: false,
    speed: 650,
    spaceBetween: 0,
    slidesPerView: 'auto',
    navigation: true,
    pagination: true,
};

/**
 * Default navigation options
 * @param {string|Element} element
 * @return Object|false
 */
export function navigation(element) {
    const $element = getNode(element);
    if (!element) return false;

    const prev = $element.querySelector(SELECTORS.ARROW_PREV);
    const next = $element.querySelector(SELECTORS.ARROW_NEXT);
    if (!prev || !next) return false;

    return {
        nextEl: next,
        prevEl: prev,
    };
}

/**
 * Default pagination options
 * @param {string|Element} element
 * @return Object|false
 */
export function pagination(element) {
    const $element = getNode(element);
    if (!$element) return false;

    const $pagination = $element.querySelector(SELECTORS.PAGINATION);
    if (!$pagination) return false;

    return {
        el: $pagination,
        type: 'bullets',
        clickable: true,
        renderBullet(index, className) {
            return `<li class="${className}"><span>${index + 1}</span></li>`;
        },
    };
}

function updateProperty(instance, options, property, callback) {
    if (has(options, property) && options[property] === true) {
        if (options[property] === true) options[property] = callback(instance);

        if (Is.object(options[property])) {
            const callbackOptions = callback(instance);
            const thisOptions = options[property];

            options[property] = {
                ...callbackOptions,
                ...thisOptions,
            };
        }
    }
}

/**
 * Combine navigation, pagination properties with default values
 * @param {Element} container
 * @param {Object} options
 * @return Object
 */
function updateProperties(container, options) {
    updateProperty(container, options, 'pagination', pagination);
    updateProperty(container, options, 'navigation', navigation);

    // Disable looping and swiping when slides count <= 1
    const slidesCount = container.querySelectorAll(CLASSES.SLIDE).length;
    options.loop = options.loop && slidesCount > 1;
    options.allowSlidePrev = options.allowSlidePrev || slidesCount > 1;
    options.allowSlideNext = options.allowSlideNext || slidesCount > 1;

    return options;
}

/**
 * Initialize Swiper instance
 * @param {string|Element} $element
 * @param {Object|null} options
 * @return {Object|null} swiper instance
 */
export function initializer($element, options) {
    $element = getNode($element);
    if (!$element) return null;

    const $container = $element.querySelector(CLASSES.CONTAINER);
    if (!$container) return null;

    const datasetOptions = {};

    options = { ...defaultOptions, ...options, ...datasetOptions };
    options = updateProperties($element, options);

    return new Swiper($container, options);
}

/**
 * Initialize Swiper slider instances
 * @param {{ selector: string, options: Object|null }[]} instances
 */
export function initSwiperInstances(instances) {
    instances.forEach(({ selector, options }) => {
        document.querySelectorAll(selector).forEach((instance) => {
            initializer(instance, options || {});
        });
    });
}
