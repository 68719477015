'use strict'

import clone from 'lodash-es/cloneDeep'
import getter from 'lodash-es/get'
import merge from 'lodash-es/merge'

import def from './default'

export default class Localization {
	constructor(translates = {}, lang = 'ru', fallback = 'ru') {
		this.translates = clone(translates)
		this.fallback = fallback
		this.lang = lang
	}

	get(key) {
		const str = getter(this.translates[this.lang], key, getter(this.translates[this.fallback], key, null))
		if (str === null) console.warn('Unresolved key:', key)
		return str
	}

	extend(translates, lang = null) {
		this.translates = merge(this.translates, lang !== null ? { [lang]: translates } : translates)
		return this
	}

	setLang(lang) {
		this.lang = lang in this.translates ? lang : this.fallback
		return this
	}

	getCurrent() {
		return this.lang
	}
}

export const LibraryLocalization = new Localization(def)
