import Vue from 'vue/dist/vue';

const TABS = {
    INFO: 'info',
    EDIT_INFO: 'edit-info',
    EDIT_PASSWORD: 'edit-password',
};

// notice: we cant use vue for manipulating tabs with another instances inside. I tried.
const domTabs = document.querySelectorAll('[data-user-info-tab]');

export default (el, name) =>
    new Vue({
        el,
        name,
        data: () => ({
            currentTab: TABS.INFO,
            TABS,
        }),
        methods: {
            toggleTab(tab) {
                this.currentTab = this.currentTab === tab ? TABS.INFO : tab;
            },
        },
        watch: {
            currentTab(value) {
                domTabs.forEach((tab) => {
                    tab.hidden = true;
                });
                document.querySelector(
                    `[data-user-info-tab=${value}]`
                ).hidden = false;
            },
        },
    });
