import '@scripts/sentry'; // must be placed before any other import to handle errors

import { initVueInstances } from '@library/scripts/register';
import '@scripts/mixins/global';
import initYandexMaps from '@library/maps/yandex-map/yandex-map';
import { setupNotificationsDefaults } from '@library/uikit/notifications';
import { initReadyDOMInteractions } from '@scripts/dom-interactions';
import { getFromBinding, getFromGlobalScope } from '@scripts/helpers';
import { GLOBAL, DOCUMENT_EVENTS, VUE_ELEMENTS, VUEX_MUTATIONS } from '@scripts/constants';
import { initRecaptcha } from '@scripts/global';
import {
    mapUserData,
    mapContactsData,
    mapLoginData,
    mapProfileData,
    mapCartDetails,
    mapRegisteredDetailsData,
    mapInstructionData,
    mapSearchDefaultData,
    mapCheckoutUserRole,
    mapCatalogVinInfo,
} from '@scripts/mappings';
import store from '@scripts/store';
import initHeader from '../components/header';
import initSearchDetails from '../components/search-details';
import initContactsPage from '../pages/contacts';
import initWholesalerForm from '../components/forms/wholesaler/index';
import initFeedbackForm from '../components/forms/feedback';
import initVinResultModal from '../components/modals/vin-result';
import initVinForm from '../components/forms/vin';
import initLoginForm from '../components/forms/login';
import initRegistrationForm from '../components/forms/registration';
import initResetPasswordRequestForm from '../components/forms/reset-password-request';
import initResetPasswordCompleteForm from '../components/forms/reset-password-complete';
import initDetailsRegistrationForm from '../components/forms/details-registration';
import initCommentForm from '../components/forms/comment';
import initAddressesList from '../components/addresses-list';
import initUserInfoForm from '../components/forms/user-info';
import initUserPasswordForm from '../components/forms/user-password';
import initUserAddressForm from '../components/forms/user-address';
import initUserAddressSuggestionForm from '../components/forms/user-address-suggestion';
import initProductsList from '../components/products-list';
import initNeighboursList from '../components/products-slider';
import initGallerySwitcher from '../components/gallery-switcher';
import initMultiSelect from '../components/multi-select';
import initInfoList from '../components/info-list';
import initUserInfoTabs from '../components/user-info/tabs';
import initCompatibilityInfoTabs from '../components/compatibility-info/tabs';
import initCatalogTitle from '../pages/catalog/catalog-title';
import initCatalogVinInfo from '../components/catalog-vin-info/catalog-vin-info';
import initCatalogCars from '../components/catalog-cars/catalog-cars';
import initProductCard from '../components/product-card/product-card';
import initCheckoutPage from '../pages/checkout';
import initUserSettingsForm from '../components/forms/user-settings';
import initCartPage from '../pages/cart';
import initCitySelectForm from '../components/forms/city-select';
import initProductPage from '../pages/product';
import initRegisteredDetailsList from '../components/registered-details-list';
import initClaimForm from '../components/forms/claim';
import initFavoritesPage from '../pages/favorites';
import initLogoutConfirm from '../components/modals/logout-confirm';
import initClearCartConfirm from '../components/modals/clear-cart-confirm';
import initOrderRepeat from '../components/orders-list/order-repeat';
import initArticle from '../components/article/article';
import initServiceStatiomForm from '../components/forms/service-station/index';
import initCartSharedPage from '../pages/cart-shared/index';
import initWheelOfFortune from '../pages/wheel-of-fortune/index';
import initIndustrialSupply from '../components/forms/industrial-supply/index';
import initProductSelectionBySize from '../pages/product-selection-by-size/index';
import initPartRequestForm from '../components/forms/part-request';

import UIkit from '../vendor/uikit/uikit';

// HOTFIX: костыль с состоянием корзины при нативной навигации "назад"/"вперед"
if (performance.navigation.type === 2) {
    window.location.reload();
}

window.UIkit = UIkit;

store.commit(VUEX_MUTATIONS.SET_USER, getFromBinding('user', mapUserData));
store.commit(VUEX_MUTATIONS.SET_CART, getFromBinding('cart', mapCartDetails));
store.commit(VUEX_MUTATIONS.SET_SELECTED_CITY, getFromBinding('selectedCity'));
store.commit(VUEX_MUTATIONS.SET_IS_CITY_SELECTED, getFromBinding('isCitySelected'));
store.commit(VUEX_MUTATIONS.SET_CITIES_WITH_WAREHOUSES, getFromBinding('citiesWithWarehouses'));

setupNotificationsDefaults({
    position: 'bottom-left',
});

document.addEventListener(DOCUMENT_EVENTS.READY, () => {
    initRecaptcha();
    initVueInstances([
        {
            query: VUE_ELEMENTS.HEADER,
            name: 'header',
            init: initHeader,
            data: getFromBinding('headerData'),
        },
        {
            query: VUE_ELEMENTS.LOGIN_FORM,
            name: 'login-form',
            init: initLoginForm,
            data: getFromBinding('loginData', mapLoginData),
        },
        {
            query: VUE_ELEMENTS.REGISTRATION_FORM,
            name: 'registration-form',
            init: initRegistrationForm,
        },
        {
            query: VUE_ELEMENTS.RESET_PASSWORD_REQUEST_FORM,
            name: 'reset-password-request-form',
            init: initResetPasswordRequestForm,
        },
        {
            query: VUE_ELEMENTS.RESET_PASSWORD_COMPLETE_FORM,
            name: 'reset-password-complete-form',
            init: initResetPasswordCompleteForm,
            data: getFromBinding('confirmationResetPassword'),
        },
        {
            query: VUE_ELEMENTS.SEARCH_DETAILS,
            name: 'search-details',
            init: initSearchDetails,
            data: getFromBinding('searchDefaultData', mapSearchDefaultData),
        },
        {
            query: VUE_ELEMENTS.CONTACTS_PAGE,
            name: 'contacts-page',
            init: initContactsPage,
            data: {
                bindings: getFromBinding('contactsData', mapContactsData),
                mapDefaults: getFromBinding('contactsMapData'),
            },
        },
        {
            query: VUE_ELEMENTS.PRODUCTS_LIST,
            name: 'products-list',
            init: initProductsList,
            data: getFromBinding('productsData'),
        },
        {
            query: VUE_ELEMENTS.NEIGHBOURS_LIST,
            name: 'neighbours-list',
            init: initNeighboursList,
        },
        {
            query: VUE_ELEMENTS.PRODUCT_CARD,
            name: 'product-card',
            init: initProductCard,
            data: {
                schemasData: getFromBinding('schemasData'),
                favorites: getFromBinding('productsData'),
            },
        },
        {
            query: VUE_ELEMENTS.WHOLESALER_FORM,
            name: 'wholesaler-form',
            init: initWholesalerForm,
        },
        {
            query: VUE_ELEMENTS.FEEDBACK_FORM,
            name: 'feedback-form',
            init: initFeedbackForm,
        },
        {
            query: VUE_ELEMENTS.VIN_RESULT_MODAL,
            name: 'vin-result',
            init: initVinResultModal,
        },
        {
            query: VUE_ELEMENTS.VIN_FORM,
            name: 'vin-form',
            init: initVinForm,
        },
        {
            query: VUE_ELEMENTS.DETAILS_REGISTRATION_FORM,
            name: 'details-registration-form',
            init: initDetailsRegistrationForm,
        },
        {
            query: VUE_ELEMENTS.COMMENT_FORM,
            name: 'comment-form',
            init: initCommentForm,
            data: getFromBinding('instructionData', mapInstructionData),
        },
        {
            query: VUE_ELEMENTS.COMPATIBILITY_INFO_TABS,
            name: 'compatibility-info-tabs',
            init: initCompatibilityInfoTabs,
        },
        {
            query: VUE_ELEMENTS.USER_INFO_TABS,
            name: 'user-info-tabs',
            init: initUserInfoTabs,
        },
        {
            query: VUE_ELEMENTS.CATALOG_TITLE,
            name: 'catalog-title',
            init: initCatalogTitle,
        },
        {
            query: VUE_ELEMENTS.CATALOG_VIN_INFO,
            name: 'catalog-vin-info',
            init: initCatalogVinInfo,
            data: {
                vinInfo: getFromBinding('vinInfo', mapCatalogVinInfo)
            }
        },
        {
            query: VUE_ELEMENTS.CATALOG_CARS,
            name: 'catalog-cars',
            init: initCatalogCars,
            data: {
                cars: getFromBinding('cars'),
                productsData: getFromBinding('productsData'),
            },
        },
        {
            query: VUE_ELEMENTS.INFO_LIST,
            name: 'info-list',
            init: initInfoList,
        },
        {
            query: VUE_ELEMENTS.USER_INFO_FORM,
            name: 'user-info-form',
            init: initUserInfoForm,
        },
        {
            query: VUE_ELEMENTS.USER_PASSWORD_FORM,
            name: 'user-password-form',
            init: initUserPasswordForm,
        },
        {
            query: VUE_ELEMENTS.USER_ADDRESS_FORM,
            name: 'user-address-form',
            init: initUserAddressForm,
        },
        {
            query: VUE_ELEMENTS.USER_ADDRESS_SUGGESTION_FORM,
            name: 'user-address-suggestion-form',
            init: initUserAddressSuggestionForm,
        },
        {
            query: VUE_ELEMENTS.ADDRESSES_LIST,
            name: 'addresses-list',
            init: initAddressesList,
            data: getFromBinding('profileData', mapProfileData),
        },
        {
            query: VUE_ELEMENTS.MULTI_SELECT,
            name: 'multi-select',
            init: initMultiSelect,
        },
        {
            query: VUE_ELEMENTS.CHECKOUT_PAGE,
            name: 'checkout-page',
            init: initCheckoutPage,
            data: {
                roleData: getFromBinding('roleData', mapCheckoutUserRole),
                warehouses: getFromBinding('warehouses'),
            },
        },
        {
            query: VUE_ELEMENTS.CART_PAGE,
            name: 'cart-page',
            init: initCartPage,
        },
        {
            query: VUE_ELEMENTS.USER_SETTINGS_FORM,
            name: 'user-settings-form',
            init: initUserSettingsForm,
            data: getFromBinding('wholesalerSettingsData'),
        },
        {
            query: VUE_ELEMENTS.GALLERY_SWITCHER,
            name: 'gallery-switcher',
            init: initGallerySwitcher,
        },
        {
            query: VUE_ELEMENTS.CITY_SELECT_FORM,
            name: 'city-select-form',
            init: initCitySelectForm,
        },
        {
            query: VUE_ELEMENTS.PRODUCT_PAGE,
            name: 'product-page',
            init: initProductPage,
        },
        {
            query: VUE_ELEMENTS.REGISTERED_DETAILS_LIST,
            name: 'registered-details-list',
            init: initRegisteredDetailsList,
            data: getFromBinding('registeredDetailsData', mapRegisteredDetailsData),
        },
        {
            query: VUE_ELEMENTS.CLAIM_FORM,
            name: 'claim-form',
            init: initClaimForm,
        },
        {
            query: VUE_ELEMENTS.FAVORITES_PAGE,
            name: 'favorites-page',
            init: initFavoritesPage,
            data: getFromBinding('productsData'),
        },
        {
            query: VUE_ELEMENTS.LOGOUT_CONFIRM,
            name: 'logout-confirm',
            init: initLogoutConfirm,
        },
        {
            query: VUE_ELEMENTS.CLEAR_CART_CONFIRM,
            name: 'clear-cart-confirm',
            init: initClearCartConfirm,
        },
        {
            query: VUE_ELEMENTS.REPEAT_ORDER,
            name: 'repeat-order',
            init: initOrderRepeat,
        },
        {
            query: VUE_ELEMENTS.ARTICLE_IFRAME,
            name: 'article-iframe',
            init: initArticle,
        },

        {
            query: VUE_ELEMENTS.SERVICE_STATION_FORM,
            name: 'service-station-form',
            init: initServiceStatiomForm,
        },
        {
            query: VUE_ELEMENTS.CART_SHARED_PAGE,
            name: 'cart-shared-page',
            init: initCartSharedPage,
        },
        {
            query: VUE_ELEMENTS.WHEEL_OF_FORTUNE,
            name: 'wheel-of-fortune',
            init: initWheelOfFortune,
        },
        {
            query: VUE_ELEMENTS.INDUSTRIAL_SUPPLY,
            name: 'industrial-supply',
            init: initIndustrialSupply,
        },
        {
            query: VUE_ELEMENTS.PRODUCT_SELECTION_BY_SIZE,
            name: 'product-selection-by-size',
            init: initProductSelectionBySize,
        },
        {
            query: VUE_ELEMENTS.PART_REQUEST_FORM,
            name: 'part-request-form',
            init: initPartRequestForm,
        },

    ]);
    initReadyDOMInteractions();
});

document.addEventListener(DOCUMENT_EVENTS.MAP_CONFIG_SET, () => {
    if (getFromGlobalScope('yandexMapApiKey', false)) {
        initYandexMaps(GLOBAL.APP_ENTRY_POINT);
    }
});
